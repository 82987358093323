import { PaddedPage } from 'components/common/Layout';
import SurveyContext from 'contexts/SurveyContext';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import TrustAccountTable from './TrustAccountTable';
import { Radios } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

export default function FileTrustAccount() {
  const {setSurveyNav, file, surveyId, fileId } = useContext(SurveyContext);
  const [filters, setFilters] = useState({})
  const { currentUser } = useContext(AppContext);
  useEffect(() => {setSurveyNav('trust-account')}, [setSurveyNav]);

  const authorized = useMemo(() => {
    return file?.firmId ? currentUser?.firms?.find(firm => !['guest', 'basic'].includes(firm.type) && firm.id == file.firmId) : true;
  }, [currentUser]);

  return (
    <PaddedPage noBottom className="grow relative">
        <div className="flex flex-col relative gap-4">
          {/* <h2>Trust Account Integration</h2> */}
          <div className="flex justify-between border rounded p-2 border-med-grey items-center">
            <div className="flex flex-col">
              <label className="text-sm">Filter</label>
              <Radios inline options={[{name: "All", value: ""}, {name: "Cleared", value: true}, {name: "Uncleared", value: false}]} valueOverride={filters.cleared} onChange={(cleared) => setFilters({...filters, cleared})} />
            </div>
          </div>
          {
            file && authorized &&
            <TrustAccountTable
              surveyId={surveyId}
              fileId={fileId}
              filters={filters}
              firmId={file && file.firmId}
              documents={["Register", "All"]}
              add
            />
          }
        </div>
        {/* conditional blockout */}
        {!authorized &&
          <>
            <div 
                className="absolute top-0 bottom-0 left-0 right-0 bg-transparent-white"
                style={{
                  backdropFilter: "blur(3px)"
                }}
            />
            <div
              className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-start"
            >
              <div className="sticky top-10 mt-20 flex items-center justify-center">
                <div className={`border rounded p-4 border-red bg-light-light-red text-red flex gap-2`}>
                  <FontAwesomeIcon icon={faWarning}/>
                  <p className="text-red">You are not authorized to view this page!</p>
                </div>
              </div>
            </div>
          </>
        }
    </PaddedPage>
  )
}
