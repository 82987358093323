import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonPrimary from 'components/common/Button'
import React from 'react'
import DocumentErrors from './DocumentErrors'
import DocumentQuestions from './DocumentQuestions'
import DocumentTitle from './DocumentTitle'

export default function LTSADocumentContent({errors, document, ltsaDocument, loading, setShowConfirmRegenerate, handleCreateLTSADocument}) {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between md:items-center p-2 gap-2">
        <DocumentTitle ltsa errors={errors} document={document}/>
        {
          ltsaDocument.type ?
            (ltsaDocument.ltsaDocumentId ?
              <div className="flex gap-2">
                <ButtonPrimary className="text-sm" disabled={errors || loading} onClick={() => setShowConfirmRegenerate(true)}>{loading ?  <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> : "Regenerate"}</ButtonPrimary>
                <a target="_blank" rel="noreferrer" className="" href={`${process.env.REACT_APP_LTSA_CLIENT}/wfc/package/${ltsaDocument.ltsaPackageId}/view`}>
                  <ButtonPrimary className="text-sm" disabled={errors || loading}>View Package</ButtonPrimary>
                </a>
                <a target="_blank" rel="noreferrer" className="" href={`${process.env.REACT_APP_LTSA_CLIENT}/wfc/package/${ltsaDocument.ltsaPackageId}/application/${ltsaDocument.ltsaDocumentId}/edit`}>
                  <ButtonPrimary className="text-sm" disabled={errors || loading}>View Document</ButtonPrimary>
                </a>
              </div>
            : 
            <div className="flex gap-2">
              <ButtonPrimary className="text-sm" disabled={errors || loading} onClick={handleCreateLTSADocument}>{loading ?  <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> : "Update"}</ButtonPrimary>
              <a target="_blank" rel="noreferrer" className="" href={`${process.env.REACT_APP_LTSA_CLIENT}/lotr/${ltsaDocument.type === "LOTR_REPORT" ? 'tr' : 'td'}/create/${ltsaDocument.lotrDocumentId}`}>
                <ButtonPrimary className="text-sm" disabled={errors || loading}>View Document</ButtonPrimary>
              </a>
            </div>
            )
          :
          <ButtonPrimary className="text-sm" disabled={errors || loading} onClick={handleCreateLTSADocument}>{loading ?  <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> : "Create"}</ButtonPrimary>
        
        }
      </div>
      {errors && <DocumentErrors errors={errors}/>}
      {
        document.info.questions && document.info.questions.length > 0 &&
        <DocumentQuestions document={document}/>
      }
    </>
  )
}
