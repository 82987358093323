import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import Preview from './Preview';
import CrossSectionJumbo from './CardsJumbo';
import oval from 'assets/images/landing/oval.jpg';
import screenshot from 'assets/images/landing/appscreenshot.png'
import logo from 'assets/images/logo/logo-letters.png';
import cityNight from 'assets/images/landing/city-night.png';
import clock from 'assets/images/landing/clock.png';
import lock from 'assets/images/landing/lock.png';
import coins from 'assets/images/landing/coins.png';
import laptop from 'assets/images/landing/laptop.png';
import ButtonPrimary from 'components/common/Button';
import RoundedButton from 'components/common/Button/RoundedButton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faStar } from '@fortawesome/free-solid-svg-icons';
import desktop from 'assets/images/about/desktop.png';
import mlsUploadGif from 'assets/images/about/mlsupload.gif';
import lotrGif from 'assets/images/about/lotrGif.gif';
import chequePrintGif from 'assets/images/about/chequePrintGif.gif';
import person1 from 'assets/images/about/person1.jpg';
import person2 from 'assets/images/about/person2.jpg';
import person3 from 'assets/images/about/person3.jpg';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import Testimonials from './Testimonials';



const AboutPage = () => {
  return (
  <div className="flex flex-col">
    <Preview/>
    <div className="w-full bg-dark-green">
      <PaddedPage className="self-center flex justify-center">
        <h1 className="text-white font-medium">Simple • Affordable • Customized</h1>
      </PaddedPage>
    </div>
    <div className="w-full bg-med-green flex flex-col">
      <PaddedPage className="self-center">
        <div className="flex items-center h-test w-full gap-12 sm:flex-col sm:justify-center">
          <img src={mlsUploadGif} alt="desktop" className="w-5/8 rounded-xl border border-3 object-cover"/>
          <div className="md:w-3/8 flex items-center justify-center">
            <div className="flex flex-col gap-4 max-w-100">
              <h1>
                Upload Your Contracts
              </h1>
              <h3>Automatically populate over 20 entries including property address, PID number, buyers names, completion, possession and adjustment dates and more! </h3>
              <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
                <RoundedButton>Start Now</RoundedButton>
              </a>
            </div>
          </div>
        </div>
      </PaddedPage>
    </div>
    <PaddedPage className="self-center bg-transparent">
      <div className="flex items-center w-full h-test flex-row-reverse gap-8 sm:flex-col sm:justify-center">
        <img src={lotrGif} alt="desktop" className="w-5/8 rounded-xl shadow-xl border border-3 object-cover"/>
        <div className="md:w-3/8 flex items-center justify-center">
          <div className="flex flex-col gap-4 max-w-100">
            <h1>
              Auto Populate PTT Returns & LOTR Reports
            </h1>
            <h3>with all the directors and shareholders information saving you hours of time!</h3>
            <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
              <RoundedButton>Start Now</RoundedButton>
            </a>
          </div>
        </div>
      </div>
    </PaddedPage>
    <div className="w-full bg-yellow flex flex-col">
      <PaddedPage className="self-center">
        <div className="flex items-center w-full h-test gap-12 sm:flex-col sm:justify-center">
          <img src={chequePrintGif} alt="desktop" className="w-5/8 rounded-xl border-3 object-cover"/>
          <div className="md:w-3/8 flex items-center justify-center">
            <div className="flex flex-col gap-4 max-w-100">
              <h1>
                Instantly Print Cheques
              </h1>
              <h3>Reconcile your trust account, and export your general ledger data to use in other accounting software!</h3>
              <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
                <RoundedButton>Start Now</RoundedButton>
              </a>
            </div>
          </div>
        </div>
      </PaddedPage>
    </div>
    <div className="w-full">
      <PaddedPage >
        <div className="self-center items-center w-full flex flex-col py-8 gap-12 2xl:mx-auto 2xl:max-w-8xl">
          <h1 className="text-primary font-medium">Additional Features</h1>
          <div className="flex gap-8 w-full sm:flex-col sm:pl-8 pl-4">
            <ul className='list-disc grow text-base font-bold flex flex-col gap-4'>
              <li>Full LTSA Integration</li>
              <li>FCT Title Insurance Integration</li>
              <li>Web Based - secured access anywhere/anytime</li>
            </ul>
            <ul className='list-disc grow text-base font-bold flex flex-col gap-4'>
              <li>Commercial Conveyances & Lending</li>
              <li>Manufactured Homes transfers</li>
              <li>Curate your own databases</li>
            </ul>
            <ul className='list-disc grow text-base font-bold flex flex-col gap-4'>
              <li>TAF Report Generation</li>
              <li>Property Tax Certificate Upload</li>
              <li>Document and Account Templates</li>
            </ul>
          </div>
          <h2>+ Many More Added Each Week Based On Client Requests!</h2>
          <a className="mt-2" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
            <RoundedButton>Start Now</RoundedButton>
          </a>
        </div>
      </PaddedPage>
    </div>
    <div className="w-full bg-med-green">
      <PaddedPage>
        <div className="self-center py-8 gap-4 flex flex-col justify-center items-center">
          <h1 className="text-white font-medium">Only $49.00<sup>+tx</sup>/File</h1>
          <h2 className="text-white font-normal">Are you ready to help your clients save money?</h2>
          <a className="pt-4" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
            <RoundedButton white>Get Started Now</RoundedButton>
          </a>
        </div>
      </PaddedPage>
    </div>
    <Testimonials/>
  </div>
  );
};

export default AboutPage;
