import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import AppContext from 'contexts/AppContext';
import TooltipBasic from 'components/common/TooltipBasic';


const SideNavLink = ({to, children, selected, small, message}) => {
  return (
    small ?
      <TooltipBasic message={message} className="relative" right>
        <Link className={`2xl:text-base 2xl:leading-4 no-underline py-2 px-4 items-center gap-1 content-center flex hover:bg-light-grey dark:hover:bg-midnight rounded-r-sm ${selected ? 'bg-yellow dark:bg-mocha font-bold' : ''}`} to={to}>{children}</Link>
      </TooltipBasic>
    :   
      <Link className={`2xl:text-base no-underline py-2 px-4 2xl:px-8 items-center gap-1 content-center flex hover:bg-light-grey dark:hover:bg-midnight rounded-r-sm ${selected ? 'bg-yellow dark:bg-mocha font-bold' : ''}`} to={to}>{children}</Link>
  )
}
const AdminSideNav = ({small}) => {
  const location = useLocation();
  const {menuOpen, currentUser} = useContext(AppContext);
  return (
    <div className={`self-stretch transition-all shrink-0 grow-0`}>
      <div className="flex flex-col text-xs py-8">
        <h3 className={`w-0 opacity-0 ${small ? 'md:w-0' : 'pl-4 md:w-full md:opacity-100'} 2xl:text-base 2xl:pl-10 dark:text-bone`}>Admin</h3>
        <hr className="mt-1 dark:border-slate"></hr>
        {
          ["admin", "super-admin"].includes(currentUser.type) &&
          <>
            <SideNavLink small={small} message="Mortgages" selected={location.pathname.includes('admin/mortgages')} to="/admin/mortgages">📝<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Mortgages</span></SideNavLink>
            <SideNavLink small={small} message="Businesses" selected={location.pathname.includes('admin/businesses')} to="/admin/businesses">🏭<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Businesses</span></SideNavLink>
            <SideNavLink small={small} message="Documents" selected={location.pathname.includes('admin/documents')} to="/admin/documents">📑<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Documents</span></SideNavLink>
            <SideNavLink small={small} message="Firms" selected={location.pathname.includes('admin/firms')} to="/admin/firms">⚖️<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Firms</span></SideNavLink>
          </>
        }
        {
          "super-admin" === currentUser.type &&
          <SideNavLink small={small} message="Bugs" selected={location.pathname.includes('admin/bug-reports')} to="/admin/bug-reports">🪲<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Bugs</span></SideNavLink>
        }
        <SideNavLink small={small} message="Users" selected={location.pathname.includes('admin/users')} to="/admin/users">👤<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Users</span></SideNavLink>
        {
          currentUser.firmAdmin && 
          <>
            <SideNavLink small={small} message="Firm" selected={location.pathname.includes('admin/firm')} to="/admin/firm">⚖️<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Firm</span></SideNavLink>
            <SideNavLink small={small} message="Payments" selected={location.pathname.includes('admin/payments')} to="/admin/payments">💳<span className={`hidden md:flex ${small ? 'w-0 md:hidden' : ''}`}>Payments</span></SideNavLink>
          </>
        }
      </div>
    </div>
  );
};

export default AdminSideNav;
