import React, {useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function TooltipBasic({message, color, className, showOverride, left, right, large, children}) {
  const [show, setShow] = useState(false);
  const elementRef = useRef();
  const [direction, setDirection] = useState({x: 0, y: 1});

  const showTooltip = () => {
    const rect = elementRef.current.getBoundingClientRect();
    setDirection({x: rect.x < 200 ? 1 : rect.x > (window.innerWidth - 400) ? -1 : 0 , y: rect.y < 100 ? -1 : 0})
    setShow(true);
  }

  return (
    <div ref={elementRef} onMouseEnter={showTooltip} onMouseLeave={() => setShow(false)} className={`${className}`}>
      {(showOverride || show) && message && <div className={`absolute -translate-x-1/2 bottom-6 z-50 pointer-events-none ${(direction.y == -1) ? 'top-10' : ''}`}>
        <div className={` text-xxs w-fit flex p-2 rounded whitespace-nowrap text-white grow-0 ${(color) ? `bg-${color}` : 'bg-dark-grey'} ${right ? 'relative left-10' : ''} ${left ? 'relative -left-28' : ''}`}>
          <p className="text-white font-semibold grow whitespace-nowrap">
            {message}
          </p>
        </div>
      </div>}
      <div>
        {children}
      </div>
    </div>
  )
}
