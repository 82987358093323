import React, {useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input, List, Radios } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import AddMortgage from 'components/surveyInputs/AddMortgage';
import { Table } from 'components/surveyInputs';

export default function CreateBusiness() {
	const navigate = useNavigate();
	const [bank, setBank] = useState();

	const onComplete = () => {
		navigate("/admin/mortgages")
	}

	return (
    <PaddedPage bottom>
      <div className="flex flex-col gap-2 items-start">
        <Table
          title="📝Create Mortgage"
        >
          <BusinessInput label="Bank or Lender" onSelect={setBank} description="What bank is this mortgage for" type={["bank", "lender"]} publicBusiness={true}/>
          <AddMortgage onCreate={onComplete} bank={bank} publicMortgage={true}/>
        </Table>
      </div>
    </PaddedPage>
	)
}
