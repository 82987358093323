import React, {useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function Tooltip({message, color, bgColor, borderColor, icon, className, showOverride, left, right, large, badge, forwardedRef}) {
  const [show, setShow] = useState(false);
  const elementRef = useRef();
  const [direction, setDirection] = useState({x: 0, y: 1});

  const showTooltip = () => {
    const rect = elementRef.current.getBoundingClientRect();
    setDirection({x: rect.x < 200 ? 1 : rect.x > (window.innerWidth - 400) ? -1 : 0 , y: rect.y < 150 ? -1 : 0})
    setShow(true);
  }
  return (
    <div ref={elementRef} onMouseEnter={showTooltip} onMouseLeave={() => setShow(false)} className={`inline-block ml-1 relative ${large ? 'w-4' : 'w-2'} ${className}`}>
      <div className={`absolute ${direction.x == -1 ? 'right-0' : 'left-0'} ${large ? '-bottom-1.5' : 'bottom-0'} w-2 text-left`}>
        {(showOverride || show) && message && <div className={`absolute ${direction.x == -1 ? 'right-0 justify-end' : 'left-0'} ${(direction.y == -1) ? 'top-7' : 'bottom-5'} flex w-1/4screen z-30`}>
          <div className={` text-xxs w-fit flex p-2 rounded text-white grow-0 ${(borderColor || color) ? `bg-${bgColor || color}` : 'bg-blue'}`}>
            {
              React.isValidElement(message) ?
                <p className="text-white font-semibold grow min-w-16">
                  {React.isValidElement(message) ? message : ''}
                </p>
               : 
               <p className="text-white font-semibold grow min-w-16" dangerouslySetInnerHTML={{ __html: message}}>
              </p>
            }
          </div>
        </div>}
        <FontAwesomeIcon forwardedRef={forwardedRef} className={`${color ? `text-${color}` : 'text-blue'} ${large ? '' : 'text-xxs'} ${badge ? `rounded-full bg-${bgColor} w-3 h-3 p-0.5` : ''} top-0 ${message ? 'cursor-pointer' : ''}`} icon={icon || faCircleQuestion}/>
      </div>
    </div>
  )
}
