import { formatCurrency } from "selectors/formSelectors";

let countryNameLookup = {
    'AFG': 'Afghanistan',
    'ALB': 'Albania',
    'DZA': 'Algeria',
    'ASM': 'AmericanSamoa',
    'AND': 'Andorra',
    'AGO': 'Angola',
    'AIA': 'Anguilla',
    'ATA': 'Antarctica',
    'ATG': 'Antigua and Barbuda',
    'ARG': 'Argentina',
    'ARM': 'Armenia',
    'ABW': 'Aruba',
    'AUS': 'Australia',
    'AUT': 'Austria',
    'AZE': 'Azerbaijan',
    'BHS': 'The Bahamas',
    'BHR': 'Bahrain',
    'BGD': 'Bangladesh',
    'BRB': 'Barbados',
    'BLR': 'Belarus',
    'BEL': 'Belgium',
    'BLZ': 'Belize',
    'BEN': 'Benin',
    'BMU': 'Bermuda',
    'BTN': 'Bhutan',
    'BOL': 'Bolivia',
    'BES': 'Bonaire Sint Eustatius an dSaba',
    'BIH': 'Bosnia and Herzegovina',
    'BWA': 'Botswana',
    'BVT': 'Bouvet Island',
    'BRA': 'Brazil',
    'IOT': 'The British Indian Ocean Territory',
    'BRN': 'Brunei Darussalam',
    'BGR': 'Bulgaria',
    'BFA': 'Burkina Faso',
    'BDI': 'Burundi',
    'CPV': 'Cabo Verde',
    'KHM': 'Cambodia',
    'CMR': 'Cameroon',
    'CAN': 'Canada',
    'CYM': 'Cayman Islands',
    'CAF': 'Central African Republic',
    'TCD': 'Chad',
    'CHL': 'Chile',
    'CHN': 'China',
    'CXR': 'Christmas Island',
    'CCK': 'Cocos Islands',
    'COL': 'Colombia',
    'COM': 'Comoros',
    'COD': 'Congo',
    'COK': 'Cook Islands',
    'CRI': 'Costa Rica',
    'HRV': 'Croatia',
    'CUB': 'Cuba',
    'CUW': 'Curaçao',
    'CYP': 'Cyprus',
    'CZE': 'Czechia',
    'CIV': "Côted'Ivoire",
    'DNK': 'Denmark',
    'DJI': 'Djibouti',
    'DMA': 'Dominica',
    'DOM': 'The Dominican Republic',
    'ECU': 'Ecuador',
    'EGY': 'Egypt',
    'SLV': 'El Salvador',
    'GNQ': 'Equatorial Guinea',
    'ERI': 'Eritrea',
    'EST': 'Estonia',
    'SWZ': 'Eswatini',
    'ETH': 'Ethiopia',
    'FLK': 'The Falkland Islands',
    'FRO': 'The Faroe Islands',
    'FJI': 'Fiji',
    'FIN': 'Finland',
    'FRA': 'France',
    'GUF': 'French Guiana',
    'PYF': 'French Polynesia',
    'ATF': 'The French Southern Territories',
    'GAB': 'Gabon',
    'GMB': 'Gambia',
    'GEO': 'Georgia',
    'DEU': 'Germany',
    'GHA': 'Ghana',
    'GIB': 'Gibraltar',
    'GRC': 'Greece',
    'GRL': 'Greenland',
    'GRD': 'Grenada',
    'GLP': 'Guadeloupe',
    'GUM': 'Guam',
    'GTM': 'Guatemala',
    'GGY': 'Guernsey',
    'GIN': 'Guinea',
    'GNB': 'Guinea-Bissau',
    'GUY': 'Guyana',
    'HTI': 'Haiti',
    'HMD': 'Heard Island and McDonald Islands',
    'VAT': 'The Holy See',
    'HND': 'Honduras',
    'HKG': 'Hong Kong',
    'HUN': 'Hungary',
    'ISL': 'Iceland',
    'IND': 'India',
    'IDN': 'Indonesia',
    'IRN': 'Iran',
    'IRQ': 'Iraq',
    'IRL': 'Ireland',
    'IMN': 'Isle of Man',
    'ISR': 'Israel',
    'ITA': 'Italy',
    'JAM': 'Jamaica',
    'JPN': 'Japan',
    'JEY': 'Jersey',
    'JOR': 'Jordan',
    'KAZ': 'Kazakhstan',
    'KEN': 'Kenya',
    'KIR': 'Kiribati',
    'KOR': 'South Korea',
    'KWT': 'Kuwait',
    'KGZ': 'Kyrgyzstan',
    'LAO': 'Laos',
    'LVA': 'Latvia',
    'LBN': 'Lebanon',
    'LSO': 'Lesotho',
    'LBR': 'Liberia',
    'LBY': 'Libya',
    'LIE': 'Liechtenstein',
    'LTU': 'Lithuania',
    'LUX': 'Luxembourg',
    'MAC': 'Macao',
    'MDG': 'Madagascar',
    'MWI': 'Malawi',
    'MYS': 'Malaysia',
    'MDV': 'Maldives',
    'MLI': 'Mali',
    'MLT': 'Malta',
    'MHL': 'Marshall Islands',
    'MTQ': 'Martinique',
    'MRT': 'Mauritania',
    'MUS': 'Mauritius',
    'MYT': 'Mayotte',
    'MEX': 'Mexico',
    'FSM': 'Micronesia',
    'MDA': 'Moldova',
    'MCO': 'Monaco',
    'MNG': 'Mongolia',
    'MNE': 'Montenegro',
    'MSR': 'Montserrat',
    'MAR': 'Morocco',
    'MOZ': 'Mozambique',
    'MMR': 'Myanmar',
    'NAM': 'Namibia',
    'NRU': 'Nauru',
    'NPL': 'Nepal',
    'NLD': 'The Netherlands',
    'NCL': 'New Caledonia',
    'NZL': 'New Zealand',
    'NIC': 'Nicaragua',
    'NER': 'Niger',
    'NGA': 'Nigeria',
    'NIU': 'Niue',
    'NFK': 'Norfolk Island',
    'MNP': 'The Northern Mariana Islands',
    'NOR': 'Norway',
    'OMN': 'Oman',
    'PAK': 'Pakistan',
    'PLW': 'Palau',
    'PSE': 'Palestine',
    'PAN': 'Panama',
    'PNG': 'Papua New Guinea',
    'PRY': 'Paraguay',
    'PER': 'Peru',
    'PHL': 'The Philippines',
    'PCN': 'Pitcairn',
    'POL': 'Poland',
    'PRT': 'Portugal',
    'PRI': 'Puerto Rico',
    'QAT': 'Qatar',
    'MKD': 'Republic of North Macedonia',
    'ROU': 'Romania',
    'RUS': 'Russia',
    'RWA': 'Rwanda',
    'REU': 'Réunion',
    'WSM': 'Samoa',
    'SAU': 'Saudi Arabia',
    'SEN': 'Senegal',
    'SRB': 'Serbia',
    'SYC': 'Seychelles',
    'SLE': 'Sierra Leone',
    'SGP': 'Singapore',
    'SVK': 'Slovakia',
    'SVN': 'Slovenia',
    'SLB': 'Solomon Islands',
    'SOM': 'Somalia',
    'ZAF': 'South Africa',
    'SSD': 'South Sudan',
    'ESP': 'Spain',
    'LKA': 'SriLanka',
    'SDN': 'Sudan',
    'SUR': 'Suriname',
    'SJM': 'Svalbard and Jan Mayen',
    'SWE': 'Sweden',
    'CHE': 'Switzerland',
    'SYR': 'Syria',
    'TWN': 'Taiwan',
    'TJK': 'Tajikistan',
    'TZA': 'Tanzania',
    'THA': 'Thailand',
    'TLS': 'Timor-Leste',
    'TGO': 'Togo',
    'TKL': 'Tokelau',
    'TON': 'Tonga',
    'TTO': 'Trinidad and Tobago',
    'TUN': 'Tunisia',
    'TUR': 'Turkey',
    'TKM': 'Turkmenistan',
    'TCA': 'Turks and Caicos',
    'TUV': 'Tuvalu',
    'UGA': 'Uganda',
    'UKR': 'Ukraine',
    'ARE': 'United Arab Emirates',
    'GBR': 'Ireland',
    'USA': 'United States of America',
    'URY': 'Uruguay',
    'UZB': 'Uzbekistan',
    'VUT': 'Vanuatu',
    'VEN': 'Venezuela',
    'VNM': 'VietNam',
    'VGB': 'British Virgin Islands',
    'VIR': "U.S. Virgin Islands",
    'WLF': 'Wallis and Futuna',
    'ESH': 'Western Sahara',
    'YEM': 'Yemen',
    'ZMB': 'Zambia',
    'ZWE': 'Zimbabwe',
    'ALA': 'Ål and Islands'
  }

const timeToDays = (time) => {
    return Math.round(time / (1000 * 3600 * 24));
}

function isLeapYear(date) {
    return new Date(new Date(date).getFullYear(), 1, 29).getDate() === 29;
}
  
const daysInQuarter = (date) => {
    const month = new Date(date).getMonth();
    if (month < 3) {
        return isLeapYear(date) ? 91 : 90;
    } else if (month < 6) {
        return 91;
    } else if (month < 9) {
        return 92;
    } else {
        return 92;
    }
}

const daysLeftBiAnnual = (date) => {
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    if (month < 6) {
        return {value: timeToDays(new Date(year, 5, 30, 24) - new Date(date))/(isLeapYear(date) ? 182 : 181), string: `${timeToDays(new Date(year, 5, 30, 24) - new Date(date))}/${(isLeapYear(date) ? 182 : 181)} days`};
    } else {
        return {value: timeToDays(new Date(year, 11, 31, 24) - new Date(date))/184, string: `${timeToDays(new Date(year, 11, 31, 24) - new Date(date))}/184 days`};
    }
}

const daysDoneBiAnnual = (date) => {
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    if (month < 6) {
        return {value: timeToDays(new Date(date) - new Date(year - 1, 11, 31, 24))/(isLeapYear(date) ? 182 : 181), string: `${timeToDays(new Date(date) - new Date(year - 1, 11, 31, 24))}/${(isLeapYear(date) ? 182 : 181)} days`};
    } else {
        return {value: timeToDays(new Date(date) - new Date(year, 5, 30, 24))/184, string: `${timeToDays(new Date(date) - new Date(year, 5, 30, 24))}/184 days`};
    }
}

const percentOfMonthLeft = (date, longform=false) => {
    date = new Date(date);
    // date = new Date(date.getTime() + (1000 * 60 * 60 * 8))
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = new Date(year, month, 0).getDate();
    if (longform) {
        return `${daysInMonth - (date.getDate()-1)}/${daysInMonth}`
    }
    return (daysInMonth - (date.getDate()-1))/daysInMonth;
}

const percentOfMonth = (date, longform=false) => {
    date = new Date(date);
    // date = new Date(date.getTime() + (1000 * 60 * 60 * 8))
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = new Date(year, month, 0).getDate();
    if (longform) {
        return `${date.getDate()-1}/${daysInMonth}`
    }
    return (date.getDate()-1)/daysInMonth;
}

const daysFromStartOfQuarter = (date) => {
    date = new Date(date);
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
        return timeToDays(date - new Date(year, 0, 1));
    } else if (month < 6) {
        return timeToDays(date - new Date(year, 3, 1));
    } else if (month < 9) {
        return timeToDays(date - new Date(year, 6, 1));
    } else {
        return timeToDays(date - new Date(year, 9, 1));
    }
}

const daysLeftInQuarter = (date) => {
    date = new Date(date);
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
        return timeToDays(new Date(year, 2, 31) - date) + 1;
    } else if (month < 6) {
        return timeToDays(new Date(year, 5, 30) - date) + 1;
    } else if (month < 9) {
        return timeToDays(new Date(year, 8, 30) - date) + 1;
    } else {
        return timeToDays(new Date(year, 11, 31) - date) + 1;
    }
}

const firstDayOfQuarter = (date) => {
    date = new Date(date);
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
        return (new Date(year, 0, 1));
    } else if (month < 6) {
        return ( new Date(year, 3, 1));
    } else if (month < 9) {
        return (new Date(year, 6, 1));
    } else {
        return (new Date(year, 9, 1));
    }
}

const lastDayOfQuarter = (date) => {
    date = new Date(date);
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
        return new Date(year, 3, 0);
    } else if (month < 6) {
        return new Date(year, 6, 0);
    } else if (month < 9) {
        return new Date(year, 9, 0);
    } else {
        return new Date(year, 11, 31);
    }
}

const daysInYear = (date) => {
    date = new Date(date);
    if (isLeapYear(date)) {
        return 366;
    } else {
        return 365;
    }
}

const daysBetween = (first, second) => {
    return Math.round((first - second) / (1000 * 60 * 60 * 24));
}

const countryLookup = (country) => {
    return countryNameLookup[country?.toUpperCase()] || country || "Canada";
}

const securtityDepositInterest = (depositDate, adjustmentDate, deposit, useNote) => {
    let startDate = new Date(depositDate);
    adjustmentDate = new Date(adjustmentDate);
    let years = adjustmentDate.getFullYear() - startDate.getFullYear();
    let currentInterest = 0;
    let note = '';
    for (let start = 0; start <= years; start ++) {
        let endOfYear = new Date(startDate.getFullYear(), 11, 31);
        let currentEnd = adjustmentDate < endOfYear ? adjustmentDate : endOfYear;
        let interest = interestForYear(startDate);
        let numerator = daysBetween(currentEnd, startDate) + (years-start === 1 ? 1 : 0);
        let denominator = daysInYear(startDate);
        note += `${formatCurrency(Number(deposit) + Number(currentInterest))} * ${interest * 100}% * ${numerator}/${denominator} ${start < years ? '+ ' : ''}`;
        currentInterest += Math.round(((Number(deposit) + currentInterest) * (numerator)/denominator * interest)*100)/100;
        startDate = new Date(startDate.getFullYear() + 1, 0, 1);
    }
    note += ` = ${formatCurrency(currentInterest)}`;
    if (useNote) return note;
    return currentInterest;
}

const interestForYear = (date) => {
    date = new Date(date);
    let year = date.getFullYear();
    if (year === 2023) {
        return 0.0195;
    } else if (year === 2024) {
        return 0.027;
    } else if (year === 2025) {
        return 0.0095
    } else {
        return 0;
    }
}

export {
    interestForYear,
    timeToDays,
    daysInQuarter,
    daysLeftBiAnnual,
    daysDoneBiAnnual,
    daysFromStartOfQuarter,
    daysLeftInQuarter,
    percentOfMonthLeft,
    percentOfMonth,
    daysBetween,
    daysInYear,
    countryLookup,
    firstDayOfQuarter,
    lastDayOfQuarter,
};

const defaultExp = {
    securtityDepositInterest,
    interestForYear,
    timeToDays,
    daysInQuarter,
    daysLeftBiAnnual,
    daysDoneBiAnnual,
    daysFromStartOfQuarter,
    daysLeftInQuarter,
    percentOfMonthLeft,
    percentOfMonth,
    daysBetween,
    daysInYear,
    countryLookup,
    firstDayOfQuarter,
    lastDayOfQuarter
};

export default defaultExp;
  