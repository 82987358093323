import React, {useContext} from 'react';
import { getFirstDayOfMonth } from 'helpers/calendar';
import MonthDay from './MonthDay';
import CalendarContext from 'contexts/CalendarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

export default function CalendarMonth() {
	const {date, setDate, nextMonth, prevMonth} = useContext(CalendarContext);
  const firstDayOfMonth = getFirstDayOfMonth(date);
	const firstDayOfMonthCalendarDay = firstDayOfMonth.getDate();

  const getDays = () => {
		let days = [];
		let currentDate = firstDayOfMonth;
		let currentMonth = firstDayOfMonth.getMonth() ;
		let x = 0;
		for (let i = 0; i < firstDayOfMonth.getDay(); i ++) {
			days.push(<MonthDay key={`${i}+empty`} empty />);
		}

		while (currentDate.getMonth() === currentMonth) {
			days.push(<MonthDay key={firstDayOfMonthCalendarDay + x} dayOfWeek={x} dayOfMonth={firstDayOfMonthCalendarDay + x} />);
			currentDate.setDate(currentDate.getDate() + 1);
			x ++;
		}
		return days;
	}

	let monthName = new Date(date);
	monthName = monthName.toLocaleString('en-US', {month: 'long'}); // {month:'long'}

	return (
    <div className="flex flex-col gap-2 rounded">
			<div className="flex justify-between">
				<FontAwesomeIcon className={`${prevMonth ? '' : 'h-0'} cursor-pointer`} onClick={prevMonth} icon={faArrowAltCircleLeft}/>
				<p className="font-bold text-center dark:text-bone">{monthName}</p>
				<FontAwesomeIcon className={`${nextMonth ? '' : 'h-0'} cursor-pointer`} onClick={nextMonth} icon={faArrowAltCircleRight}/>
			</div>
			<div className="flex justify-around text-sm">
				<p className="font-medium dark:text-bone" >S</p>
				<p className="font-medium dark:text-bone" >M</p>
				<p className="font-medium dark:text-bone" >T</p>
				<p className="font-medium dark:text-bone" >W</p>
				<p className="font-medium dark:text-bone" >T</p>
				<p className="font-medium dark:text-bone" >F</p>
				<p className="font-medium dark:text-bone" >S</p>
			</div>
			<div className="flex flex-wrap">
				{getDays()}
			</div>
		</div>
  )
}
