import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import AboutPreview from '../about/Preview';
import RoundedButton from 'components/common/Button/RoundedButton';
import Testimonials from 'screens/about/Testimonials';
import mlsUploadGif from 'assets/images/about/mlsupload.gif';
import pttVideo from 'assets/videos/pttdemo.mov';
import printCheques from 'assets/videos/printCheques.mov';
import easyToLearn from 'assets/videos/easyToLearn.mov';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToFile, faCheck, faDatabase, faFileCsv, faHotel, faPlus, faUpload, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck, faPaste } from '@fortawesome/free-regular-svg-icons';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import fctLogo from "assets/images/survey/fctLogo.png";
import stewartTitleLogo from "assets/images/survey/stewartTitleLogo.png";
import camosun from "assets/images/partners/camosun.png";
import capilano from "assets/images/partners/capilano.png";
import okanagan from "assets/images/partners/okanagancollege.svg";
import sprottshaw from "assets/images/partners/sprottshaw.gif";
import vcc from "assets/images/partners/vcc.svg";


const LandingPage = () => {
  return (
    <div className="flex flex-col">
    {/* <Preview/> */}
    <AboutPreview/>
    <div className="w-full bg-dark-green">
      <PaddedPage className="self-center flex justify-center">
        <h1 className="text-white font-medium">Simple • Affordable • Customized</h1>
      </PaddedPage>
    </div>
    <div className="w-full bg-med-green flex flex-col relative">
      <PaddedPage className="self-center z-10">
        <div className="flex items-center h-test w-full gap-12 sm:flex-col sm:justify-center">
          {/* <img src={mlsUploadGif} alt="desktop" className="w-5/8 rounded-xl border border-3 object-cover"/> */}
          <video className="w-1/2 shadow-xl border border-3 rounded-xl max-w-400" height="auto" autoplay="true" muted="true" loop="true"><source src={easyToLearn} type="video/mp4"/></video>
          <div className="md:w-3/8 flex items-center justify-center">
            <div className="flex flex-col gap-4 max-w-100">
              <h1>
                Easy Has Never Been So Powerful⚡
              </h1>
              <h3>With our clean and super user friendly interface, simply jump on with little to no training!</h3>
              <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
                <RoundedButton>Start Now</RoundedButton>
              </a>
            </div>
          </div>
        </div>
      </PaddedPage>
      <div className="absolute w-full bottom-0 opacity-20">
        <div className="w-full overflow-hidden rotate-180">
            <svg className="relative block h-80 w-full" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path className="fill-dark-green" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
            </svg>
        </div>
        <div className="h-20 bg-dark-green"></div>
      </div>
    </div>
    <PaddedPage className="self-center bg-transparent">
      <div className="flex items-center w-full h-test flex-row-reverse gap-8 sm:flex-col sm:justify-center">
        {/* <img src={lotrGif} alt="desktop" className="w-5/8 rounded-xl shadow-xl border border-3 object-cover"/> */}
        <video className="w-1/2 shadow-xl border border-3 rounded-xl" height="auto" autoplay="true" muted="true" loop="true"><source src={pttVideo} type="video/mp4"/></video>
        <div className="md:w-3/8 flex items-center justify-center">
          <div className="flex flex-col gap-4 max-w-100">
            <h1>
              Auto Populate PTT Returns & LOTR Reports 📨
            </h1>
            <h3>with all the directors and shareholders information saving you hours of time!</h3>
            <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
              <RoundedButton>Start Now</RoundedButton>
            </a>
          </div>
        </div>
      </div>
    </PaddedPage>
    <div className="w-full bg-yellow flex flex-col relative overflow-hidden">
      <PaddedPage className="self-center z-10">
        <div className="flex items-center w-full h-test gap-12 sm:flex-col sm:justify-center">
          {/* <img src={chequePrintGif} alt="desktop" className="w-5/8 rounded-xl border-3 object-cover"/> */}
          <video className="w-1/2 shadow-xl border border-3 rounded-xl" height="auto" autoplay="true" muted="true" loop="true"><source src={printCheques} type="video/mp4"/></video>
          <div className="md:w-3/8 flex items-center justify-center">
            <div className="flex flex-col gap-4 max-w-100">
              <h1>
                Instantly Print Cheques 💵
              </h1>
              <h3>Reconcile your trust account, and export your general ledger data to use in other accounting software!</h3>
              <a className="sm:self-center" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
                <RoundedButton>Start Now</RoundedButton>
              </a>
            </div>
          </div>
        </div>
      </PaddedPage>
      <div className="absolute w-full -bottom-20 opacity-20 overflow-hidden -rotate-6 scale-110">
        <div className="w-full overflow-hidden rotate-180">
            <svg className="relative block h-80 w-full -scale-x-100" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path className="fill-primary" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
            </svg>
        </div>
        <div className="h-40 bg-primary"></div>
      </div>
    </div>
    <div className="w-full">
      <PaddedPage >
        <div className="self-center items-center w-full flex flex-col py-12 gap-12 2xl:mx-auto 2xl:max-w-8xl">
          <h1 className="text-primary font-medium">Additional Features</h1>
          <div className="flex gap-8 w-full sm:flex-col sm:pl-8 pl-4">
            <ul className='grow text-base font-medium flex flex-col gap-4'>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center text-xl">
                  {/* <FontAwesomeIcon icon={faCircleCheck}/> */}
                  <img className="h-6 w-6" src={ltsaLogo} alt="ltsa logo"/>
                </div>
                Full LTSA Integration</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center text-xl">
                  {/* <FontAwesomeIcon icon={faCircleCheck}/> */}
                  <img className="h-10 w-10" src={fctLogo} alt="fct logo"/>
                </div>
                FCT Title Insurance Integration</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-12 h-6 flex items-center justify-center">
                <img className="h-5 w-12" src={stewartTitleLogo} alt="fct logo"/>
                  {/* <div className="sepia">
                    💻
                  </div> */}
                </div>
                Stewart Title Insurance Integration</li>
            </ul>
            <ul className='grow text-base font-medium flex flex-col gap-4'>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon icon={faHotel}/>
                  {/* <div className="sepia">
                    🏢
                  </div> */}
                </div>
                Commercial Conveyances & Lending</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon icon={faArrowRightToFile}/>
                  {/* <div className="sepia">
                    🏬
                  </div> */}
                </div>
                Manufactured Homes transfers</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon icon={faDatabase}/>
                  {/* <div className="sepia">
                    🪪
                  </div> */}
                </div>
                Curate your own databases</li>
            </ul>
            <ul className='grow text-base font-medium flex flex-col gap-4'>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon icon={faFileCsv}/>
                  {/* <div className="sepia">
                    📑
                  </div> */}
                </div>
                TAF Report Generation</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon className="text-sm" icon={faWifi}/>
                  {/* <div className="sepia">
                    💻
                  </div> */}
                </div>
                Web Based - secured access anywhere/anytime</li>
              <li className="flex items-center gap-2">
                <div className="rounded-full w-6 h-6 flex items-center justify-center bg-grey">
                  <FontAwesomeIcon icon={faPaste}/>
                  {/* <div className="sepia">
                    📥
                  </div> */}
                </div>
                Document and Account Templates</li>
            </ul>
          </div>
          <h2 className="flex gap-2 font-medium"> 
            <div className="rounded-full w-6 h-6 flex items-center justify-center bg-dark-grey">
              <FontAwesomeIcon className="text-sm text-white" icon={faPlus}/>
            </div> 
            Many More Added Each Week Based On Client Requests!
          </h2>
          <a className="mt-2" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
            <RoundedButton>Start Now</RoundedButton>
          </a>
        </div>
      </PaddedPage>
    </div>
    <div className="w-full bg-med-green">
      <PaddedPage>
        <div className="self-center py-8 gap-4 flex flex-col justify-center items-center">
          <h1 className="text-white font-medium">Only $49.00<sup>+tx</sup>/File</h1>
          <h2 className="text-white font-normal">No contract, no minimum files, just good software.</h2>
          <a className="pt-4" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
            <RoundedButton white>Get Started Now</RoundedButton>
          </a>
        </div>
      </PaddedPage>
    </div>
    <div className="w-full">
      <PaddedPage>
        <div className="self-center py-8 gap-6 py-20 flex flex-col justify-center items-center">
          {/* <h1 className="font-medium">Proudly chosen as the Certifying and training platform by</h1> */}
          <h2 className="font-normal">Proudly chosen as the Certifying and Training platform by</h2>
          <div className="flex gap-8 justify-center items-center md:flex-wrap sm:flex-wrap xs:flex-wrap">
            <img src={camosun} alt="camosun" className="h-20 object-cover"/>
            <img src={capilano} alt="capilano" className="h-20 object-cover"/>
            <img src={okanagan} alt="okanagan" className="h-16 object-cover"/>
            <img src={sprottshaw} alt="sprottshaw" className="h-24 object-cover"/>
            <img src={vcc} alt="vcc" className="h-20 object-cover"/>
          </div>
        </div>
      </PaddedPage>
    </div>
    <Testimonials/>
  </div>
  );
};

export default LandingPage;
