import ButtonPrimary from 'components/common/Button'
import Loading from 'components/common/Loading'
import React from 'react'
import DocumentErrors from './DocumentErrors'
import DocumentQuestions from './DocumentQuestions'
import DocumentTitle from './DocumentTitle'

export default function DocumentContent({requestNeeded, requestError, loading, performRequest, generateDocument, downloadRef, errors, document}) {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between md:items-center p-2 gap-2">
        <DocumentTitle className="text-sm" errors={errors} document={document}/>
        <div className="flex gap-2">
          {
            requestNeeded &&
            <ButtonPrimary disabled={requestError || loading} onClick={() => performRequest()}>{loading ? <Loading/> : `Get ${requestNeeded.name}`}</ButtonPrimary>
          }
          <ButtonPrimary className="text-sm" onClick={generateDocument}>Download</ButtonPrimary>
          <a className="absolute" ref={downloadRef}></a>
        </div>
      </div>
      {errors && <DocumentErrors errors={errors} requestError={requestError}/>}
      {
        document.info.questions && document.info.questions.length > 0 &&
        <DocumentQuestions document={document}/>
      }
    </>
  )
}
