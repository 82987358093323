import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding,faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Firm({firm}) {
  return (
    <div className={`rounded cursor-pointer flex bg-white border-l-4 border-light-grey shadow hover:shadow-md dark:bg-shadow dark:border-midnight`}>
      <div className="bg-light-grey flex items-center justify-center pr-1 group-hover:bg-grey dark:bg-midnight"><span>⚖️</span></div>
      <div className={`flex p-2 items-center gap-2 grow w-full`}>  
        <strong>{firm.name}</strong>
        <div className="flex items-center gap-2 ml-4">
          {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
          <small><FontAwesomeIcon className="" icon={faEnvelope} /> {firm.email}</small>
        </div>
      </div>
    </div>
  )
}
