import React from 'react';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import ReccentSurveyFiles from 'components/survey/ReccentSurveyFiles';
import Calendar from 'components/calendar';

const HomePage = () => {
  return (
    <PaddedPage noBottom>
      <div className="flex flex-col">
        <div className="pb-4">
          <ReccentSurveyFiles/>
        </div>
        <div className="py-4">
          <Calendar showTitle/>
        </div>
      </div>
    </PaddedPage>
  );
};

export default HomePage;
