import React, { useState } from 'react';
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faEnvelopeOpenText, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import LetterheadDocPreview from './LetterheadDocPreview';
import { FullWidthPage } from 'components/common/Layout';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import ButtonPrimary from 'components/common/Button';

const GET_LETTERHEAD = gql`
  query GetLetterhead($id: String) {
    letterhead(id: $id) {
      id,
      name,
      url,
      type,
      previewUrl,
      firm {
        name,
        id
      },
      user {
        username
      }
    }
  }
`;

const DELETE_LETTERHEAD = gql`
  mutation DeleteLetterhead($id: String!) {
    deleteLetterhead(id: $id) {
      id,
    }
  }
`;


export default function Show() {
  const params = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_LETTERHEAD, {
    variables: { id: params.id }, 
  });
  const [deleteLetterheadMutation, { loading:loadingDelete }] = useMutation(DELETE_LETTERHEAD);

  const handleDelete = () => {
    if (loading) return;
    setShowDelete(false);
    deleteLetterheadMutation({
      variables: {id: data.letterhead.id}
    })
    .then(res => {
      navigate("/letterheads");
    })
  }

  return (
    data ?
    <>
       {showDelete && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete the "${data.letterhead.name}" letterhead.`}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      <FullWidthPage botttom className="pt-4 flex flex-col gap-2 pb-8">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <h1>✉️{data.letterhead.name}
            </h1>
            {data.letterhead.firm && data.letterhead.firm.name && <div className="rounded bg-primary text-white px-3 font-bold">{data.letterhead.firm.name}</div>}
          </div>
          <div className="flex items-center gap-2">
            <small><FontAwesomeIcon className="" icon={faArrowsUpDownLeftRight} /> {data.letterhead.type || "Letterhead"}</small>
            <small><FontAwesomeIcon className="" icon={faUser} /> {data.letterhead.user.username}</small>
            <small  onClick={()=> setShowDelete(true)} className="hover:text-red rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faTrash} /></small>
          </div>
        </div>
        <div>
          <h2>Image</h2>
          <hr className="mt-2"></hr>
        </div>
        <img className="h-12 self-start" src={data.letterhead.previewUrl} alt="letterhead preview small"/>
        <LetterheadDocPreview previewImage={data.letterhead.url} type={data.letterhead.type}/> 
      </FullWidthPage>
    </>
    :
    <>
    </>
  )
}
