import React, {useContext} from 'react'
import SurveyContext from 'contexts/SurveyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';
import SurveyChapterItem from './SurveyChapterItem';
import LoadingText from 'components/loading/LoadingText';

export default function SurveyPageChapters({first, last}) {
  const { fileId, surveyId, currentPage, pages, errors } = useContext(SurveyContext);

  return (
    <div className="flex flex-col max-w-22 min-w-22 hidden md:flex border-r border-transparent-dark-grey dark:border-slate bg-light-light-grey dark:bg-midnight dark:border-l self-stretch">
        <div className={`
          flex flex-col z-10 sticky max-h-3/4 mb-10 border-dark
          md:top-10
          lg:top-10
          xl:top-10
          2xl:top-22
        `}>
          {pages ? pages.map((page, i) => (
            <SurveyChapterItem key={i} pageNumber={i} currentPage={currentPage} page={page}/>
            ))
          :
          <>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
          </>
          }
        </div>
        <div className="relative grow flex flex-col justify-end">
          <div className="sticky bottom-0 flex justify-between p-4">
              {!first && <Link className="" to={`/files/${surveyId}/${fileId}/${currentPage - 1}`} disabled={errors}>
                <div className="rounded-full dark:text-bone text-transparent-darkest-grey bg-transparent-grey hover:bg-light-grey flex items-center justify-center cursor-pointer w-8 h-8">
                  <FontAwesomeIcon icon={faArrowLeft}/>
                </div>
              </Link>}
              <Link className="ml-auto" to={last ? `/files/${surveyId}/${fileId}/summary` : `/files/${surveyId}/${fileId}/${currentPage + 1}`}>
                <div className="rounded-full dark:text-bone text-transparent-darkest-grey bg-transparent-grey hover:bg-light-grey flex items-center justify-center cursor-pointer w-8 h-8">
                  <FontAwesomeIcon icon={faArrowRight}/>
                </div>
              </Link>
          </div>
        </div>
    </div>
  )
}
