import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export default function SurveyBadge({blank, survey, onClick, selectable, onRemove}) {

  const handleRemove = (e, val) => {
    e.stopPropagation();
    onRemove(val);
  }

  return (
    <div
      className={`
        rounded p-2 relative
        ${onRemove && 'pr-6'}
        ${selectable && 'hover:bg-light-green cursor-pointer'} 
        ${blank ? 'px-8 bg-white' : 'bg-light-grey'}
        dark:text-shadow
        `
      }
      onClick={onClick}
    >
      {survey && survey.name}
      {blank && <FontAwesomeIcon className="text-grey w-full" icon={faCircleXmark} />}
      {onRemove &&
        <FontAwesomeIcon onClick={(e) => handleRemove(e, survey)} className="absolute top-1 right-1 cursor-pointer" icon={faTimes} />
      }
    </div>
  )
}
