import React from 'react'

export default function LoadingText({color, random, className}) {

  return (
    <span className={`animate-pulse rounded dark:text-bone ${color ? `bg-${color} text-${color} opacity-10` : 'bg-light-light-grey dark:bg-shadow text-light-light-grey'} ` + className}>
      { random ? "-----".repeat(Math.random()*10 + 1) : "-- ---- ----"}
    </span>
  )
}
