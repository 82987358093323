import React, { useEffect, useState } from 'react';
import SurveyProvider from 'providers/SurveyProvider';
import { useParams } from 'react-router-dom';

export default function Survey({children}) {
  const params = useParams();
  const [fileId, setFileId] = useState();
  const surveyId = params.surveyId;
  const currentPage = Number(params.pageNumber);

  useEffect(() => {
    if (fileId && (params?.fileId !== fileId)) {
      setFileId(null);
    } if (!fileId && params?.fileId) {
      setFileId(params.fileId);
    }
  }, [params?.fileId, fileId]);

  return (
      fileId ?
      <SurveyProvider surveyId={surveyId} fileId={fileId} currentPage={currentPage}>
        {children}
      </SurveyProvider>
      :
      <SurveyProvider key="File" surveyId={null} fileId={null} currentPage={null}>
        {children}
      </SurveyProvider>
  )
}
