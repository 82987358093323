import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons';
import AddClient from './AddClient';
import { useQuery, useMutation, gql } from "@apollo/client";
import Client from 'components/client/Client';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import { typeToLabel } from 'helpers/general';
import ButtonPrimary from 'components/common/Button';
import ParentContext from 'contexts/ParentContext';

const GET_FILES = gql`
  query GetFiles($filters: JSONObject) {
    files(filters: $filters) {
      id,
      surveyId,
      nickname,
      fileNumber
    }
  }
`;

export default function FileInput({label, onChange}) {
  const [search, setSearch] = useState();
  const searchRef = useRef();
  const { loading, error, data, refetch } = useQuery(GET_FILES, {
    variables: {filters: {search}},
    skip: !search || (search === ' ' || search.length < 2) ? true : false
  });
  const [selected, setSelected] = useState([]);

  const handleSelect = (val) => {
    setSelected(val);
    onChange(val)
  }

  const options = data ? 
  data.files.map(file => (
    <div 
      key={file.id}
      className="hover:bg-light-light-grey dark:bg-shadow rounded cursor-pointer p-2"
      onClick={() => handleSelect(file)}
    >
      <small className="text-primary font-bold">#{file.fileNumber}</small> {file.nickname}
    </div>
  )) 
  : [];

  return (
    <div className="flex flex-col justify-start gap-2 items-start">
      {selected ?
        <div>
          <div 
            key={selected.id}
            className="hover:bg-light-grey bg-light-light-grey dark:bg-shadow rounded cursor-pointer p-2"
            onClick={() => handleSelect()}
          >
            <small className="text-primary font-bold">#{selected.fileNumber}</small> {selected.nickname}
          </div>
        </div>
        :
        <div className={`flex flex-col`}>
          <div className="relative">
            <Input placeholder={`Search File`} valueOverride={search} forwardRef={searchRef} className={`pl-8 ${search && 'rounded-b-none'}`} onChange={setSearch}/>
            <FontAwesomeIcon  className={`absolute top-2 left-1`} icon={faMagnifyingGlass}/>
          </div>
          {search &&
            <div className="flex flex-col border border-t-none bg-white p-4 gap-2 shadow-md rounded-b">
              <hr></hr>
              <div className="flex flex-col max-h-32 overflow-y-scroll scroller">
                {
                  options.length > 0 ?
                  options
                  :
                  <small className="text-light-grey">None Found</small>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}
