import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function isObject(value) {
  return !(value instanceof Date) && !Array.isArray(value) && !Object.is(value, null) && !Object.is(value, undefined) && !(value instanceof Function)
}

const useErrors = () => {
  const [errors, setErrors] = useState({});
  const [errorHandlers, setErrorHandlers] = useState({});
  const navigate = useNavigate();

  const removeError = (id) => {
    let newErrors = {...errors};
    delete newErrors[id];
    setErrors(newErrors);
  };

  const setErrorHandler = (operationName, handler) => {
    setErrorHandlers({...errorHandlers, [operationName]: handler});
  }

  const addError = useCallback((error, operationName) => {
    if (!isObject(error)) {
      error = { message: error };
    }
    let key =  operationName || error.code || error.statusCode || Math.floor(Math.random() * 10000);
    if (error.message.includes('Login Token Expired, please re-login')) {
      localStorage.removeItem('token');
      localStorage.removeItem('impersonationToken');
      return;
    }
    if (errorHandlers[key]) {
      error.handled = true;
      return errorHandlers[key](error);
    }

    setErrors(errors => {
      let newErrors = {...errors};
      newErrors[key] = {...error, key};
      return newErrors;
    });
  }, [errorHandlers]);

  return {
        errors,
        currentError: Object.entries(errors)[0],
        setErrorHandler,
        addError,        
        unauthorized: Object.keys(errors).includes('401'),
        removeError
  };
};

export default useErrors;
