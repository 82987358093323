import React, {useEffect, useState} from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import AppContext from 'contexts/AppContext';
import useLoading from 'hooks/useLoading';

const AppProvider = ({ children }) => {
  const {loading, addLoading, removeLoading} = useLoading();
  const [menuOpen, setMenuOpen] = useState(true);
  const {currentUser, login, logout, impersonating, impersonate, stopImpersonating, loading: userLoading } = useCurrentUser();

  // TODO globl loading, error, messages
  return (
    <AppContext.Provider value={{ 
      currentUser,
      login, 
      logout,
      impersonating,
      impersonate,
      stopImpersonating,
      loading: userLoading || loading,
      addLoading,
      removeLoading,
      menuOpen,
      toggleMenu: () => setMenuOpen(!menuOpen),
      closeMenu: () => setMenuOpen(false),
      success: null,
    }} >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
