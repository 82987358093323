import React, {useContext, useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { List } from 'components/inputs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import FIrmSelect from 'components/inputs/FIrmSelect';
import Message from 'components/common/Message';
import useFirmChoices from 'hooks/useFirmChoices';
import AppContext from 'contexts/AppContext';
import { Table } from 'components/surveyInputs';
import Tooltip from 'components/common/Tooltip';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const INVITE_USER = gql`
  mutation newInvite($email: String!, $type: String, $firmId: String) {
    newInvite(email: $email, type: $type, firmId: $firmId) {
      id,
      email
    }
  }
`;

const typeOptions = (firm) => {
	 let options = firm ? [
		{value: 'guest', name: "Guest"},
		{value: 'basic', name: "Basic"},
		{value: '', name: "Privileged"},
		{value: 'admin', name: "Admin"},
	] :
	[
		{value: '', name: "Regular"},
		{value: 'admin', name: "Admin"},
	]
	return options;
} 

const messageLookup = {
	'guest': 'This designation is generally used for contract conveyancers.  Allows the user to work only on conveyancing files that they have created or have been assigned.  No access to other files in the firm or office.',
	'basic': 'The ability to start a file, complete a conveyance file, work on other files within the firm but no access to trust accounting.', 
	'': 'All functions of Basic users plus the ability to view the trust account, print trust account cheques, add trust account items, create document groups, and create survey answer templates.',
	'admin': 'All functions of privileged users plus the ability to add users, delete users, delete files, change responsible conveyancers on files, change default checklist settings, and duplicate files.',
}

export default function InviteUser({firmId}) {
  const [success, setSuccess] = useState();
  const {currentUser} = useContext(AppContext);
  const {firmChoices, firmId:defaultFirmId, setFirmId} = useFirmChoices(true);
	const [email, setEmail] = useState();
	const [type, setType] = useState();
	const [firm, setFirm] = useState(firmId || defaultFirmId);
	const [newUserInviteMutation, { loading, error }] = useMutation(INVITE_USER);

  const valid = () => {
		return email && (firm ? true : type !== 'guest');
	}

	const onSubmit = () => {
		newUserInviteMutation({
			variables: {
				email,
				type,
				firmId: firm
			}
		})
    .then(res => {
		setEmail();
		// setType();
		// setFirm();
		setSuccess(res.data.newInvite.email);
    })
	}
  return (
		<div className="flex flex-col gap-2">
			<Table title="👤Invite User">
				{success && <Message positive>
				{success} invited!
				</Message>}
				<div className="flex flex-col gap-2 items-start"> 
					<div className="flex flex-col">
						<label className='dark:text-bone'>Email</label>
						<Input valueOverride={email} onChange={setEmail}/>
					</div>
					{!firmId && firmChoices.length > 0 &&
						<div className="flex flex-col">	
							<label>Firm</label>
							<List initialValue={firmChoices[0]} onChange={setFirmId} className="" options={firmChoices}/>
						</div>
					}
					<div className="flex flex-col relative">
						<label className='dark:text-bone'>Type</label>
						<List valueOverride={type} onChange={setType} options={typeOptions(firm)}/>
						<div className="absolute top-2 -right-2">
							<Tooltip
								icon={faCircleQuestion} 
								className={""}
								color="blue" 
								message={<span>
									{messageLookup[type] || messageLookup['']}
								</span>}
							/> 
						</div>
					</div>
					<ButtonPrimary disabled={loading || !valid()} className="mt-4 text-sm" onClick={onSubmit}> 
						Invite!
					</ButtonPrimary> 
				</div> 
			</Table>
		</div>
  )
}
