import React, {useState, useMemo} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleLeft, faSquarePen, faFile, faFingerprint, faMoneyCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Input, { Checkbox, List } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import SurveySelector from 'components/inputs/SurveySelector';
import SurveyBadge from 'components/survey/SurveyBadge';
import useFirmChoices from 'hooks/useFirmChoices';
import { FullWidthPage } from 'components/common/Layout';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import useMortgageChoices from 'hooks/useMortgageChoices';
import MortgageSelector from 'components/inputs/MortgageSelector';

const GET_DOCUMENT = gql`
  query GetDocument($id: String!) {
    document(id: $id) {
      id,
      name,
      surveys{
        id, 
        name
      }
      template,
      type,
      category,
      info,
      firmId
    }
  }
`;

const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $name: String, $surveyIds: [String], $template: Upload, $info: JSONObject, $category: String, $firmId: String) {
    updateDocument(id: $id, name: $name, surveyIds: $surveyIds, template: $template, info: $info, category: $category, firmId: $firmId) {
      id,
      name,
      surveyIds,
      template,
      info
    }
  }
`;

export default function Document() {
  const {firmChoices, firmId, setFirmId} = useFirmChoices(null, {name: "None", value: ""}, null, true);
  const {mortgageChoices} = useMortgageChoices(true, false);
  const navigate = useNavigate();
  const params = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_DOCUMENT, {
    variables: { id: params.id },
    onCompleted: (data) => {
      setFirmId(data.document?.firmId);
    }
  });
  const [deleteDocumentMutation, { error:deleteError }] = useMutation(DELETE_DOCUMENT);
  const [updateDocumentMutation, { error:updateError, loading:updateLoading }] = useMutation(UPDATE_DOCUMENT);

  const handleDelete = () => {
    setShowDelete(false);
    deleteDocumentMutation({variables: {id: params.id}})
      .then(() => navigate("/admin/documents"));
  }

  const onFileChange = (event) => {
		setUpdateInfo({...updateInfo, template: event.target.files[0]});
	}

  const onInfoChange = (info) => {
		setUpdateInfo({...updateInfo, info: {...(updateInfo.info || {}), ...info}});
	}
  const valid = useMemo(() => {
    let errors = [];
    if (updateInfo.info) {
      try {
        updateInfo.info.variables && console.log(JSON.parse(updateInfo.info.variables));
        updateInfo.info.requests && console.log(JSON.parse(updateInfo.info.requests));
      } catch(err) {
        errors.push("Invalid variables")
      }
    }
    return errors.length === 0
  }, [data, updateInfo]);
  const handleUpdate = () => {
    if (valid) {
      setEdit(false);
      updateDocumentMutation({variables: {
        id: params.id,
        ...updateInfo,
        firmId: (firmId || null),
        info: updateInfo.info ? 
          (["ltsa", "mortgage"].includes(data.document.type) ? 
            {...updateInfo.info, mortgageIds: updateInfo.info?.mortgageIds || data?.document?.info?.mortgageIds, variables: updateInfo.info.variables && JSON.parse(updateInfo.info.variables), questions: updateInfo.info.questions && JSON.parse(updateInfo.info.questions)} 
            : 
            {...updateInfo.info, requests: updateInfo.info.requests && JSON.parse(updateInfo.info.requests), questions: updateInfo.info.questions && JSON.parse(updateInfo.info.questions)} ) 
          : 
          undefined
      }})
        .then(() => refetch());
    }
  }
  return (
    data ?
    <FullWidthPage bottom className="pt-4">
      <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"></link>
      <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script>
      {showDelete && <ConfirmNotice 
        title={`Are you sure you want to delete ${data.document.name}?`}
        message={`To delete this document type "${data.document.name}" below`}
        confirmText={data.document.name}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h1 className='dark:text-bone'>
            📑
            {
              data.document.type === "ltsa" &&
                <img className="h-8 inline" src={ltsaLogo} alt="ltsaLogo"/>
            }
            {
              ["mortgage", "pdf"].includes(data.document.type) &&
              <FontAwesomeIcon className="" icon={faFilePdf} />
            }
            {
              ["cheque"].includes(document.type) &&
              <FontAwesomeIcon className="" icon={faMoneyCheck} />
            }
            &nbsp;
            {edit ? <Input className="text-2xl font-medium" valueOverride={data.document.name} onChange={(name) => setUpdateInfo({...updateInfo, name})} /> : data.document.name}
          </h1>
          <div className="flex items-center gap-2">
            <small><FontAwesomeIcon className="" icon={faFingerprint} /> {data.document.id}</small>
            <small  onClick={()=> setShowDelete(true)} className="hover:text-red rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faTrash} /></small>
            <small  onClick={()=> setEdit(!edit)} className="hover:text- dark:hover:text-shadow -ml-2 rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faSquarePen} /></small>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2">
          <div>
            <h2 className='dark:text-bone'>Category</h2>
            <hr className='dark:border-bone'></hr>
          </div>
          {edit ? 
            <Input className="" valueOverride={data.document.category} onChange={(category) => setUpdateInfo({...updateInfo, category})} /> 
            : 
            <div className="rounded p-2 bg-light-grey dark:text-shadow">
              {data.document.category}
            </div>
          }
        </div>
        <div className="flex flex-col">
          <div>
            <h2 className='dark:text-bone'>Surveys</h2>
            <hr className='dark:border-bone'></hr>
          </div>
          {edit ? 
            <SurveySelector valueOverride={data.document.surveys} onChange={(surveys) => setUpdateInfo({...updateInfo, surveyIds: surveys.map(survey => survey.id)})} /> 
            : 
            <div className="flex flex-wrap gap-2 pt-2">
              {data.document.surveys.map(survey => <SurveyBadge key={survey.id} survey={survey}/>)}
              {data.document.surveys.length < 1 && <SurveyBadge blank/>}
            </div>
          }
        </div>
        <div className="flex flex-col">
          <div>
            <h2 className='dark:text-bone'>Firm</h2>
            <hr className='dark:border-bone'></hr>
          </div>
          {edit ? 
            <List onChange={setFirmId} className="" options={firmChoices} valueOverride={firmId}/>
            : 
            <div className="flex flex-wrap gap-2 pt-2">
              {firmChoices.find(firm => firm.value == data.document?.firmId)?.name}
            </div>
          }
        </div>
        {
          ["mortgage", "pdf"].includes(data.document.type) &&
          <>
            <div className="flex flex-col">
              {
                data.document.type === "mortgage" &&
                <>
                  <div>
                    <h2 className='dark:text-bone'>Mortgage</h2>
                    <hr className='dark:border-bone'></hr>
                  </div>
                  {edit ? 
                    <MortgageSelector valueOverride={( updateInfo.info?.mortgageIds.length > 0 ?  updateInfo.info?.mortgageIds : data.document.info.mortgageIds).map(id => mortgageChoices.find(choice => choice.value == id))} onChange={(val) => onInfoChange({mortgageIds: val.map(survey => survey.value)})}/>
                    : 
                    <div className="flex flex-wrap gap-2 pt-2">
                      {data.document?.info?.mortgageIds.map(id => mortgageChoices.find(choice => choice.value == id)?.name).join(", ")}
                    </div>
                  }
                </>
              }
            </div>
            <div className="flex flex-col">
              <div>
                <h2 className='dark:text-bone'>PDF</h2>
                <hr className='dark:border-bone'></hr>
              </div>
              {
                edit ?
                <Input type="text" valueOverride={data?.document?.info?.pdfLink} onChange={(pdfLink) => onInfoChange({pdfLink})} /> 
                :
                <span>{data?.document?.info?.pdfLink}</span>
              }
            </div>
          </>
        }
        <div>
          {
            data.document.type == "ltsa" ?
              <>
                <div>
                  <h2 className='dark:text-bone'>Info</h2>
                  <hr className='dark:border-bone'></hr>
                </div>
                {edit ? 
                  <div className="flex flex-col gap-2">
                    <label>LTSA Document Type</label>
                    <Input type="text" valueOverride={data.document.info.documentType} onChange={(documentType) => onInfoChange({documentType})} />
                    <label>Variables (JSON LIST)</label>
                    <Input type="textarea" valueOverride={data.document.info.variables} onChange={(variables) => onInfoChange({variables})} />
                    <label>Warning</label>
                    <Input type="text" valueOverride={data.document.info.warning} onChange={(warning) => onInfoChange({warning})} />
                    <Checkbox text="Multiple" initialValue={data.document.info.multiple} onChange={(val) => onInfoChange({multiple: val})} />
                    <label>Questions</label>
                    <Input type="textarea" valueOverride={JSON.stringify(data.document.info.questions)} onChange={(questions) => onInfoChange({questions})} />  
                  </div>
                  : 
                  <span>{JSON.stringify(data.document.info)}</span>
                }
              </>
            : ["mortgage", "pdf"].includes(data.document.type) ?
              <>
                <div>
                  <h2 className='dark:text-bone'>Info</h2>
                  <hr className='dark:border-bone'></hr>
                </div>
                {edit ? 
                  <div className="flex flex-col gap-2">
                    <label>Variables (JSON LIST)</label>
                    <Input type="textarea" valueOverride={data.document.info.variables} onChange={(variables) => onInfoChange({variables})} />
                    <label>Questions</label>
                    <Input type="textarea" valueOverride={JSON.stringify(data.document.info.questions)} onChange={(questions) => onInfoChange({questions})} />  
                  </div>
                  : 
                  <span>{JSON.stringify(data.document.info)}</span>
                }
              </>
            :
              <>
                <div>
                  <h2 className='dark:text-bone'>Template</h2>
                  <hr className='dark:border-bone'></hr>
                </div>
                {edit ? 
                  <div className="flex flex-col gap-2">
                    <label>File</label>
                    <input type="file" onChange={onFileChange} />
                    <label>Requests</label>
                    <Input type="textarea" valueOverride={JSON.stringify(data.document.info.requests)} onChange={(requests) => onInfoChange({requests})} />
                    <label>Warning</label>
                    <Input type="text" valueOverride={data.document.info.warning} onChange={(warning) => onInfoChange({warning})} />
                    <label>Questions</label>
                    <Input type="textarea" valueOverride={JSON.stringify(data.document.info.questions)} onChange={(questions) => onInfoChange({questions})} />  
                  </div>
                  : 
                  <span>Document preview</span>
                }
              </>
          }
        </div>
        {
          edit && <ButtonPrimary className="self-start" disabled={!valid || updateLoading} onClick={handleUpdate}>Update</ButtonPrimary>
        }
      </div>
    </FullWidthPage>
    :
    <></>
  )
}
