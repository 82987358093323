import React, {useContext, useEffect, useMemo, useState} from 'react';
import { getSurveyValue, getTotalValue, pathMaker } from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Input from 'components/inputs/Input';
import TableContext from 'contexts/TableContext';
import Tooltip from 'components/common/Tooltip';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import ParentContext from 'contexts/ParentContext';
import { useCallback } from 'react';

const format = (val) => {
    return Number(val) ? Math.round(val * 100)/100 : val;
}

const TotalInput = ({ 
    type,
    formStyles,
    total,
    questionKey
}) => {
    const { file, totals, updateCalculation, updateTotalsAndCalculations } = useContext(SurveyContext);
    const tableContext = useContext(TableContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const {row, prevRows, parentPath} = useContext(ParentContext);

    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    const totalArray = useMemo(() => {
        return {[total]: totals[total]};
    }, [totals, total]);

    const calculatedTotal = useCallback(() => {
        let totalValue = file && file.answers && getTotalValue(file.answers, totalArray, total);
        return Number(totalValue) ? Math.round(totalValue * 100)/100 : totalValue;
    }, [totalArray, total, file]);
    
    const currentValue = useMemo(() => {
        return getSurveyValue(file.answers, path);
    }, [file.answers, path]);
    
    const currentTotal = useMemo(() => {
        return getSurveyValue(file.answers, total);
    }, [file.answers, path])
    useEffect(() => {
        if (tableContext) {
            tableContext.setBottomPadded(true);
        }
    },[tableContext]);
    
    useEffect(() => {
        if (!totalArray[total] || !totalArray[total].includes(path)) {
            if (currentValue) {
                updateTotalsAndCalculations();
            }
        } else if (!firstLoad) {
            let newTotal = format(calculatedTotal());
            updateCalculation(total, newTotal);
        }
        if (firstLoad) {
            setFirstLoad(false);
        }
    }, [currentValue]);
    
    return (
        <div className="total-input absolute -bottom-16 w-full">
            <label className="block dark:text-bone">
                Total
                <Tooltip icon={faCalculator} className="text-orange border-orange" color="orange" message="This is a calculated value. No input required."/>
            </label>
            <Input className={formStyles} type={type || 'number'} readOnly valueOverride={format(currentTotal || 0)} />
        </div>
    )
};

export default TotalInput;