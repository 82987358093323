import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileSignature, faPenToSquare, faPhone, faBuilding, faFingerprint, faFax, faChevronDown, faChevronUp, faCommentDollar, faHouse, faLocationDot, faSailboat, faPersonRunning, faHashtag, faWarning, faFileContract, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import AddMortgage from 'components/surveyInputs/AddMortgage';
import { Radios } from 'components/inputs';
import Tooltip from 'components/common/Tooltip';
import TooltipBasic from 'components/common/TooltipBasic';

export default function Mortgage({ mortgage, onDeselect, children, onUpdate }) {
  const [edit, setEdit] = useState();
  const [expand, setExpand] = useState(false);

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }

  return (
    edit ?
      <AddMortgage edit={mortgage} readOnly={mortgage.public} close={() => setEdit(false)} onCreate={onCreate} />
      :
      <div className="rounded relative flex flex-col min-w-40">
        <TooltipBasic message="Edit Mortgage" className="absolute top-1 right-6">
          <div onClick={() => setEdit(true)} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-green flex justify-center items-center">
          <FontAwesomeIcon  className={`text-xxs`} icon={mortgage.public ? faMagnifyingGlass : faPenToSquare}/>
          </div>
        </TooltipBasic>
        <TooltipBasic message="Unselect Mortgage" className="absolute top-1 right-1.5">
          <div onClick={onDeselect} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
            <FontAwesomeIcon  className={`text-xs`} icon={faTimes}/>
          </div>
        </TooltipBasic>
        <div className="flex items-center gap-1.5 p-3 pr-4 bg-med-grey rounded-t text-white pr-12 dark:bg-midnight dark:border dark:border-transparent-grey">
          <FontAwesomeIcon className={`text-xs relative -right-px`} icon={faFileSignature} />
          <h3 className="font-semmibold text-sm text-white">
            {mortgage.name}
            <Tooltip
              icon={faWarning}
              color="orange"
              message={<span>All pre-populated fields and schedules are provided for your convenience and are based on standard bank documents that may or may not be up to date.<br></br>
                Every file is unique. By continuing, you acknowledge it is your obligation to ensure all entries are correct for your specific client's needs.
              </span>}
            />
          </h3>
        </div>
        <div className="flex flex-col gap-1.5 p-3 border-x border-t border-transparent-grey bg-white dark:bg-shadow">
          <div className="flex gap-1.5">
            <small className="font-semmibold text-xs"><FontAwesomeIcon className={``} icon={faBuilding} /> {mortgage.bank && mortgage.bank.companyName}</small>
          </div>
          <div className="flex gap-1.5">
            <small className="font-semmibold text-xs"><FontAwesomeIcon className={``} icon={faFingerprint} /> {mortgage.id}</small>
          </div>
          <div className="flex flex-wrap gap-1">
            {
              mortgage.documents &&
              Object.entries(mortgage.documents).map(([key, value]) => <a target="_blank" href={value} className="text-bold text-white no-underline rounded bg-green px-1 text-sm dark:text-shadow">
                <FontAwesomeIcon icon={faFileContract}/> {key}
              </a>
              )
            }
          </div>
          {children && children.length > 0 && <div className="text-xs">
            {children}
          </div>}
        </div>
        <div
          onClick={() => setExpand(!expand)}
          className={`
            bg-light-light-grey dark:bg-shadow flex flex-col items-center justify-center border-x border-b border-transparent-grey cursor-pointer rounded-b
            text-med-grey
            hover:bg-light-grey
            hover:text-dark-grey
        `}>
          <div className={`
            transition ease-in-out ${expand ? "h-auto" : "hidden"}
            flex flex-col bg-white dark:bg-shadow dark:text-bone text-dark-grey border-t border-t-light-grey w-full dark:border-midnight p-3
          `}>
            <div className="flex flex-col gap-1.5">
              <small><strong> Principal</strong><br></br>{mortgage.principal}</small>
              <small><strong> Interest Rate</strong><br></br>{mortgage.interestRate}</small>
              <small><strong> Interest Adjustment Date</strong><br></br>{mortgage.interestAdjustmentDate}</small>
              <small><strong> Interest Calculation Period</strong><br></br>{mortgage.interestCalcPeriod}</small>
              <small><strong> Payment Date</strong><br></br>{mortgage.paymentDates}</small>
              <small><strong> First Payment Date</strong><br></br>{mortgage.firstPaymentDate}</small>
              <small><strong> Periodic Payment</strong><br></br>{mortgage.monthlyPayments}</small>
              <small><FontAwesomeIcon className="" icon={faCommentDollar} /> <strong>Interest Act Statement</strong> <br></br>{mortgage.interestActStatement}</small>
              <small><strong> Last Payment Date</strong><br></br>{mortgage.lastPaymentDate}</small>
              <small>
                <FontAwesomeIcon className="" icon={faHouse} />
                <strong> Assignment of Rents</strong>
                <Radios readOnly={true} valueOverride={mortgage.assignmentOfRents} options={[{ name: 'yes', value: true }, { name: 'no', value: false }]} />
              </small>
              {mortgage.assignmentOfRents &&
                <>
                  <small><FontAwesomeIcon className="" icon={faHashtag} /><strong>A/R Page #</strong><br></br>{mortgage.assignmentOfRentsPageNumbe}</small>
                  <small><FontAwesomeIcon className="" icon={faHashtag} /><strong>A/R Paragraph #</strong><br></br>{mortgage.assignmentOfRentsParagraphNumber}</small>
                </>
              }
              <small><FontAwesomeIcon className="" icon={faLocationDot} /> <strong>Place of Payment</strong><br></br> {mortgage.placeOfPayment}</small>
              <small><strong> Balance Due Date</strong><br></br>{mortgage.balanceDueDate}</small>
              <small>
                <FontAwesomeIcon className="" icon={faSailboat} />
                <strong> Floating Charge on Land</strong>
                <Radios readOnly={true} valueOverride={mortgage.floatingChargeOnLand} options={[{ name: 'yes', value: true }, { name: 'no', value: false }]} />
              </small>
              <small>
                <FontAwesomeIcon className="" icon={faPersonRunning} />
                <strong> Current or Running Account</strong>
                <Radios readOnly={true} valueOverride={mortgage.currentOrRunningAcct} options={[{ name: 'yes', value: true }, { name: 'no', value: false }]} />
              </small>
              <small>
                <FontAwesomeIcon className="" icon={faSailboat} />
                <strong> Term Type</strong>
                <Radios 
                  readOnly={true} 
                  valueOverride={mortgage.termType} 
                  options={[
                    {name: 'Prescribed Standard Mortgage Terms', value: 'PresStdMortgageTerm'},
                    {name: 'Filed Standard Mortgage Terms', value: 'FiledStdMortgageTerm'},
                    {name: 'Express Mortgage Terms', value: 'ExpressMortgageTerm'},
                  ]} 
                />
              </small>
              <small><FontAwesomeIcon className="" icon={faHashtag} /><strong> dfNumber</strong><br></br>{mortgage.dfNumber}</small>
              <small><FontAwesomeIcon className="" icon={faHashtag} /><strong> Additional Or Modified Terms</strong><br></br>{mortgage.addnlOrModifiedTerms}</small>
              <small><FontAwesomeIcon className="" icon={faHashtag} /><strong> Prior Encumbrances</strong><br></br>{mortgage.priorEncumbrances}</small>
            </div>
          </div>
          <hr className='dark:border-midnight'></hr>
          <FontAwesomeIcon className={`text-xs text-transparent-dark-grey dark:text-bone`} icon={expand ? faChevronUp : faChevronDown} />
        </div>
      </div>
  )
}
