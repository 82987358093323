import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import AppContext from 'contexts/AppContext';
import AdminSideNav from './AdminSideNav';
import TooltipBasic from 'components/common/TooltipBasic';


const SideNavLink = ({to, children, selected, small, message, subNav}) => {
  return (
    small ?
      <TooltipBasic message={message} className="relative" right>
        <Link className={`no-underline 2xl:text-base 2xl:leading-4 py-2 px-4 items-center gap-1 content-center flex hover:bg-light-grey dark:hover:bg-midnight rounded-r-sm ${selected ? 'bg-yellow dark:bg-mocha font-bold' : ''}`} to={to}>{children}</Link>
      </TooltipBasic>
    :  
    <Link className={`${subNav ? 'pl-10 2xl:pl-14 bg-light-grey hidden md:flex dark:bg-midnight' : '2xl:text-base'}  no-underline py-2 px-4 2xl:px-8 items-center gap-1 content-center flex hover:bg-light-grey dark:hover:bg-midnight rounded-r-sm ${selected ? 'bg-yellow dark:bg-mocha font-bold' : ''}`} to={to}>{children}</Link>
  )
}

const SideNav = () => {
  const location = useLocation();
  const { currentUser, toggleMenu } = useContext(AppContext);
  const {menuOpen} = useContext(AppContext);
  return currentUser ? (
    <div className={`self-stretch transition-all shrink-0 grow-0  xs:hidden flex flex-col justify-between`}>
      <div className="flex flex-col text-xs py-4">
        <SideNavLink small={!menuOpen} message="Home" selected={location.pathname.includes('home')} to="/home">🏠<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>Home</span></SideNavLink>
        <SideNavLink small={!menuOpen} message="Files" selected={location.pathname == '/files'} to="/files">📂<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>Files</span></SideNavLink>
        {
          location.pathname == '/files' && menuOpen &&
          <>
            <SideNavLink subNav small={!menuOpen} message="Purchase" selected={location.pathname == '/files#Purchase'} to="/files#Purchase"><span className={`font-base hidden md:flex`}>Purchase</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Sale" selected={location.pathname == '/files#Sale'} to="/files#Sale"><span className={`font-base hidden md:flex`}>Sale</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" selected={location.pathname == '/files#Refinance'} to="/files#Refinance"><span className={`font-base hidden md:flex`}>Refinance</span></SideNavLink>
          </>
        }
        <SideNavLink small={!menuOpen} message="New File" selected={location.pathname.includes('files/new')} to="/files/new">📄<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>New File</span></SideNavLink>
        <SideNavLink small={!menuOpen} message="Letterheads" selected={location.pathname.includes('letterheads')} to="/letterheads">✉️<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>Letterheads</span></SideNavLink>
        <SideNavLink small={!menuOpen} message="Calendar" selected={location.pathname.includes('calendar')} to="/calendar">📅<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>Calendar</span></SideNavLink>
        <SideNavLink small={!menuOpen} message="Trust Account" selected={location.pathname.includes('trust-account')} to="/trust-account">🧮<span className={`hidden md:flex ${!menuOpen ? 'w-0 md:hidden' : ''}`}>Trust Account</span></SideNavLink>
        {
          location.pathname.includes('trust-account') && menuOpen &&
          <>
            <SideNavLink subNav small={!menuOpen} message="Purchase" to="/trust-account/cheques-cleared"><span className={`font-base hidden md:flex`}>Cheques Cleared</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Sale" to="/trust-account/outstanding-cheques"><span className={`font-base hidden md:flex`}>Outstanding Cheques</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" to="/trust-account/taf"><span className={`font-base hidden md:flex`}>TAF Report</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" to="/trust-account/list"><span className={`font-base hidden md:flex`}>Trust Account List</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" to="/trust-account/liability"><span className={`font-base hidden md:flex`}>Trust Liability</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" to="/trust-account/reconciliation"><span className={`font-base hidden md:flex`}>Final Reconciliation</span></SideNavLink>
            <SideNavLink subNav small={!menuOpen} message="Refinance" to="/trust-account/gstpst-report"><span className={`font-base hidden md:flex`}>GST PST Report</span></SideNavLink>
          </>
        }
        {
          (currentUser?.firmAdmin || ["admin", "super-admin"].includes(currentUser?.type)) && <AdminSideNav small={!menuOpen}/>
        }
      </div>
      {menuOpen && <div className="flex flex-col justify-between px-4 gap-2 pb-4 sm:hidden text-med-grey dark:text-dark-bone">
          <div className="flex py-2 gap-2 text-xxs flex-col">
            <div className="flex flex-col">
              <Link to="/faqs">FAQs</Link>
              {currentUser && currentUser.firmAdmin && <Link to="/products">File Bundles</Link>}
              <a href="mailto:support@conveyme.ca"><span className="text-xxs">Contact Us</span></a>
              <p className="text-med-grey">support@conveyme.ca</p>
            </div>
            <p className="text-xxs text-med-grey">
              <u className="font-medium pb-2">Support Hours</u><br></br>
              Mon-Fri: 8:30am - 5:30pm<br></br>
              Closed Holidays
            </p>
          </div>
          <div>
            <div className="text-xxs">
              &copy; 2022 ConveyMe Software Inc.
            </div>
          </div>
      </div>}
    </div>
  ) : <></>;
};

export default SideNav;
