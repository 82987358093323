import React, {useState, useContext} from 'react';
import Input, { Label, Radios } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faMagnifyingGlass, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import { useMutation, gql } from "@apollo/client";
import { typeToLabel } from 'helpers/general';
import CountryInput from 'components/inputs/CountryInput';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Tooltip from 'components/common/Tooltip';

const ADD_CLIENT = gql`
  mutation AddClient(
    $firstName: String!,
    $middleName: String,
    $lastName: String!,
    $title: String,
    $gender: String,
    $email: String,
    $phoneNumber: String,
    $firmId: String,
    $type: String,
    $addressLineOne: String,
    $addressLineTwo: String,
    $city: String,
    $country: String,
    $province: String,
    $postalCode: String,
    $occupation: String,
    $dateOfBirth: String,
    $canadianCitizen: Boolean,
    $sin: String,
    $stewartTitleLawyerId: String,
  ) {
    createClient(
      firstName: $firstName,
      lastName: $lastName,
      title: $title,
      gender: $gender,
      middleName: $middleName,
      email: $email,
      phoneNumber: $phoneNumber,
      firmId: $firmId,
      type: $type,
      addressLineOne: $addressLineOne,
      addressLineTwo: $addressLineTwo,
      city: $city,
      country: $country,
      province: $province,
      postalCode: $postalCode,
      occupation: $occupation,
      dateOfBirth: $dateOfBirth,
      canadianCitizen: $canadianCitizen,
      sin: $sin,
      stewartTitleLawyerId: $stewartTitleLawyerId,
    ) {
      id,
      firstName,
      lastName,
      title,
      gender,
      middleName,
      email,
      phoneNumber,
      firmId,
      addressLineOne,
      addressLineTwo,
      city,
      country,
      province,
      postalCode,
      occupation,
      dateOfBirth,
      canadianCitizen,
      sin,
      stewartTitleLawyerId,
      type,
    }
  }
`;

const EDIT_CLIENT = gql`
  mutation UpdateClient(
    $id: String,
    $firstName: String,
    $middleName: String,
    $lastName: String!,
    $title: String,
    $gender: String,
    $email: String,
    $phoneNumber: String,
    $addressLineOne: String,
    $addressLineTwo: String,
    $city: String,
    $country: String,
    $province: String,
    $postalCode: String,
    $occupation: String,
    $dateOfBirth: String,
    $canadianCitizen: Boolean,
    $sin: String,
    $type: String,
    $firmId: String,
    $stewartTitleLawyerId: String,
  ) {
    updateClient(
      id: $id,
      firstName: $firstName,
      lastName: $lastName,
      title: $title,
      gender: $gender,
      middleName: $middleName,
      email: $email,
      phoneNumber: $phoneNumber,
      addressLineOne: $addressLineOne,
      addressLineTwo: $addressLineTwo,
      city: $city,
      country: $country,
      province: $province,
      postalCode: $postalCode,
      occupation: $occupation,
      dateOfBirth: $dateOfBirth,
      canadianCitizen: $canadianCitizen,
      sin: $sin,
      type: $type,
      firmId: $firmId,
      stewartTitleLawyerId: $stewartTitleLawyerId,
    ) {
      id,
      firstName,
      lastName,
      title,
      gender,
      middleName,
      email,
      phoneNumber,
      firmId,
      addressLineOne,
      addressLineTwo,
      city,
      country,
      province,
      postalCode,
      occupation,
      dateOfBirth,
      canadianCitizen,
      sin,
      stewartTitleLawyerId,
      type
    }
  }
`;

const DELETE_CLIENT = gql`
  mutation DeleteClient($id: String!) {
    deleteClient(id: $id) {
      id
    }
  }
`;

export default function AddClient({close, onCreate, type, edit, firmId, copyAddress, copyPropertyAddress, onDeselect}) {
  const [newClient, setNewClient] = useState(edit || {type: type && type[0], country: "Canada"});
  const [showDelete, setShowDelete] = useState();
  const [addClientMutation, { loading }] = useMutation(ADD_CLIENT);
  const [editClientMutation, { loading:editLoading }] = useMutation(EDIT_CLIENT);
  const [deleteClientMutation, { loading:deleteLoading }] = useMutation(DELETE_CLIENT);
  const [sinError, setSinError] = useState();
  const valid = () => {
    return newClient && newClient.firstName && newClient.lastName;
  }
  const addClient = () => {
    if (valid()) {
      addClientMutation({variables: {
        firstName: newClient.firstName,
        lastName: newClient.lastName,
        title: newClient.title,
        gender: newClient.gender,
        middleName: newClient.middleName,
        email: newClient.email,
        phoneNumber: newClient.phoneNumber,
        firmId,
        type: newClient.type,
        addressLineOne: newClient.addressLineOne,
        addressLineTwo: newClient.addressLineTwo,
        city: newClient.city,
        province: newClient.province,
        postalCode: newClient.postalCode,
        occupation: newClient.occupation,
        dateOfBirth: newClient.dateOfBirth,
        canadianCitizen: newClient.canadianCitizen,
        sin: newClient.sin,
      }})
      .then(res => {
        onCreate(res.data.createClient, true);
      })
    }
  }
  const editClient = () => {
    if (valid()) {
      editClientMutation({variables: {...newClient, firmId}})
      .then(res => {
        edit.id = res.data.updateClient.id;
        onCreate(res.data.updateClient, true);
      })
    }
  }

  const deleteClient = () => {
    if (newClient?.id) {
      deleteClientMutation({variables: {id: newClient?.id}})
      .then(res => {
        setShowDelete(false);
        onDeselect();
      })
    }
  }

  const updateSIN = (sin) => {
    if (sin) {
      let sinTest = sin.replaceAll("-", "");
      sinTest = sinTest.split('');  
      const even = sinTest
        .filter((_, i) => i % 2)
        .map((n) => { 
          let num = n * 2;
          if (num > 9) {
            return Number(num.toString()[0]) + Number(num.toString()[1])
          }
          return num; 
        })
        .reduce((prev, curr) => prev + curr)

      const odd = sinTest
        .filter((_, i) => !(i % 2))
        .reduce((prev, curr) => Number(prev) + Number(curr))

        const check = even + odd;
        console.log("check", check, check % 10, check % 10 === 0);
      if (check % 10 === 0){
        console.log("valid");
        setSinError();
      } else {
        console.log("invalid");
        setSinError("This SIN # is invalid");
      }   
    }
    setNewClient({...newClient, sin});
  }

  const handleCopyAddress = () => {
    setNewClient({
      ...newClient, 
      addressLineOne: copyAddress.addressLineOne, 
      addressLineTwo: copyAddress.addressLineTwo,
      country: copyAddress.country,
      city: copyAddress.city,
      province: copyAddress.province,
      postalCode: copyAddress.postalCode,
    })
  }

  const handleCopyPropertyAddress = () => {
    setNewClient({
      ...newClient, 
      addressLineOne: copyPropertyAddress.newPurchaseAddress, 
      country: 'Canada',
      city: copyPropertyAddress.newPurchaseCity,
      province: copyPropertyAddress.newPurchaseProvince,
      postalCode: copyPropertyAddress.newPurchasePostalCode,
    })
  }

  return (
    <>
      {showDelete && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete this client.`}
        onConfirm={deleteClient}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="p-4 border border-transparent-dark-grey rounded flex flex-col bg-transparent-grey gap-4">
        <div className="relative">
          <h3> <FontAwesomeIcon className={newClient?.linkedUser ? 'text-green' : ''} icon={faUser}/> {edit ? "Edit" : "New"} {typeToLabel(type) || 'Person'}
            {
              newClient?.linkedUser &&
              <Tooltip
                icon={faLink}
                color="green"
                message={"This person is linked to a user"}
              />
            }
          </h3>
          <FontAwesomeIcon onClick={close} className={`absolute top-1 right-1 cursor-pointer`} icon={edit ? faTimes: faMagnifyingGlass}/>
        </div>
        {type && <Radios valueOverride={newClient.type} inline onChange={(type) => setNewClient({...newClient, type})} options={type.map(type => ({name: type, value: type}))}/> }
        <div className="flex gap-2">
          <div className="flex flex-col items-start">
            <Label text="Gender"/>
            <List  valueOverride={newClient.gender} onChange={(gender) => setNewClient({...newClient, gender})} options={[{value: 'male', name: 'male'},{value: 'female', name: 'female'},{value: 'non-binary', name: 'non-binary'}]}/>
          </div>
          <div className="flex flex-col">
              <Label text="Date of Birth"/>
              <Input type="date" valueOverride={newClient.dateOfBirth} onChange={(dateOfBirth) => setNewClient({...newClient, dateOfBirth})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
            <Label text="Title"/>
            <List  valueOverride={newClient.title} onChange={(title) => setNewClient({...newClient, title})} options={[
                {value: 'Mr.', name: 'Mr.'},
                {value: 'Mrs.', name: 'Mrs.'},
                {value: 'Ms.', name: 'Ms.'},
                {value: 'Miss', name: 'Miss'},
                {value: 'Dr.', name: 'Dr.'},
                {value: 'Mx.', name: 'Mx.'},
            ]}/>
          </div>
          <div className="flex flex-col">
              <Label text="First Name"/>
              <Input valueOverride={newClient.firstName} onChange={(firstName) => setNewClient({...newClient, firstName})} onBlur={(firstName) => setNewClient({...newClient, firstName: firstName?.trim()})} />
          </div>
          <div className="flex flex-col">
              <Label text="Middle Name"/>
              <Input valueOverride={newClient.middleName} onChange={(middleName) => setNewClient({...newClient, middleName})} onBlur={(middleName) => setNewClient({...newClient, middleName: middleName?.trim()})} />
          </div>
          <div className="flex flex-col">
              <Label text="Last Name"/>
              <Input valueOverride={newClient.lastName} onChange={(lastName) => setNewClient({...newClient, lastName})} onBlur={(lastName) => setNewClient({...newClient, lastName: lastName?.trim()})} />
          </div>
        </div>
        <div className="flex gap-2"> 
          <div className="flex flex-col">
              <Label text="Phone Number"/>
              <Input autoPattern="   -   -    " pattern="^\d{3}[\s.-]\d{3}[\s.-]\d{4}$" patternErrorMessage="Invalid Phone Number Format try ###-###-####" valueOverride={newClient.phoneNumber} onChange={(phoneNumber) => setNewClient({...newClient, phoneNumber})} />
          </div>
          <div className="flex flex-col">
              <Label text="Email"/>
              <Input valueOverride={newClient.email} onChange={(email) => setNewClient({...newClient, email})} />
          </div>
        </div>
        <div className="flex flex-col self-start">
            <Label text="Canadian Citizen or Permanent Resident?"/>
            <Radios options={[{name: "Yes", value: true}, {name: "No", value: false}]} valueOverride={newClient.canadianCitizen} onChange={(canadianCitizen) => setNewClient({...newClient, canadianCitizen})} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
              <div className="flex gap-1 items-center">
                <Label text="Address"/>
                {copyAddress && <div onClick={handleCopyAddress} className="border border-green bg-light-green self-start text-green rounded px-3 text-sm cursor-pointer">Copy previous address</div>}
                {copyPropertyAddress && <div onClick={handleCopyPropertyAddress} className="border border-green bg-light-green self-start text-green rounded px-3 text-sm cursor-pointer">Copy property address</div>}
              </div>
              <Input valueOverride={newClient.addressLineOne} onChange={(addressLineOne) => setNewClient({...newClient, addressLineOne})} />
          </div>
          <div className="flex flex-col">
              <Label text="Second line of address"/>
              <Input valueOverride={newClient.addressLineTwo} onChange={(addressLineTwo) => setNewClient({...newClient, addressLineTwo})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col self-start">
              <Label text="Country"/>
              <CountryInput valueOverride={newClient.country} onChange={(country) => setNewClient({...newClient, country})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
              <Label text="City"/>
              <Input valueOverride={newClient.city} onChange={(city) => setNewClient({...newClient, city})} />
          </div>
          <div className="flex flex-col">
              <Label text="Province"/>
              <Input valueOverride={newClient.province} onChange={(province) => setNewClient({...newClient, province})} />
          </div>
          <div className="flex flex-col">
              <Label text="Postal Code"/>
              <Input valueOverride={newClient.postalCode} onChange={(postalCode) => setNewClient({...newClient, postalCode})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
              <Label text="Occupation"/>
              <Input valueOverride={newClient.occupation} onChange={(occupation) => setNewClient({...newClient, occupation})} />
          </div>
          <div className="flex flex-col">
              <Label text="SIN"/>
              <Input autoPattern="   -   -   " pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}" error={sinError} patternErrorMessage="Invalid SIN Format try ###-###-###" valueOverride={newClient.sin} onChange={updateSIN} />
          </div>
        </div>
        {
          ['lawyer', 'notary'].includes(newClient.type) &&
          <div className="flex flex-col">
              <Label text="Stewart Title Profile ID"/>
              <Input valueOverride={newClient.stewartTitleLawyerId} onChange={(stewartTitleLawyerId) => setNewClient({...newClient, stewartTitleLawyerId})} />
          </div>
        }
        <div className="flex justify-between">
          <ButtonPrimary disabled={editLoading || loading || !valid()} onClick={edit ? editClient : addClient} className="self-start">{edit ? (newClient.id ? "Update" : "Save") : "Add"}</ButtonPrimary>
          {(newClient.id && !newClient?.linkedUser) && <ButtonPrimary disabled={deleteLoading} onClick={() => setShowDelete(true)} className="self-start text-red border-red">Delete</ButtonPrimary>}
        </div>
      </div>
    </>
  )
}
