import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const options = [
  // green
  '#B4EDD2',
  '#B2FF59',
  '#5bc58d',
  '#00C853',
  // orange
  '#FFC841',
  '#FFA500',
  '#FA7319',
  '#dda450',
  // red
  '#e66767',
  '#F33E42',
  '#FE0000',
  '#AE150B',
  //purple
  '#F8A6C1',
  '#D53A9D',
  '#E02771',
  '#800080',
  // blue
  '#80DBD9',
  '#18BEE9',
  '#0075FF',
  '#319197',
  // '#1764BC',
  '#EAEAEA',
  '#B6B6B6',
  '#464646',
  '#030303',
];

export default function ColorSelector({initialValue, onChange, small}) {
  const [value, setValue] = useState(initialValue || '');
  const [showIcons, setShowIcons] = useState();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const changeValue = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  }
  return (
    <div className="relative">
        <div className="flex flex-col items-start cursor-pointer" onClick={() => setShowIcons(!showIcons)}>
          {value ?
            <div className={`${small ? 'text-base' : ''} w-6 h-6 border flex justify-between`} style={{backgroundColor: `${value}`}}>&nbsp;<FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></div>
            :
            <div className={`bg-light-grey rounded ${small ? 'text-base' : ''} w-6 h-6 border flex justify-between`}><span className="opacity-0">❌</span><FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></div>
          }
        </div>
        {
          showIcons && 
          <div className={`p-1 text-2xl flex flex-wrap gap-3 ${small ? 'absolute w-60 border bg-white rounded z-10' : ''}`}>
            {options.map((option) => (
                  <div
                    className={`hover:scale-110 w-10 h-10 hover:p-1 p-2 cursor-pointer`}
                    style={{backgroundColor: `${option}`}}
                    key={option}
                    name={option} 
                    value={option}
                    onClick={() => {
                      setShowIcons(false);
                      changeValue(option);
                    }}
                  >
                  </div>
              ))
            }
          </div>
        }
    </div>
  )
}
