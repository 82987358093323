import React, { useContext, useMemo } from 'react';

import Tooltip from 'components/common/Tooltip';
import ParentContext from 'contexts/ParentContext';
import { pathMaker } from 'selectors/formSelectors';
// w-32
const LineItem = ({questions, label, hideLabel, tooltip, questionKey, formStyles, skipPath}) => {
  const {row, prevRows, parentPath, subscribeCleanup} = useContext(ParentContext);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  return (
    <>
      {!hideLabel && 
        <>
          <label className="line-item-label dark:text-bone"><strong>{label}</strong></label>
          {tooltip && <Tooltip message={tooltip} />}
        </>
      }
      <div className={`flex grow gap-2 line-item ${formStyles || 'items-stretch'}`}>
        <ParentContext.Provider value={{parentPath: skipPath ? '' : path, prevRows, subscribeCleanup}}>
          {questions}
        </ParentContext.Provider>
      </div>
    </>
  );
};

export default LineItem;