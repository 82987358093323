import React, { useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import FAQuestion from './FAQuestion';
import addingLetterhead from 'assets/pdf/adding-letterhead.pdf';
import addingTeam from 'assets/pdf/adding-team.pdf';
import savingTemplate from 'assets/pdf/saving-account-template.pdf';
import adjustContract from 'assets/pdf/adjusting-assigned-contract.pdf';
import calendarColors from 'assets/pdf/calendar-colors.pdf';
import checkSSOA from 'assets/pdf/check-ssoa-calculations.pdf';
import multipleLots from 'assets/pdf/purchase-multiple-lots.pdf';
import trustAccounting from 'assets/pdf/trust-accounting.pdf';
import printCheques from 'assets/pdf/print-cheques.pdf';
import familyTransfers from 'assets/pdf/family-transfers.pdf';
import estatePurchase from 'assets/pdf/estate-purchase.pdf';
import fctTitleInsurance from 'assets/pdf/fct-title-insurance.pdf';
import howToAddABankAccount from 'assets/pdf/how-to-add-a-bank-account.pdf';
import stewartTitleInsurance from 'assets/pdf/stewart-title-insurance.pdf';


const Faqs = () => {
  return (
    <PaddedPage noBottom className="flex flex-col gap-4 pb-20">
      <h2 className='dark:text-bone'>Frequently Asked Questions</h2>
      <FAQuestion 
        question="How to add a letterhead"
        startOpen={false}
      >
       <embed src={addingLetterhead} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How do I add team members?"
        startOpen={false}
      >
       <embed src={addingTeam} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How do I save an account template?"
        startOpen={false}
      >
       <embed src={savingTemplate} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="Get started with your first purchase file!"
        startOpen={false}
      >
        <iframe id="kaltura_player" src="https://api.ca.kaltura.com/p/133/sp/13300/embedIframeJs/uiconf_id/23449421/partner_id/133?iframeembed=true&playerId=kaltura_player&entry_id=0_md7u1gkc&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_mme3h6jj" width="608" height="402" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="ConveyMe - First Purchase File!"></iframe>      </FAQuestion>
      <FAQuestion 
        question="How do I start a sale file?"
        startOpen={false}
      >
        <iframe id="kaltura_player" src="https://api.ca.kaltura.com/p/133/sp/13300/embedIframeJs/uiconf_id/23449421/partner_id/133?iframeembed=true&playerId=kaltura_player&entry_id=0_ybj39p6c&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_lm5ijale" width="608" height="402" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="ConveyMe - Completing a Sale File"></iframe>      
      </FAQuestion>
      <FAQuestion 
        question="How to add fees, disbursements and other charges to your purchase file"
        startOpen={false}
      >
        <iframe id="kaltura_player" src="https://api.ca.kaltura.com/p/133/sp/13300/embedIframeJs/uiconf_id/23449421/partner_id/133?iframeembed=true&playerId=kaltura_player&entry_id=0_rgd79d7k&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_80ux8rbb" width="608" height="402" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="ConveyMe - Adding Fees, Disbursements and other Charges"></iframe>
      </FAQuestion>
      <FAQuestion 
        question="How to colour code your calendar by conveyancer"
        startOpen={false}
      >
       <embed src={calendarColors} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to adjust SOA's if the contract has been assigned"
        startOpen={false}
      >
       <embed src={adjustContract} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to Handle a purchase with multiple lots"
        startOpen={false}
      >
       <embed src={multipleLots} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to check SSOA calculations in a sale file"
        startOpen={false}
      >
       <embed src={checkSSOA} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="Get Started with Trust Accounting!"
        startOpen={false}
      >
       <embed src={trustAccounting} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to Print Cheques"
        startOpen={false}
      >
       <embed src={printCheques} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="Get Started with Family Transfers"
        startOpen={false}
      >
       <embed src={familyTransfers} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to handle an Estate Purchase"
        startOpen={false}
      >
       <embed src={estatePurchase} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to save document groups for quick download!"
        startOpen={false}
      >
        <iframe id="kaltura_player" src="https://api.ca.kaltura.com/p/133/sp/13300/embedIframeJs/uiconf_id/23449421/partner_id/133?iframeembed=true&playerId=kaltura_player&entry_id=0_ay5k6i4g&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_s3kqqso7" width="608" height="402" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="ConveyMe - Save Document Groups for Quick Download!"></iframe>
      </FAQuestion>
      <FAQuestion 
        question="Get Started with FCT Title Insurance"
        startOpen={false}
      >
       <embed src={fctTitleInsurance} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="Get Started with Stewart Title Insurance"
        startOpen={false}
      >
       <embed src={stewartTitleInsurance} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="Get Started with Trust Accounting"
        startOpen={false}
      >
        <iframe id="kaltura_player" src="https://api.ca.kaltura.com/p/133/sp/13300/embedIframeJs/uiconf_id/23449421/partner_id/133?iframeembed=true&playerId=kaltura_player&entry_id=0_lm4s20jo&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_tv2qno3w" width="608" height="402" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="Get Started with Trust Accounting"></iframe>
      </FAQuestion>
      <FAQuestion 
        question="How to add a bank account to print cheques"
        startOpen={false}
      >
       <embed src={howToAddABankAccount} className="w-full" width="500" height="375"></embed>
      </FAQuestion>
      <FAQuestion 
        question="How to Consolidate Debt"
        startOpen={false}
      >
        <iframe src="https://player.vimeo.com/video/978588899?h=a6564eef57" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </FAQuestion>
    </PaddedPage>
  );
};

export default Faqs;
