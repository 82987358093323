import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from "@apollo/client";
import AdminDocument from 'components/documents/AdminDocument';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import { FullWidthPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';
import Search from 'components/common/Search';

const GET_DOCUMENTS = gql`
  query GetDocuments($pageId: String, $filters: JSONObject) {
    documents(pageId: $pageId, filters: $filters) {
      id,
      name,
      category,
      firm {
        name
      },
      surveys {
        name
      },
      type
    }
  }
`;

export default function Documents() {
  const {setActivePage} = useContext(ActivePageContext);
  const [pageId, setPageId] = useState();
  const [filters, setFilters] = useState({strictSearch: true});
  const [documents, setDocuments] = useState([]);
  const { loading, error, data } = useQuery(GET_DOCUMENTS, 
    {
      fetchPolicy:'no-cache',
      variables: {pageId, filters},
      onCompleted (data) {
        setDocuments((documents) => ([...documents, ...data.documents]));
      }
    }
  );
  setActivePage('document');
  
  useEffect(() => {
    setDocuments([]);
    setPageId();
  },[filters]);

  let sortedDocuments = [...documents].sort((a,b) => {
    let compare = JSON.stringify(a.surveys).localeCompare(JSON.stringify(b.surveys));
    if (!compare) {
      compare = (a.category || "").localeCompare(b.category || "");
    }
    if (!compare) {
      compare = a.name.localeCompare(b.name)
    }
    return compare;
  });

  const loadMore = () => {
    if (!loading) {
      setPageId(documents.at(-1).name);
    }
  }

  return (
    <FullWidthPage botttom className="pt-4 flex flex-col gap-2 pb-8">
      <div className="flex gap-2 items-center">
        <h2 className="dark:text-bone">Documents <Link to="new"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link></h2>
        <Search filters={filters} loading={loading} onClick={setFilters}/>
      </div>
      <div className="flex flex-col gap-1">
        {sortedDocuments.map((document, i) => <Link className="no-underline" key={document.id + i} to={`/admin/documents/${document.id}`}><AdminDocument document={document}/></Link>)}
        {loading &&
          <Loading/>
        }
        {
          data?.documents?.length > 19 &&
          <div className="text-med-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {sortedDocuments.length === 0 && !loading && <small className="text-med-grey">No Documents</small> }
      </div>
    </FullWidthPage>
  )
}
