import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFile, faEnvelope, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

export default function AdminDocument({document}) {
  return (
    <div className={`rounded cursor-pointer flex bg-white border-l-4 ${document.type === "ltsa" ? "border-green" : document.type === "mortgage" ? "border-orange" :"border-light-grey dark:border-midnight"} shadow hover:shadow-md dark:bg-shadow `}>
      <div className={`${document.type === "ltsa" ? "bg-green" : document.type === "mortgage" ? "bg-orange dark:text-shadow" :"bg-light-grey dark:bg-midnight"} flex items-center justify-center pr-1 group-hover:bg-grey`}><span>
        {
            document.type === "ltsa" ?
            <img src={ltsaLogo} className="h-6" alt="ltsa logo"/>
            : ["mortgage", "pdf"].includes(document.type) ?
            <FontAwesomeIcon className="px-1" icon={faFilePdf} />
            :
            ["cheque"].includes(document.type) ?
            <FontAwesomeIcon className="px-1" icon={faMoneyCheck} />
            :
            '📑'
          }  
      </span></div>
      <div className={`flex p-2 items-center gap-2 justify-between grow w-full`}>  
        <div className="flex items-center gap-2">
          <strong>{document.name}</strong>
        </div>
        <div className="flex items-center gap-2">
          {document.firm && 
            <div className={`rounded px-2 bg-green text-sm text-white font-bold dark:text-shadow`}>
              {document.firm.name}
            </div>
          }
          {document.category && 
            <div className={`rounded px-2 bg-med-grey text-sm text-white font-bold dark:text-shadow`}>
              {document.category}
            </div>
          }
          {document.surveys.map((survey, i) => (
            <div key={i} className={`rounded px-2 bg-primary text-sm text-white font-bold dark:text-shadow`}>
              {survey.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
