import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SurveyContext from 'contexts/SurveyContext';
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faCircleCheck, faFileLines, faFolderOpen, faPaste } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import SurveyPageChapters from './SurveyPageChapters';
import LoadingText from 'components/loading/LoadingText';
import LoadingPage from 'components/loading/LoadingPage';

export default function SurveyPage({first, last}) {
  const { pageComponents, fileId, surveyId, currentPage, page, pages, errors, setSurveyNav } = useContext(SurveyContext);
  useEffect(() => {setSurveyNav('survey')}, [setSurveyNav]);

  return (
    <div className="flex grow items-start">
      <SurveyPageChapters first={first} last={last} />
      <PaddedPage className="flex fustify-between relative overflow-x-auto">
        <div className="flex flex-col grow gap-5 items-start overflow-x-auto w-full">
          <h1 className="dark:text-bone">{page ? page.title : <LoadingText/>}</h1>
          {pageComponents || <LoadingPage/>}
          <div className="self-end">
            <Link className="" to={last ? `/files/${surveyId}/${fileId}/summary` : `/files/${surveyId}/${fileId}/${currentPage + 1}`}>
              <div className="rounded-full text-transparent-darkest-grey dark:text-bone bg-transparent-grey hover:bg-light-grey flex items-center justify-center cursor-pointer w-8 h-8">
                <FontAwesomeIcon icon={faArrowRight}/>
              </div>
            </Link>
          </div>
        </div>
      </PaddedPage>
    </div>
  )
}
