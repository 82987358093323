import React, { useContext } from 'react'
import { useQuery, gql } from "@apollo/client";
import Firm from 'components/firms/Firm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import { FullWidthPage } from 'components/common/Layout';


const GET_FIRMS = gql`
  query GetFirms {
    firms {
      id,
      name,
      email,
      subdomain
    }
  }
`;

export default function Firms() {
  const { loading, error, data } = useQuery(GET_FIRMS, {fetchPolicy:'no-cache'});
  const {setActivePage} = useContext(ActivePageContext);
  setActivePage('firms');

  return (
    <FullWidthPage bottom className="pt-4 flex flex-col gap-2"> 
      <h2 className="dark:text-bone">Firms <Link to="new"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link></h2>
      <div className="flex flex-col gap-1">
        {data && data.firms.map(firm => <Link className="no-underline" key={firm.id} to={`/admin/firms/${firm.id}`} ><Firm firm={firm}/></Link>)}
        {data && data.firms.length === 0 && <small className="text-med-grey">No Firms</small> }
      </div>
    </FullWidthPage>
  )
}
