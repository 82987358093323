import { gql, useMutation } from '@apollo/client';
import { faCheck, faGear, faNoteSticky, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Input from 'components/inputs';
import AppContext from 'contexts/AppContext';
import SurveyContext from 'contexts/SurveyContext';
import React, { useContext } from 'react';
import { useState } from 'react';
import ChecklistPhase from './ChecklistPhase';
import TooltipBasic from 'components/common/TooltipBasic';

const UPDATE_CHECKLIST_ANSWERS = gql`
  mutation UpdateChecklistAnswers($id: String!, $answers: [String], $notes: JSONObject) {
    updateChecklistAnswers(id: $id, answers: $answers, notes: $notes) {
      id
    }
  }
`;

const UPDATE_CHECKLIST_TEMPLATE = gql`
  mutation UpdateChecklistTemplate($id: String!, $name: String, $phases: JSON) {
    updateChecklistTemplate(id: $id, name: $name phases: $phases) {
      id
    }
  }
`;

export default function ChecklistTemplate({checklist, updateAnswers, updateNotes, updateTemplate}) {
  const {currentUser} = useContext(AppContext);
  const [templateUpdate, setTemplateUpdate] = useState();
  const [updateChecklistAnswersMutation, { loading }] = useMutation(UPDATE_CHECKLIST_ANSWERS);
  const [edit, setEdit] = useState(false);
  const [updateChecklistTemplateMutation, { loading: updateTemplateLoading }] = useMutation(UPDATE_CHECKLIST_TEMPLATE);

  const { surveyId } = useContext(SurveyContext);

  const handleUpdateAnswers = (answer, i) => {
    let answers = [...checklist.answers];
    let removed = false;
    answers = checklist.answers.filter(ans => {
      if (ans === answer) {
        removed = true;
        return false;
      }
      return true;
    });
    if (!removed) {
      answers.push(answer);
    }
    updateChecklistAnswersMutation({variables: {
      answers, id: checklist.id, surveyId
    }})
    .then((res) => {
      updateAnswers(answers);
    });
  }

  const handleUpdateNotes = (note, item) => {
    let newNotes = {...checklist.notes, [item]: note};
    updateChecklistAnswersMutation({variables: {
      notes: newNotes, id: checklist.id, surveyId
    }})
    .then((res) => {
      updateNotes(newNotes);
    });
  }

  const toggleEdit = () => {
    setTemplateUpdate(checklist.template);
    setEdit(!edit);
  }

  const saveUpdates = () => {
    updateChecklistTemplateMutation({variables: {
      ...templateUpdate
    }})
    .then((res) => {
      updateTemplate(templateUpdate);
      setEdit(false);
    });
  }

  const updateName = (name) => {
    setTemplateUpdate((template) => ({...template, name}));
  }

  const updatePhase = (phase, i) => {
    setTemplateUpdate((template) => {
      let newPhases = [...template.phases];
      newPhases[i] = phase;
      return {...template, phases: [...newPhases]};
    })
  }

  const addPhase = () => {
    setTemplateUpdate((template) => ({...template, phases: [...template.phases, {blocks: []}]}))
  }

  const removePhase = (phase, i) => {
    setTemplateUpdate((template) => ({...template, phases: template.phases.filter((phase,index) => index !== i)}));
  }

  return (
    <div>
      <div className="flex gap-2">
        <h2 className='dark:text-bone'>{edit ? 
          <Input className="text-xl font-medium px-1 py-0" valueOverride={templateUpdate.name} onChange={updateName} />
          : checklist.template.name}
        </h2>
        {
          (["admin", "super-admin"].includes(currentUser.type) || currentUser.firmAdmin) && 
          <TooltipBasic message="Edit" className="relative">
            <div onClick={toggleEdit} className={` hover:text-white rounded-full w-6 h-6 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:bg-shadow dark:text-bone`}>
              <FontAwesomeIcon icon={faGear} className="text-base"/>
            </div>
          </TooltipBasic>
        }
      </div>
      <div className="relative mt-3">
        <TooltipBasic message="File Note" className="absolute -left-2 -top-2">
          <div className={` hover:text-white rounded-full w-6 h-6 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:bg-shadow dark:text-bone`}>
            <FontAwesomeIcon icon={faNoteSticky} className="text-base"/>
          </div>
        </TooltipBasic>
        <Input type="textarea" className="text-sm font-medium border-light-grey leading-5 border pt-2 pl-3 w-full" valueOverride={checklist.notes && checklist.notes.main} onBlur={(note) => handleUpdateNotes(note, "main")} />
      </div>
      <div className="flex flex-col gap-2 pt-4">
        {
          (edit ? templateUpdate : checklist.template).phases.map((phase, i) => <ChecklistPhase 
            edit={edit} 
            key={i} 
            answers={checklist.answers} 
            notes={checklist.notes}
            updateNotes={handleUpdateNotes}
            updateAnswers={handleUpdateAnswers} 
            phase={phase}
            updatePhase={(phase) => updatePhase(phase, i)}
            removePhase={() => removePhase(phase, i)}
          />)
        }
        {edit && <div className="flex justify-between">
          <ButtonPrimary className="text-xs" onClick={addPhase} ><FontAwesomeIcon icon={faPlusCircle}/> New Phase</ButtonPrimary>
          <ButtonPrimary className="text-white bg-green text-xs" onClick={saveUpdates} ><FontAwesomeIcon icon={faCheck}/> Save</ButtonPrimary>
        </div>}
      </div>
    </div>
  )
}
