import React, { useContext, useEffect, useState } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Input, Label } from 'components/inputs';
import { ButtonPrimary } from 'components/common/Button';
import { Link } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import Logo from 'assets/images/logo/logo.png';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import Message from 'components/common/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RequestFlagsContext from 'contexts/RequestFlagsContext';

const CREATE_PASSWORD_RESET = gql`
  mutation CreatePasswordReset($usernameOrEmail: String!) {
    createPasswordReset(usernameOrEmail: $usernameOrEmail) {
      message
    }
  }
`;

const ForgotPassword = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  let {setErrorHandler} = useContext(RequestFlagsContext);
  const [createPasswordResetMutation, { loading: createResetLoading }] = useMutation(CREATE_PASSWORD_RESET);

  useEffect(() => {
    setErrorHandler('CreatePasswordReset', (error) => {
      setError(error);
    });
    return () => setErrorHandler('CreatePasswordReset', undefined);
  }, []);

  const handleCreateReset = () => {
    createPasswordResetMutation({variables: {usernameOrEmail}})
    .then(res => {
      setSuccess(true);
    });
  }

  const valid = () => {
    return usernameOrEmail ? true : false;
  }

  return (
    <div className="top-0 left-0 absolute w-screen h-screen h-screen bg-gradient-to-r from-light-light-yellow dark:from-dark-coffee to-light-yellow dark:to-midnight flex flex-col z-60 justify-center items-center">
      <div className="flex flex-col justify-between p-6 gap-4 w-80 h-110 rounded-xl bg-white border dark:bg-midnight dark:border-black">
        <div className="flex flex-col gap-4">
          <div className="bg-contain bg-no-repeat grow-0 shirnk-0 w-20 h-12 self-center" style={{backgroundImage: `url(${Logo})`}}/>
          {
            success ?
            <>
              <h2 className="font-bold dark:text-bone">Success!</h2>
              <p className="text-sm leading-4 dark:text-bone">
                An email has been sent for you to reset your password!
                <br/><br/>
                Return to <Link className="no-underline text-primary" to="/login">Login</Link>
              </p>
            </>
            :
            <>
              <h2 className="font-bold dark:text-bone">Forgot your Password?</h2>
              <p className="text-sm leading-4 dark:text-bone">
                Give us your email or username and we will send you instructions by email.
                <br/><br/>
                Return to <Link className="no-underline text-primary" to="/login">Login</Link>
              </p>
              {
                error &&
                <Message negative>
                  <FontAwesomeIcon icon={faCircleExclamation}/>
                  &nbsp;
                  {error.message}
                </Message>
              }
              <div className="flex flex-col text-left">
                <Label text="Username or Email" />
                <Input className="dark:bg-shadow" onChange={setUsernameOrEmail} />
              </div>
            </>
          }
        </div>
        {
          !success && <ButtonPrimary className="self-end" disabled={createResetLoading || !valid()} onClick={handleCreateReset}>Send Password Reset</ButtonPrimary>
        }
      </div>
      <div className="flex p-4 gap-4">
        <Link className="no-underline text-sm" to={'/terms-and-conditions'}><span className="">Terms</span></Link>
        <Link className="no-underline text-sm" to={'/privacy'}><span className="">Privacy</span></Link>
        <Link className="no-underline text-sm" to="/faqs">Help</Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
