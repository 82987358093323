import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import desktop from 'assets/images/about/desktop.png';
import mobile from 'assets/images/about/mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import RoundedButton from 'components/common/Button/RoundedButton';


export default function Preview() {
  return (
    <div className="bg-cover bg-no-repeat py-12 pb-16 xs:pt-10 xs:pb-10 flex flex-col min-h-80screen" >
       <PaddedPage className="flex gap-4 margin-auto 2xl:mx-auto 2xl:max-w-8xl self-center items-center" >
          <div className="w-1/2 2xl:w-1/3 flex flex-col items-start gap-8 sm:w-full xs:gap-4">
              <h1 className="jumbo xs:text-4.5xl xs:leading-9">
                The Easiest Conveyancing Platform
              </h1>
              <h2 className="">
              ConveyMe is re-imagining how you handle purchases, sales, mortgages, 
              family transfers and commercial files.
              </h2>
              <a className="pt-4" href="https://share.hsforms.com/1LXgpw0SVT7moUBdasqNNogdxxpj">
                <RoundedButton className="border border-dark-grey" white>Book A Demo</RoundedButton>
              </a>
              {/* <a href="#whoweare">
                <ButtonPrimary className="mt-2 p-12 px-10 font-bold border-white bg-white" >Learn more</ButtonPrimary>
              </a> */}
          </div>
          <div className="relative w-1/2 2xl:w-2/3 sm:hidden flex justify-center items-center">
              <div className="w-4/5">
                <div style={{backgroundImage: `url(${desktop})`}} />
                <div style={{backgroundImage: `url(${mobile})`}} />
                <img src={desktop} className="rounded-lg border-3 shadow-xl" alt="ConveyMe Desktop"/>
              </div>
              <div className="absolute top-20 right-6 flex justify-end">
                <img src={mobile} className="relative right-0 w-2/7 xl:w-3/8 2xl:w-1/2 rounded-xl shadow-xl border-6" alt="ConveyMe Mobile"/>
              </div>
          </div>
        </PaddedPage>
        <div className="w-full flex items-center justify-center xs:pt-4">
          <FontAwesomeIcon className="animate-bounce" icon={faChevronDown}/>
        </div>
    </div>
  )
}
