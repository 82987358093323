import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Dropdown from 'components/common/Dropdown';
import AppContext from 'contexts/AppContext';
import { faBug, faMask } from '@fortawesome/free-solid-svg-icons';
import BugReport from 'components/modal/BugReport';

export default function UserNav({className}) {
  const {logout, currentUser, impersonating, stopImpersonating} = useContext(AppContext);
  const [showBugReport, setShowBugReport] = useState();
  return (
    <div className={`flex gap-4 text-sm ${className}`}>
      {/* <FontAwesomeIcon className="cursor-pointer hidden md:inline" icon={faBug} onClick={() => setShowBugReport(true)}/> */}
      <Dropdown options={[
            {
              to: "/profile",
              text: "profile"
            },
            {
              action: impersonating ? stopImpersonating : logout,
              text: impersonating ? "stop impersonating" : "logout"
            },
          ]}
          trigger={<FontAwesomeIcon icon={impersonating ? faMask: faUser}/> }
      />
      {showBugReport &&
        <BugReport onClose={() => setShowBugReport(false)} onConfirm={() => setShowBugReport(false)} />
      }
   </div>
  )
}
