import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import Loading from 'components/common/Loading';

const ButtonRoot = ({ children, onClick, to, className, icon, small, selected, disabled, full, title, loading }) => {
  const iconClass = () => {
    return small ? 'text-xl' : 'text-2xl';
  };
  
  const flexClass = () => {
    const base = 'flex justify-center items-center';
    return small ? `${base} gap-2` : `${base} gap-2`;
  };

  const sizingClass = () => {
    if (small) {
      return (!icon || icon === 'none') ? 'text-sm leading-4 px-3 py-2' : 'text-sm leading-4 px-3 py-2';
    } else {
      return (!icon || icon === 'none') ? 'text-md leading-none px-3 py-2' : 'text-md leading-none px-3 py-2';
    }
  };

  const renderChildren = () => {
    if (loading) {
      return (
        <div 
          title={title || ((typeof children === 'string' || children instanceof String) ? children : undefined)}
          className="before:content-[attr(title)] before:opacity-0 before:h-0 before:block"
        >
          <Loading/>
        </div>
      )
    } else if (icon == 'left') {
      return (
        <>
          <FontAwesomeIcon className={iconClass()} icon={faArrowAltCircleLeft} />
          {children}
        </>
      );
    } else if (icon == 'right') {
      return (
        <>
          {children}
          <FontAwesomeIcon className={iconClass()} icon={faArrowAltCircleRight} />
        </>
      );
    } else {
      return (
        <>{children}</>
      );
    }
  };

  return (
    to ?
      <Link 
        className={`
          ${sizingClass()} 
          ${selected && 'bg-primary text-white'} 
          ${disabled ? 'cursor-default text-light-grey' : 'cursor-pointer hover:text-dark-grey'}
          ${full && 'w-full'}
          ${className}
          `
        }
        onClick={onClick}
        to={to}
        title={title || ((typeof children === 'string' || children instanceof String) ? children : undefined)}
      >
        <div 
          className={flexClass()}
        >
          {renderChildren()}
        </div>
      </Link>
      :
      <button
        title={title || ((typeof children === 'string' || children instanceof String) ? children : undefined)}
        className={` 
          ${sizingClass()} 
          ${selected && 'bg-primary text-white'}
          ${disabled ? 'cursor-default text-light-grey' : 'cursor-pointer hover:text-dark-grey'}
          ${full && 'w-full'}
          ${className}
        `}
        onClick={onClick}
        disabled={disabled}
      >
        <div 
          className={flexClass()}
        >
          {renderChildren()}
        </div>
      </button>
  );
};

export default ButtonRoot;
