import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// get trust account items for this file
const GET_TAF_ITEMS = gql`
  query GetTAFItems($filters: JSONObject) {
    tafFees(filters: $filters) {
      name,
      value,
      date,
      surveyId, 
      fileId,
      file {
        fileNumber,
        completionDate,
        surveyName
      }
    }
  }
`;

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month.length > 1 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function TAFReport({filters}) {
  const {setActivePage, from, to, firmId, setExportCSV} = useContext(ActivePageContext);
  setActivePage("taf")
  const {exportToCsv} = useExportCSV();
  const { loading, error, data, refetch } = useQuery(GET_TAF_ITEMS, {
    variables: {firmId, filters: {from, to}},
    fetchPolicy:'no-cache'
  });
  const [startingBalance, setStartingBalance] = useState(0);

  const filteredItems = useMemo(() => {
    let filteredData = data?.tafFees?.filter(item => {
      let filter = false;
      switch(filters?.cleared) {
        case true:
          filter = filter || !item.cleared;
          break;
        case false:
          filter = filter || item.cleared;
          break;
        default:
          break;
      }
      filter = filter || (filters?.inOut ? (filters.inOut === "in" ? item.value < 0 : item.value > 0) : filter);
      filter = filter || (filters?.type ? item.type !== filters?.type : filter); 
      return !filter;
    });

    return filteredData;
  }, [data, filters]);

  const totals = useMemo(() => {
    return filteredItems?.reduce((prev, current) => {
      if (current.value > 0) {
        prev.in += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0))
      } else {
        prev.in += Number(current.value) * -1;
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0))
      }
      return prev;
    }, {in: 0, out: 0, balances: [Number(startingBalance)]}) || {in: 0, out: 0, balances: []};
  }, [data, startingBalance])

  useEffect(() => {
    if (!setExportCSV) return;
    if (filteredItems?.length > 0) {
      setExportCSV({
        call: () => exportToCsv(filteredItems?.map(({name, file, value}) => ({fileMatter: file?.fileNumber, name, type: file?.surveyName, completion: file?.completionDate, amount: formatCurrency(value), tax: formatCurrency(value * 0.05) })), `Taf Report ${from} - ${to}`)
      })
    } else {
      setExportCSV();
    }
  }, [data]);

  return (
    <div className="bg-light-grey rounded p-2 dark:bg-transparent">
      <table className="w-full bg-light-grey border-spacing-2 dark:bg-transparent">
        <tbody>
          <tr className="border-b border-dark-grey dark:bg-shadow">
            <th>No</th>
            <th>File Matter</th>
            <th>Name</th>
            <th>Exempt</th>
            <th>File Type</th>
            <th>Completion</th>
            <th>Amount</th>
            <th>Tax</th>
          </tr>
          {filteredItems?.map((item, i) => (
            <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
              <td className="p-1 text-center">{i+1}</td>
              <td className="p-1 "><Link to={`/files/${item.surveyId}/${item.fileId}/trust-account`}>{item.file?.fileNumber}</Link></td>
              <td className="p-1 ">{item.name}</td>
              <td className="p-1 text-center">False (default)</td>
              <td className="p-1 text-center">{item.file?.surveyName}</td>
              <td className="p-1 text-center">{item.file?.completionDate}</td>
              <td className="p-1 text-right">{formatCurrency(item.value)}</td>
              <td className="p-1 text-right">{formatCurrency(item.value * 0.05)}</td>
            </tr>
          ))}
          <tr className="border-t border-med-grey">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-right pt-2">{formatCurrency(totals.in)}</td>
            <td className="text-right pt-2">{formatCurrency(totals.in * 0.05)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
