import React, {useContext, useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import stewartTitleLogo from "assets/images/survey/stewartTitleLogo.png";
import ParentContext from 'contexts/ParentContext';
import Transparent from 'components/notices/Transparent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalculator, faGears, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import useSurveyErrors from 'hooks/useSurveyErrors';
import { validFCTInputs, validLTSAInputs } from 'helpers/document';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { softSubset } from 'helpers/general';
import { faHandPointUp, faHandPointer } from '@fortawesome/free-regular-svg-icons';
import StewartTitleResources from './StewartTitleResources';

const GET_STEWART_TITLE_INSURANCE = gql`
  mutation GetStewartTitleInsurance($fileId: String!, $surveyId: String!) {
    getStewartTitleInsurance(fileId: $fileId,  surveyId: $surveyId) {
      status,
      policyNumber,
      fileUrl,
      isValid,
      messages {
        Message
      }
    }
  }
`;

const GET_STEWART_TITLE_STATUS = gql`
  mutation GetStewartTitleInsuranceStatus($fileId: String!, $surveyId: String!) {
    getStewartTitleInsuranceStatus(fileId: $fileId, surveyId: $surveyId) {
      status,
      policyNumber,
      fileUrl,
      isValid,
      messages {
        Message
      }
    }
  }
`;

const CREATE_RESOURCE_LINK = gql`
  mutation CreateStewartTitleResourceLink($type: String!) {
    createStewartTitleResourceLink(type: $type) {
      redirectUrl,
    }
  }
`;

let statusLookup = {
  undefined: null,
  'Order not Submitted': true,
  'Unordered': true, 
  'Flagged': true, 
  'Ordered': true,  
  'Order Resubmission Required': true,  
  'Cancelled': false, 
  'Declined': true,
  'Closed': false,
}

let requiredFields = (type) => {
  if (type === "Refinance") {
    return ["answers.allBorrowers", "answers.allTransferors", "answers.pid", "answers.closingDate", "answers.addressDetails.newPurchaseAddress", "answers.addressDetails.newPurchaseCity", 
    "answers.propertyType == 'Common Elements Condo' ? answers.addressDetails.unitNumber : true",
    "answers.titleInsuranceMortgageAmount && answers.lender.companyName", 
    "['Multi Family Units'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true",
    "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0",
    "answers.titleInsuranceMortgageAmount",
    "answers.supervisingLawyer?.stewartTitleLawyerId || answers.stewartTitleLawyerId",
    "!['Mobile Home', 'Rooming House', 'Live Work Units'].includes(answers.propertyType)",
    "!['Easement', 'Other'].includes(answers.estateType)",
    "answers.NumberOfUnits ? (answers.NumberOfUnits <= 6 && answers.NumberOfUnits >=2) : true",
    "file.requests?.getTitle?.[0].response?.order?.orderedProduct?.fieldedData.descriptionsOfLand[0].fullLegalDescription || answers.titleSearch?.title?.legalDescription"
    ];
  }
  return ["answers.buyerGroups", "answers.sellers", "answers.pid", "answers.purchasePriceTotal", "answers.closingDate", "answers.addressDetails.newPurchaseAddress", "answers.addressDetails.newPurchaseCity", 
  "answers.hasMortgage ? (answers.titleInsuranceMortgageAmount && answers.lender.companyName) : true", 
  "answers.propertyType == 'Common Elements Condo' ? answers.addressDetails.unitNumber : true",
  "['Multi Family Units'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true",
  "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0",
  "answers.hasMortgage ? answers.titleInsuranceMortgageAmount : true",
  "answers.supervisingLawyer?.stewartTitleLawyerId || answers.stewartTitleLawyerId",
  "!['Mobile Home', 'Rooming House', 'Live Work Units'].includes(answers.propertyType)",
  "answers.NumberOfUnits ? (answers.NumberOfUnits <= 6 && answers.NumberOfUnits >=2) : true",
  "answers.sellersSupervisingLawyer",
  "file.requests?.getTitle?.[0].response?.order?.orderedProduct?.fieldedData.descriptionsOfLand[0].fullLegalDescription || answers.titleSearch?.title?.legalDescription"
  ];
} 
// sellelr birthdate
// if other rep lender then otherparties soli and supervising
const StewartTitleInsurcneInput = ({ 
  questionKey, 
  tokenIdPath
}) => {
    const { file, pages, fileId, surveyId, updateAnswers } = useContext(SurveyContext);
    const [getStewartTitleInsurance, { loading, error }] = useMutation(GET_STEWART_TITLE_INSURANCE);
    const [getStewartTitleStatus, { loading: createLoading, error: createError }] = useMutation(GET_STEWART_TITLE_STATUS);
    const [createResourceLink] = useMutation(CREATE_RESOURCE_LINK);
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const {errorMessages} = useSurveyErrors(pages, file);
    const [iframeURL, setIframeUrl] = useState();
    const [updateNotice, setUpdateNotice] = useState();
    const [updateFlag, setUpdateFlag] = useState();
    const iframeRef = useRef();
    const [showWarining, setShowWarning] = useState();

    const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    
    const surveyValue = useMemo(() => {
      let value = file && getSurveyValue(file.answers, path);
      return value;
    }, [file, path]);

    const tokenValue = useMemo(() => {
      let value = file && getSurveyValue(file.answers, tokenIdPath);
      return value;
    }, [file, tokenIdPath]);

    const dataDependencies = useMemo(() => {
      if (file) {
        let deps = validFCTInputs(file, requiredFields(file.survey?.name));
        if (surveyValue?.status && surveyValue?.data && statusLookup[surveyValue?.status]) {
          if (!softSubset(deps.data, surveyValue.data, null, null, ['firmId'])) {
            setUpdateFlag(true);
          }
        }
        return deps;
      }
    }, [file, surveyValue]);

    const errors = useMemo(() => {
      if (dataDependencies) {
        let customErrorMessages = errorMessages ? {
          ...errorMessages, 
          "answers.hasMortgage ? (answers.titleInsuranceMortgageAmount && answers.lender.companyName) : true": 'Missing mortgage information',
          "answers.titleInsuranceMortgageAmount && answers.lender.companyName": 'Missing mortgage information',
          "answers.addressDetails.newPurchaseAddress": "Missing address on property page",
          "answers.sellersSupervisingLawyer": "Missing Seller's Lawyer on Other Parties page",
          "answers.hasMortgage ? answers.titleInsuranceMortgageAmount : true": "Mortgage Amount on Title Insurance page",
          "file.requests?.getTitle?.[0].response?.order?.orderedProduct?.fieldedData.descriptionsOfLand[0].fullLegalDescription || answers.titleSearch?.title?.legalDescription": "Property Legal Description required",
          "answers.propertyType == 'Common Elements Condo' ? answers.addressDetails.unitNumber : true": "Address Unit number must be entered when property is a Common Elements Condo",
          "['Multi Family Units'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true" : "Number of units is not within valid range (2 - 6)",
          "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0": "Owner address info must be empty or include province and street address.",
          "!['Mobile Home', 'Rooming House', 'Live Work Units'].includes(answers.propertyType)" : (<div>
            This property type must be discussed with Stewart Title.
            <br/>
            Separate underwriting requirements may apply.
            <br/>
            Please contact an Underwriter at 416-307-3300 or toll free at 1-888-667-5151.
          </div>),
          "!['Easement', 'Other'].includes(answers.estateType)" : "Unsupported Estate type, please contact Stewart Title",
          "answers.NumberOfUnits ? (answers.NumberOfUnits <= 6 && answers.NumberOfUnits >=2) : true" : "If the property has more than 6 units, it is no longer considered a residential policy but rather a commercial policy. Please Contact Stewart Title at 416-307-3300 or toll free at 1-888-667-5151.",
          "answers.supervisingLawyer?.stewartTitleLawyerId || answers.stewartTitleLawyerId": (<div>
            Unsure where to find your profile ID?  If you are an existing Stewart Title client, contact Stewart Support at (866) 969-9101 or (416) 307-3300 or your <a href="https://www.stewart.ca/contact-us/business-development-team" target="_blank">Business Development Manager</a>.
            <br/>
            Not a Stewart Title Client?  <a href="https://www.stewart.ca/contact-us/becomeaclient" target="_blank">Click here</a> to sign up.
          </div>),
        } : undefined;
        return dataDependencies?.errors?.length > 0 ? (customErrorMessages ? dataDependencies?.errors?.map(error => customErrorMessages[error] || error) : dataDependencies?.errors) : false;
      }
    }, [dataDependencies]);

    let updateStatus = useCallback(() => {
      getStewartTitleStatus({
        variables: {fileId, surveyId}
      })
      .then(res => {
        if (res.data.getStewartTitleInsuranceStatus?.status == 'Expired Token') {
          updateAnswers(tokenIdPath, 'Expired');
        } else {
          updateAnswers(path, {...res.data.getStewartTitleInsuranceStatus, data: surveyValue?.data});
        }
      });
    }, [getStewartTitleStatus, surveyValue])

    useEffect(() => {
      if (surveyValue?.status) {
        updateStatus();
      }
    }, []);

    const submit = () => {
      setUpdateNotice(false);
      getStewartTitleInsurance({
        variables: {fileId, surveyId}
      })
      .then(res => {
        if (res.data.getStewartTitleInsurance.status == 'Expired Token') {
          updateAnswers(tokenIdPath, 'Expired');
        } else {
          updateAnswers(path, {...res.data.getStewartTitleInsurance, data: dataDependencies?.data});
          setUpdateFlag(false);
          setIframeUrl(res.data.getStewartTitleInsurance?.fileUrl);
        }
      });
    }

    const openFile = () => {
      setIframeUrl(surveyValue?.fileUrl);
    }

    const onClose = () => {
      setIframeUrl("http://google.com/");
    }

    useEffect(() => {
      if (iframeURL == 'http://google.com/') {
        setIframeUrl(null);
        updateStatus();
      }
    }, [iframeURL])

    const resourceRedirect = (type) => {
      createResourceLink({
        variables: {type}
      })
      .then(res => {
        window.open(res.data.createStewartTitleResourceLink.redirectUrl, '_blank');
      })
    }

    return (
      <>
        {updateNotice && <ConfirmNotice
          title={`Are you sure?`}
          message={`This will replace all title insurance info with info from the ConveyMe Survey.`}
          onConfirm={() => submit()}
          onClose={() => setUpdateNotice(false)}
        /> }
        {
          iframeURL &&
          <Transparent noScroll>
            <div onClick={onClose} className="absolute flex justify-center items-center w-full h-full overscroll-contain">
              <div  onClick={(e) => e.stopPropagation()} className="bg-white rounded border shadow-md relative">
                <FontAwesomeIcon onClick={onClose} className="cursor-pointer absolute -top-3 -right-2" icon={faTimes} />
                <iframe src={iframeURL} ref={iframeRef} className="h-3/4" width="1250" title="Stewart Title Iframe"></iframe>
              </div>
            </div>
          </Transparent>
        } 
        <div className="rounded border flex flex-col bg-transparent-grey border-grey dark:border-transparent-grey dark:bg-midnight">
          <div className="flex flex-col gap-2 p-4">
            <div className="flex items-center gap-8">
              <img className="h-8 self-start" src={stewartTitleLogo} alt="Stewart Title logo"/>
              <div className="font-bold flex flex-col">
                Status: {surveyValue?.status  || 'No Title Insurance'}
                {
                  surveyValue?.policyNumber && (surveyValue?.policyNumber?.length > 6) &&
                  <p className="text-xxs">
                    Policy Number: {surveyValue?.policyNumber}
                  </p>
                }
              </div>
            </div>
            <div className="flex justify-end">
              {(!surveyValue?.status || statusLookup[surveyValue?.status]) && <ButtonPrimary className="self-end" disabled={(!tokenValue) || (tokenValue == 'Expired') || errors || loading} onClick={() => submit()}>{surveyValue?.status ? 'See Title Insurance' : 'Get Title Insurance'}</ButtonPrimary>}
              {surveyValue?.fileUrl && surveyValue?.status && !statusLookup[surveyValue?.status] && <ButtonPrimary className="self-end" disabled={(!tokenValue) || (tokenValue == 'Expired') || errors || loading || !surveyValue.isValid} onClick={() => openFile()}>{'See Title Insurance'}</ButtonPrimary>}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {
              (!tokenValue || (tokenValue == 'Expired')) &&
              <div className="rounded border-red border bg-light-light-red text-red p-4">
                <h3 className="text-red">Not authorized, please login above with Stewart Title</h3>
              </div>
            }
              {surveyValue?.messages?.length > 0 &&
                <div className="rounded gap-2 items-center border-orange border bg-light-yellow text-orange p-4">
                  <h3 className="text-red">Stewart Title Messages</h3>
                  <div className="flex flex-col">
                    {surveyValue.messages?.map(({Message}, i) => (
                        <div key={i} className="text-red">{Message}</div>
                      ))
                    }
                  </div>
                </div>
            }
            {errors &&<div className="rounded border-red border bg-light-light-red text-red p-4">
              <h3 className="text-red">Missing Title Insurance Info</h3>
              {errors.map((error, i) => (
                <div key={i}>
                  {error}
                </div>
              ))}
            </div>}
            {updateFlag &&<div className="rounded flex gap-2 items-center border-orange border bg-light-yellow text-orange p-4">
              <FontAwesomeIcon icon={faWarning}/>
              <h3 className="text-red">ConveyMe Title Insurance Info has been updated since last Stewart Title submission</h3>
            </div>}
          </div>
          <StewartTitleResources/>
        </div>
      </>
    )
  };

export default StewartTitleInsurcneInput;