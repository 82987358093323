import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import TableContext from 'contexts/TableContext';
import ParentContext from 'contexts/ParentContext';
import { addSurveyValue, getCalculatedValue, getSurveyValue, pathMaker } from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import { isArray } from '@apollo/client/cache/inmemory/helpers';
import _ from 'lodash';

export default function MagicTable({
  title,
  calculatedLabel,
  formStyles,
  description,
  questionKey,
  children,
  defaults,
  labels,
  lineItemKey,
  questions,
  magicDataKey,
  hidden
}) {
  // pr-8
  const [bottomPadded, setBottomPadded] = useState();
  const {row, prevRows, parentPath, subscribeCleanup} = useContext(ParentContext);
  const { file, updateAnswers, updateCalculation, lastUpdate } = useContext(SurveyContext);
  const [calculations, setCalculations] = useState({});
  const [cachedSurveyValue, setCachedSurveyValue] = useState();
  const [cachedCalculatedDeps, setCachedCalculatedDeps] = useState();
  const [depsChanged, setDepsChanged] = useState(0);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const calculatedLabelValue = useMemo(() => {
    return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const surveyValue = useMemo(() => {
    let value = file && getSurveyValue(file.answers, path);
    if ((!value || value.length === 0 || !isArray(value)) && defaults?.length > 0) {
      updateAnswers(path, defaults);
      setCachedSurveyValue(defaults);
      return defaults;
    }
    if (_.isEqual(cachedSurveyValue, value)) {
      return cachedSurveyValue
    }
    setCachedSurveyValue(value);
    return value;
  }, [file, path]);

  useEffect(() => {
    for (let update of lastUpdate) {
      if (cachedCalculatedDeps.includes('answers.' + update)) {
        setDepsChanged(depsChanged + 1);
      }
    }
  }, [lastUpdate]);

  const questionKeys = useMemo(() => {
    return questions.map(question => question.question.key);
  }, []);

  const questionTotals = useMemo(() => {
    return questions.map(question => question.question.total);
  }, []);

  const addHiddenValues = useCallback((values, hiddenValues) => {
    if (values.length == 0) return [];
    let includingHiddenValues = [...hiddenValues];
    let lastIndex = 0;
    while (lastIndex < includingHiddenValues.length) {
      if (!includingHiddenValues[lastIndex]) {
        includingHiddenValues[lastIndex] = values.shift();
      }
      lastIndex ++;
    }
    return [...includingHiddenValues, ...values].filter(value => value);
  }, []);

  const [magicData, magicTotalValues, magicTotals, linkLookup, hiddenValues] = useMemo(() => {
    let linkLookup = {};
    let magicTotalValues = {};
    let magicTotals = {};
    let hiddenValues = [];
    let calculatedDeps = '';
    let res = [{
      magicArray: (surveyValue || []).reduce((prev, row, i) => {
        let lineItem = {};
        let usesCalculation = false;
        if (!row) return prev;
        if (row.show && !getCalculatedValue(file, row.show, prevRows)) {
          hiddenValues[i] = row;
          return prev
        }
        let rowData = questionTotals.map((total, i) => row.data[i] || {})
        for (let [j, col] of (rowData.entries() || [])) {
          lineItem[questionKeys[j]] =  col && (col.static || getCalculatedValue(file, col.calculated, prevRows));
          if (Number(lineItem[questionKeys[j]]) || Number(lineItem[questionKeys[j]]) === 0) lineItem[questionKeys[j]] = Number(lineItem[questionKeys[j]]) || undefined;
          usesCalculation = usesCalculation || col?.calculated;
          if (col?.calculated) {
            if (!col.static) {
              calculatedDeps += col.calculated;
            }
            linkLookup[`magicArray[${prev.length}].${lineItemKey}.${questionKeys[j]}`] = {status: col?.static ? -1 : 1, linkId: col.linkId};
          }
          // calculate totals
          if (!col?.skipTotal && questionTotals[j]) {
            magicTotals[questionTotals[j]] = [...( magicTotals[questionTotals[j]] || []), `magicArray[${i}].${lineItemKey}.${questionKeys[j]}`];
            magicTotalValues[questionTotals[j]] = getCalculatedValue(file, 'answers.' + questionTotals[j], prevRows) || Math.round((Number(magicTotalValues[questionTotals[j]] || 0) + Number(lineItem[questionKeys[j]] || 0)) * 100)/100;
          }
        }
        lineItem['magicKey'] = usesCalculation ? (i + 1) : undefined;
        return [...prev, {[lineItemKey]: lineItem}];
      }, [])
    }, magicTotalValues, magicTotals, linkLookup, hiddenValues]
    if (magicDataKey) {
      updateCalculation(magicDataKey, res[0].magicArray);
    }
    setCachedCalculatedDeps(calculatedDeps + questionTotals.map(total => 'answers.' + total).join(''));
    return  res;
  }, [surveyValue, depsChanged]);

  const magicUpdateAnswers = (paths, data) => {
    if (paths) {
      // first create new magic data
      if (!Array.isArray(paths)) {
        paths = [paths];
        data = [data];
      }
      let answers = magicData;
      for (let i = 0; i < paths.length; i ++) {
        answers = addSurveyValue(answers || {}, paths[i], data[i]);
      }
      // then convert new magic data back to survey data
      let newSurveyValue = answers.magicArray.map(lineItem => {
        let rowData = [];
        let currentMagicKey = lineItem[lineItemKey].magicKey
        let row = {show: currentMagicKey ? surveyValue[currentMagicKey - 1]?.show : 'true'};
        for (let [key, value] of Object.entries(lineItem[lineItemKey])) {
          let columnIndex = questionKeys.indexOf(key);
          if (columnIndex >= 0) {
            //set column data
            let column;
            if (currentMagicKey) {
              column = {
                //copy linked/calculated data
                ...(surveyValue[currentMagicKey - 1]?.data?.[columnIndex] || {}),
              }
              //find old magic data with same key, if value is different update static
              let oldData = magicData.magicArray.find(row => row[lineItemKey].magicKey == currentMagicKey);
              if (oldData?.[lineItemKey]?.[key] != value) {
                column.static = value;
              }
            } else {
              column = {
                static: value
              }
            }
            rowData[columnIndex] = column;
          }
        }
        // set each row
        row.data = rowData;
        return row;
      })
      updateAnswers(path, addHiddenValues(newSurveyValue, hiddenValues));
    }
  }
  const magicUpdateCalculation = useCallback((paths, data) => {
    // setCalculations((current) => {
    //   if (paths) {
    //     let pushPaths = [];
    //     let pushData = [];
    //     if (!Array.isArray(paths)) {
    //       paths = [paths];
    //       data = [data];
    //     }
    //     let newCalculations = {...current};
    //     for (let i = 0; i < paths.length; i ++) {
    //       newCalculations = addSurveyValue((newCalculations || {}), paths[i], data[i]);
    //       if (!paths[i].includes('magicArray')) {
    //         pushPaths.push(paths[i]);
    //         pushData.push(data[i]);
    //       }
    //     }
    //     if (pushPaths.length > 0) {
    //       updateCalculation(pushPaths, pushData);
    //     }
    //     return newCalculations;
    //   }
    // })
  }, []);

  // -mr-2
  return (
    <TableContext.Provider value={{setBottomPadded}}>
        <SurveyContext.Provider value={{ 
            file: { answers: {...magicData, ...magicTotalValues }},
            totals: magicTotals,
            updateAnswers: magicUpdateAnswers,
            updateCalculation: magicUpdateCalculation,
            updateTotalsAndCalculations: () => (3),
            updateErrors: () => (4),
            linked: linkLookup
          }} 
        >
          <div className={`flex flex-col gap-2 w-full survey-table ${hidden ? 'hidden' : ''}`}>
            {children}
          </div>
        </SurveyContext.Provider>
    </TableContext.Provider>
  )
}
