import React, {useContext, useMemo} from 'react';
import SurveyContext from 'contexts/SurveyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { getCalculatedValue, pageValid } from 'selectors/formSelectors';
import { Link, useLocation } from 'react-router-dom';


export default function SurveyChapterItem({page, pageNumber, currentPage}) {
  const { file, fileId, surveyId } = useContext(SurveyContext);
  const location = useLocation();
  const filled = useMemo(() => {
    return pageValid(file, page);
  }, [file, page]);
  
  let subNav = useMemo(() => page.questions.reduce((prev, curr) => {
    if (curr.question.id) {
      if (curr.question.hideCalculation || curr.question.showCalculation) {
        let res = curr.question.hideCalculation ?  getCalculatedValue(file, curr.question.hideCalculation, []) : !getCalculatedValue(file, curr.question.showCalculation, []);
        res || prev.push(curr.question.id);
      } else {
        prev.push(curr.question.id);
      }
    }
    return prev;
  }, []), [page, file]);

  return (
    <>
      <Link className="no-underline hover:bg-light-grey dark:hover:bg-transparent-grey" to={`/files/${surveyId}/${fileId}/${pageNumber + 1}`}>
        <div className={`py-2 sm:pl-2 md:pl-4 lg:pl-6 xl:pl-8 2xl:py-2.5 flex gap-2 justify-between text-xs 2xl:text-sm 2xl:leading-2 items-center ${pageNumber + 1 === currentPage && 'bg-dark-grey dark:bg-transparent-darkest-grey text-white'}`}>
              {page.title}
            { filled &&
            <FontAwesomeIcon className="text-green sm:pl-1 md:pl-3 lg:pl-5 pr-2" icon={faCircleCheck} />
          }
        </div>
      </Link>
      {
        (pageNumber + 1 === currentPage) && subNav?.map(item => (
          <Link className="no-underline hover:bg-light-grey dark:hover:bg-transparent-grey " to={`/files/${surveyId}/${fileId}/${pageNumber + 1}#${item.replaceAll(' ', '')}`}>
            <div className={`py-1 sm:px-2 md:px-4 lg:px-6 xl:px-8 flex gap-2 justify-between text-xxs 2xl:text-xs items-center ${pageNumber + 1 === currentPage && 'bg-med-grey dark:bg-transparent-grey text-white hover:bg-transparent-darkest-grey'}`}>
                  {item}
            </div>
          </Link>
        ))
      }
    </>
  )
}
