import { gql, useMutation } from '@apollo/client';
import { faChevronDown, faChevronUp, faFileLines, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/Tooltip';
import TooltipBasic from 'components/common/TooltipBasic';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import MiniChecklist from 'components/survey/MiniChecklist';
import AppContext from 'contexts/AppContext';
import CalendarContext from 'contexts/CalendarContext';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const DELETE_CALENDAR_ITEM = gql`
  mutation DeleteCalendarItem($id: String!) {
    deleteCalendarItem(id: $id) {
      id
    }
  }
`;

export default function CalendarItem({event}) {
  const {removeEvent, colorMode} = useContext(CalendarContext);
  const { currentUser } = useContext(AppContext);
  const {refetch} = useContext(CalendarContext);
  const [expand, setExpand] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteCalendarItemMutation, { loading }] = useMutation(DELETE_CALENDAR_ITEM);


  useEffect(() => {
    if (!event.fileId || !event.surveyId) {
      setExpand(false);
    }
  }, [event.fileId, event.surveyId]);

  const handleRemoveItem = () => {
    deleteCalendarItemMutation({variables: {id: event.id}})
    .then((res) => {
      removeEvent(event.id);
      setShowConfirm(false);
    });
  }

  return (
    <>
      {showConfirm && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete the event from all calendars.`}
        onConfirm={handleRemoveItem}
        onClose={() => setShowConfirm(false)}
      /> }
      <div className="flex flex-col">
        <div 
          onClick={() => setExpand(!expand)}
          className={`rounded flex shadow-sm cursor-pointer bg-white hover:bg-light-light-grey dark:bg-shadow items-center z-10 w-full ${expand ? '' : ''}`}
          style={{border: `1px solid ${event.colorOne}`}}
        > 
          <div 
            className="h-full flex items-center justify-center"
            style={{border: `1px solid ${event.colorOne}`, backgroundImage: `linear-gradient(${colorMode ? event.user.color : event.colorOne} ${(Number(event.percentage) || Number(event.percentage) === 0) ? 100 - event.percentage*100 : '100'}%, ${colorMode ? event.user.color : (event.colorTwo || event.colorOne)})`}}
          >
            {event.icon}
          </div>
          <div
            className={`flex p-2 items-center justify-between gap-2 w-full rounded-r`}
          >
            <div className="flex items-center gap-2">
              <div className="relative flex flex-col gap-0">
                <span className="text-primary text-xxs -mb-1 font-bold whitespace-nowrap">#{event.tag}</span>
                <p className="font-semibold text-sm leading-4 dark:text-bone">{event.name}</p>
              </div> 
              <small className="self-end">{event.subText}</small>
              {event.percentage ? <small className="text-xs font-bold">{Math.round(event.percentage*100)}%</small> : ''}
            </div>
            <div className="flex gap-2 items-center">
              {event.fileId &&
                <a target="_blank" rel="noreferrer" href={`files/${event.surveyId}/${event.fileId}/summary`}>
                  <TooltipBasic message={`File: ${event.fileId}`} className="relative">
                    <div className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:bg-dark-grey`}>
                        <FontAwesomeIcon icon={faFileLines} className="text-xs dark:text-light-grey"/>
                    </div>
                  </TooltipBasic>
                </a>
              }
              {
                ["admin", "super-admin"].includes(currentUser.type) ?
                  <Link to={`/admin/users/${event.userId}`}>
                    <TooltipBasic message={event.user.username} className="relative">
                      <div className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:bg-dark-grey`}>
                          <FontAwesomeIcon icon={faUser} className="text-xs dark:text-light-grey"/>
                      </div>
                    </TooltipBasic>
                  </Link>
                :
                  <TooltipBasic message={event.user.username} className="relative">
                    <div className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:bg-dark-grey`}>
                        <FontAwesomeIcon icon={faUser} className="text-xs dark:text-light-grey"/>
                    </div>
                  </TooltipBasic>
              }
              {event.fileId &&
                <FontAwesomeIcon style={{color: `${event.colorOne}`}} className={`cursor-pointer`} onClick={() => setExpand(!expand)} icon={expand ? faChevronUp : faChevronDown}/>
              }
              {
                event.id &&
                <FontAwesomeIcon onClick={() => setShowConfirm(true)} className="cursor-pointer text-red" icon={faTimes}/>
              }
            </div>
          </div>
        </div>
        {expand &&
          <div className={`rounded-b p-2 z-0 pt-3 bg-light-light-grey dark:bg-shadow border-light-grey border-t-0 dark:border-midnight -mt-1 flex shadow-sm border w-full`}>
            <MiniChecklist phase={event.subText} surveyId={event.surveyId} fileId={event.fileId} refetch={refetch}/>
          </div>
        }
      </div>
    </>
  )
}
