import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faPenToSquare, faPhone, faEnvelope, faLocationDot, faCakeCandles, faBriefcase, faEarthAmericas, faLink } from '@fortawesome/free-solid-svg-icons';
import AddClient from 'components/surveyInputs/AddClient';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import TooltipBasic from 'components/common/TooltipBasic';
import Tooltip from 'components/common/Tooltip';

export default function Client({client, onDeselect, children, onUpdate, mini, copyAddress, copyPropertyAddress, type, firmId}) {
  const [edit, setEdit] = useState();

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }
  return (
    edit ?
      <AddClient firmId={firmId} type={type} copyAddress={copyAddress} copyPropertyAddress={copyPropertyAddress} edit={client} onDeselect={onDeselect} close={() => setEdit(false)} onCreate={onCreate}/>
    :
      <div className={`rounded relative flex flex-col min-w-40 ${mini ? 'text-xs' : ''}`}>
        <TooltipBasic message="Edit Person" className="absolute top-1 right-6">
          <div onClick={() => setEdit(true)} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-green flex justify-center items-center">
            <FontAwesomeIcon  className={`text-xxs`} icon={faPenToSquare}/>
          </div>
        </TooltipBasic>
        {(onDeselect) && 
          <TooltipBasic message="Unselect Person" className="absolute top-1 right-1.5">
            <div onClick={onDeselect} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
              <FontAwesomeIcon  className={`text-xs`} icon={faTimes}/>
            </div>
          </TooltipBasic>
        }
        <div className={`flex items-center gap-1.5 p-3 pr-4 bg-med-grey dark:bg-midnight dark:border dark:border-transparent-grey ${mini ? 'rounded' : 'rounded-t'} text-white pr-12`}>
            <FontAwesomeIcon className={`${client?.linkedUser ? 'text-green' : ''} text-xs`} icon={faUser}/>
            <h3 className="font-semibold text-sm text-white">{client.title} {client.firstName} {client.lastName}
              {
                client?.linkedUser &&
                <Tooltip
                  icon={faLink}
                  color="green"
                  message={"This person is linked to a user"}
                />
              }
            </h3>
        </div>
        {
          !mini &&
          <div className="flex flex-col gap-1.5 p-3 border bg-white rounded-b border-transparent-grey dark:bg-shadow">
            {(client.email || client.phoneNumber) && <div className="flex gap-2">
              {client.email && <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faEnvelope}/> {client.email}</small>}
              {client.phoneNumber && <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faPhone}/> {client.phoneNumber}</small>}
            </div>}
            {client.country && <div className="flex gap-1.5">
              <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faEarthAmericas}/> {client.country}</small>
            </div>}
            {client.addressLineOne && <div className="flex gap-1.5">
              <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faLocationDot}/> {client.addressLineOne}, {client.city}</small>
            </div>}
            {client.dateOfBirth && <div className="flex gap-1.5">
              <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faCakeCandles}/> {client.dateOfBirth}</small>
            </div>}
            {client.occupation && <div className="flex gap-1.5">
              <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faBriefcase}/> {client.occupation}</small>
            </div>}
            {client.sin && <div className="flex gap-1.5">
              <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faIdCard}/> SIN: {client.sin}</small>
            </div>}
            {
              (children?.length > 0) &&
              <div className="text-xs flex flex-col gap-4 mt-2">
                {children}
              </div>
            }
          </div>
        }
      </div>
  )
}
