import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import stewartTitleLogo from "assets/images/survey/stewartTitleLogo.png";
import ParentContext from 'contexts/ParentContext';
import AppContext from 'contexts/AppContext';

const CREATETOKEN = gql`
  mutation CreateToken($fileId: String!, $surveyId: String!) {
    createStewartTitleTokenRequest(fileId: $fileId, surveyId: $surveyId) {
      redirectUrl,
    }
  }
`;

const DELETETOKEN = gql`
  mutation DeleteStewartTitleToken {
    deleteStewartTitleToken {
      userId,
      id,
      tokenUsername,
    }
  }
`;

const GETTOKEN = gql`
  mutation GetToken {
    getStewartTitleToken {
      token {
        id,
        userId
      },
      message
    }
  }
`;

const StewartTitlecredentialsInput = ({ 
  questionKey, 
}) => {
    const { file, updateAnswers } = useContext(SurveyContext);
    const {currentUser} = useContext(AppContext);
    const [ update, setUpdate ] = useState();
    const [message, setMessage] = useState();
    const [getTokenMutation, { loading, error }] = useMutation(GETTOKEN);
    const [token, setToken] = useState();
    const [createTokenMutation, { loading: createLoading, error: createError }] = useMutation(CREATETOKEN);
    const [deleteTokenMutation, { loading: deleteLoading, error: deleteError }] = useMutation(DELETETOKEN);
    const [surveyValue, setSurveyValue] = useState();
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    useEffect(() => {
      let value = file && getSurveyValue(file.answers, path);
      setSurveyValue(value);
    }, [file, path]);


    useEffect(() => {
      if (file.answers) {
        getTokenMutation({
        })
        .then(res => {
          updateAnswers(path, res.data.getStewartTitleToken?.token?.id);
          setToken(res.data.getStewartTitleToken?.token);
        })
      }
    }, []);

    const submit = () => {
      createTokenMutation({
        variables: {fileId: file.id, surveyId: file.surveyId}
      })
      .then(res => {
        window.location.replace(res.data.createStewartTitleTokenRequest.redirectUrl);
      })
    }

    const remove = () => {
      deleteTokenMutation()
      .then(res => {
        updateAnswers(path, null);
      })
    }
    
    return message ?
      <div className="rounded border p-4 bg-light-light-red border-red flex items-center gap-8">
        <img className="h-8 self-start" src={stewartTitleLogo} alt="Stewart Title logo"/>
        <strong className="text-red"> 
          {message}
        </strong>
      </div>
      : (!surveyValue || update || (surveyValue == 'Expired')) ?
      (
        <div className="rounded border p-4 flex flex-col gap-2 bg-transparent-grey border-grey dark:border-transparent-grey dark:bg-midnight">
          <img className="h-8 self-start" src={stewartTitleLogo} alt="Stewart Title logo"/>
          <ButtonPrimary className="self-end" disabled={createLoading || loading} onClick={submit}>Authorize</ButtonPrimary>
        </div>
      )
      :
      <div className="rounded border p-4 bg-light-green border-green flex flex-col gap-8">
        <div className=" flex items-center gap-8">
          <img className="h-8 self-start" src={stewartTitleLogo} alt="stewart title logo"/>
          <strong className="text-green"> 
            Authorized for Stewart Title
          </strong>
        </div>
        {currentUser?.id === token?.userId &&
          <div className="flex justify-end gap-2">
            <ButtonPrimary className="self-end text-red border-red" disabled={deleteLoading || loading} onClick={remove}>Remove</ButtonPrimary>
            <ButtonPrimary className="self-end" disabled={createLoading || loading} onClick={() => setUpdate(true)}>Update</ButtonPrimary>
          </div>
        }
      </div>
};

export default StewartTitlecredentialsInput;