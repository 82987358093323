import { faCheck, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/Tooltip';
import React from 'react';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

export default function DocumentTitle({errors, ltsa, pdf, document}) {
  return (
    <div className="flex gap-2 items-center">
      { errors ? 
        <FontAwesomeIcon className="text-red text-lg" icon={faTriangleExclamation} /> 
        :
        <FontAwesomeIcon className="text-green text-lg" icon={faCheck} /> 
      }
      {
        ltsa && <img src={ltsaLogo} className="h-8" alt="ltsa logo"/>
      }
      {
        pdf && <FontAwesomeIcon className="" icon={faFilePdf} />
      }
      <strong className="text-sm">
        {document.name}
        {document.info.warning && <Tooltip className="text-orange border-orange" color="orange" icon={faTriangleExclamation} message={document.info.warning} />}
      </strong>
    </div>
  )
}
