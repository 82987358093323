import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimaryOutline = ({className, ...rest}) => {
  return (
    <ButtonRoot 
      className={`
        border
        font-semibold 
        text-sm
        hover:no-underline
        hover:brightness-98
        dark:text-bone
        ${className && className.includes("border") ? "" : "border-light-grey"}
        ${className && className.includes("rounded") ? "" : "rounded"}
        ${className && className.includes("bg-") ? "" : "bg-white dark:bg-transparent"}
        ${className}
      `}
      {...rest}
    />
  );
};

export default ButtonPrimaryOutline;
