import React from 'react';

const PaddedPage = ({ children, className, noBottom, noTop, style }) => {
  return (
    <div className={`
      sm:px-2
      md:px-4 
      lg:px-6 
      xl:px-8 
      grow
      ${
        !noTop &&
        `sm:pt-2
        md:pt-4
        lg:pt-6 
        xl:pt-8 `
      }
      ${!noBottom && `
        sm:pb-2
        md:pb-4 
        lg:pb-6 
        xl:pb-8 
      `}
      ${className}
    `}
      style={style}
    >
      {children}
    </div>
  );
};

export default PaddedPage;

