import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { faBuilding, faFilePdf } from '@fortawesome/free-regular-svg-icons';

export default function SelectedDocument({document, onCancel}) {
  if (!document) return <></>
  return (
    <div className="px-2 bg-white py-1 pr-6 rounded flex gap-2 items-center cursor-pointer hover:bg-light-green relative border border-green w-72 dark:bg-midnight">
      {
        document.firmId &&
        <FontAwesomeIcon className="text-green" icon={faBuilding} />
      }
      {
        document.type == "ltsa" &&
        <img src={ltsaLogo} alt="LTSA logo" className="h-4"/>
      }
      {
        ["mortgage", "pdf"].includes(document.type) &&
        <FontAwesomeIcon className="" icon={faFilePdf} />
      }
      <span className={` tight font-medium text-sm ${document.type === "ltsa" ? "text-green" : ""}`}>{document.name}</span>
      <FontAwesomeIcon onClick={() => onCancel(document)} className="absolute top-1 right-1.5 text-sm text-green" icon={faTimes} />
    </div>
  )
}
