import React, {useContext, useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import stewartTitleLogo from "assets/images/survey/stewartTitleLogo.png";
import ParentContext from 'contexts/ParentContext';
import Transparent from 'components/notices/Transparent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalculator, faGears, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import useSurveyErrors from 'hooks/useSurveyErrors';
import { validFCTInputs, validLTSAInputs } from 'helpers/document';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { softSubset } from 'helpers/general';
import { faHandPointUp, faHandPointer } from '@fortawesome/free-regular-svg-icons';

const CREATE_RESOURCE_LINK = gql`
  mutation CreateStewartTitleResourceLink($type: String!) {
    createStewartTitleResourceLink(type: $type) {
      redirectUrl,
    }
  }
`;

let statusLookup = {
  undefined: null,
  'Order not Submitted': true,
  'Unordered': true, 
  'Flagged': true, 
  'Ordered': true,  
  'Order Resubmission Required': true,  
  'Cancelled': true, 
  'Declined': true,
  'Closed': false,
}

const StewartTitleResources = () => {
    const [createResourceLink] = useMutation(CREATE_RESOURCE_LINK);

    const resourceRedirect = (type) => {
      createResourceLink({
        variables: {type}
      })
      .then(res => {
        window.open(res.data.createStewartTitleResourceLink.redirectUrl, '_blank');
      })
    }

    return (
      <div className="flex flex-col mt-4">
        <div className="flex gap-2 bg-grey items-center p-2 dark:bg-midnight">
          <FontAwesomeIcon icon={faGears}/>
          <h3 className='dark:text-bone'>Stewart Title Resources</h3>
        </div>
        <div onClick={() => resourceRedirect('home')} className="cursor-pointer hover:font-bold flex gap-2 items-center p-2 border border-grey bg-light-light-grey dark:bg-shadow dark:border-transparent-grey">
          <FontAwesomeIcon icon={faArrowRight}/>
          Home/Tools
        </div>
        <div onClick={() => resourceRedirect('calculator')} className="cursor-pointer hover:font-bold flex gap-2 items-center p-2 border border-grey bg-light-light-grey dark:bg-shadow dark:border-transparent-grey">
          <FontAwesomeIcon icon={faCalculator}/>
          Premium Calculator
        </div>
        <div onClick={() => resourceRedirect('policy')} className="cursor-pointer hover:font-bold flex gap-2 items-center p-2 border border-grey bg-light-light-grey dark:bg-shadow dark:border-transparent-grey">
          <FontAwesomeIcon icon={faHandPointer}/>
          Order Commercial Policy
        </div>
      </div>
    )
  };

export default StewartTitleResources;