import React, {useContext, useState, useEffect, useMemo} from 'react';
import AppContext from 'contexts/AppContext';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faTriangleExclamation, faCircleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from './Transparent';


let colorLookup = {
  error: 'bg-red',
  warning: 'bg-yellow',
  success: 'bg-green'
}

let titleLookup = {
  error: 'Error !',
  warning: 'Warning',
  success: 'Success !'
}

let IconLookup = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  success: faCircleCheck
}

export default function Notices() {
  let {success} = useContext(AppContext);
  let {currentError, removeError} = useContext(RequestFlagsContext);
  let [open, setOpen] = useState(false);
  let message = currentError ? currentError[1].message : success && success.message;

  useEffect(() => {
    if ((currentError && !currentError.handled) || success) {
      setOpen(true);
    }
  }, [currentError, success]);

  const close = () => {
    setOpen(false);
    if (currentError) {
      removeError(currentError[0]);
    } else if (success) {
      // TODO remove errors or succss messages
      //remove success message
    }
  }

  let type = useMemo(() => {
    if (currentError && ['403', '400'].includes(currentError[0])) {
      return 'error';
    } else if (success) {
      return 'success';
    } else {
      return 'warning';
    }
  }, [currentError, success]);

  return (
    <>
      {
        open &&
          <div className={`sticky top-0 z-50 bg-red text-white flex items-center justify-center relative animate-shake ${colorLookup[type]}`}>
            <FontAwesomeIcon onClick={close} className="" icon={IconLookup[type]} />
            {titleLookup[type]} {message}
            <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute right-2" icon={faTimes} />
          </div>
      }
    </>
  )
}
