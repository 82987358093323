import React, {useMemo, useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input, List, SurveySelector } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Message from 'components/common/Message';
import { Table } from 'components/surveyInputs';
import useFirmChoices from 'hooks/useFirmChoices';
import useMortgageChoices from 'hooks/useMortgageChoices';
import MortgageSelector from 'components/inputs/MortgageSelector';

const NEW_DOCUMENT = gql`
  mutation NewDocument($name: String!, $surveyIds: [String], $template: Upload, $type: String, $info: JSONObject, $category: String, $firmId: String) {
    newDocument(name: $name, surveyIds: $surveyIds, template: $template, type: $type, info: $info, category: $category, firmId: $firmId) {
      id
    }
  }
`;

export default function CreateDocument() {
	const {firmChoices, firmId, setFirmId} = useFirmChoices(null, {name: "None", value: ""}, null, true);
	const [mortgageIds, setMortgageIds] = useState([]);
	const [file, setFile] = useState();
	const [name, setName] = useState();
	const [type, setType] = useState('');
	const [info, setInfo] = useState({});
	const [category, setCategory] = useState();
	const [errors, setErrors] = useState([]);
	const navigate = useNavigate();
	const [surveyIds, setSurveyIds] = useState();
	const [newDocumentMutation, { loading, error }] = useMutation(NEW_DOCUMENT);

	const onFileChange = (event) => {
		setFile(event.target.files[0]);
	}
	const onSubmit = () => {
		newDocumentMutation({
			variables: {
				template: file,
				name,
				surveyIds,
				type,
				category,
				firmId,
				info: (["ltsa", "mortgage"].includes(type) ? 
				  {...info, mortgageIds: mortgageIds, variables: info.variables && JSON.parse(info.variables), questions: info.questions && JSON.parse(info.questions)} 
				  : 
				  {...info, requests: info.requests && JSON.parse(info.requests), questions: info.questions && JSON.parse(info.questions || [])} ) 
			}
		})
		.then(res => {
			navigate("/admin/documents");
		})
	}
	const valid = useMemo(() => {
		let errors = [];
		try {
			info?.variables && JSON.parse(info.variables);
			info?.questions && JSON.parse(info.questions);
			setErrors([]);
		} catch (err) {
			console.log("bad json", err);
			setErrors(["Invalid Variables/Questions, must be valid JSON"])
		}
		return errors.length === 0 && (["cheque", "trust"].includes(type) || (surveyIds && surveyIds.length > 0)) && name && (['', 'cheque'].includes(type) ? file : info);
	}, [file, name, surveyIds, type, info]);
  return (
	<PaddedPage bottom>
		<div className="flex flex-col gap-2">
			<Table title="📑Create Document">
				<div className="flex flex-col gap-2 items-start"> 
					<label>Name</label>
					<Input onChange={setName}/>
					<label>Category</label>
					<Input onChange={setCategory}/>
					<label>SurveyId</label>
					<SurveySelector onChange={(val) => setSurveyIds(val.map(survey => survey.id))} />
					<label>Type</label>
					<List options={[
						{name: "ConveyMe", value: ""},
						{name: "LTSA", value: "ltsa"},
						{name: "Mortgage", value: "mortgage"},
						{name: "PDF", value: "pdf"},
						{name: "Cheque", value: "cheque"},
						{name: "Trust Document", value: "trust"}
					]} onChange={setType}/>
					<label>Firm</label>
					<List onChange={setFirmId} className="" options={firmChoices}/>
					{
						type === "ltsa" ?
						<>
							<label>LTSADocument Type</label>
							<Input type="text" onChange={(documentType) => setInfo({...info, documentType})} /> 
							<label>Warning</label>
							<Input type="text" onChange={(warning) => setInfo({...info, warning})}/> 
						</>
						: ["mortgage", "pdf"].includes(type) ?
							<>
								{type === "mortgage" && 
									<>
										<label>Mortgage</label>
										<MortgageSelector onChange={(val) => setMortgageIds(val.map(survey => survey?.value))}/>
									</>
								}
								<label>PDF</label>
								<Input type="text" onChange={(pdfLink) => setInfo({...info, pdfLink})} /> 
							</>
						:
						<>
							<label>File</label>
							<input type="file" onChange={onFileChange} /> 
						</>
					}
					{
						["mortgage", "pdf", 'ltsa'].includes(type) &&
						<>
							<label>Variables (JSON LIST)</label>
							<Input type="text" onChange={(variables) => setInfo({...info, variables})}/>
						</>
					}
					<label>Questions</label>
					<Input type="textarea" className="w-full" valueOverride={info.questions} onChange={(questions) => setInfo({...info, questions})} />  
					{
						errors.map((error, i) => 
							<Message negative={true}>
								{error}
							</Message>
						)
					}
					<ButtonPrimary disabled={loading || !valid} className="mt-4" onClick={onSubmit}> 
						Create!
					</ButtonPrimary> 
				</div> 
			</Table>
		</div>
	</PaddedPage>
  )
}
