import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ButtonPrimary from './Button'
import Loading from './Loading';
import Tooltip from './Tooltip';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export default function Search({filters, setFilters, onClick, loading, toolTipText, className, valueOverride, useOverride, forwardedRef}) {
  let [search, setSearch] = useState(filters.search);
  let handleClick = () => {
    onClick({...filters, search})
  }

  let handleChange = (e) => {
    setSearch(e.target.value);
    setFilters && setFilters({...filters, search: e.target.value});
  }
  return (
    <div className={`relative flex gap-1 justify-center ${className}`}>
      <div className="relative flex gap-2">
        <input className="rounded-full border p-1 pl-8 border focus-visible:border-white dark:bg-midnight dark:border-slate" value={useOverride ? valueOverride || '' : search} onChange={handleChange}/>
        <FontAwesomeIcon icon={faSearch} className="absolute left-2 text-sm top-2.5 text-med-grey" />
        {toolTipText &&
          <Tooltip
            forwardedRef={forwardedRef}
            icon={faQuestionCircle} 
            className={"self-start text-xs ml-0 mt-2"}
            message={toolTipText}
          /> 
        }
        {onClick && <ButtonPrimary disabled={loading} onClick={handleClick}>{loading ? <Loading/> : 'Search'}</ButtonPrimary>}
      </div>
    </div>
  )
}
