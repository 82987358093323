import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input, { Checkbox } from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorSelector from 'components/inputs/ColorSelector';
import ReccentSurveyFiles from 'components/survey/ReccentSurveyFiles';
import { faTag, faUser, faEnvelope, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import ClientInput from 'components/surveyInputs/ClientInput';

const GET_ME = gql`
  query GetMe {
    me {
      id,
      firstName,
      lastName,
      email,
      username,
      color,
      type,
      firms {
        id,
        name
      },
      client {
        id,
        firstName,
        lastName,
        title,
        gender,
        middleName,
        email,
        phoneNumber,
        firmId,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        province,
        postalCode,
        occupation,
        dateOfBirth,
        canadianCitizen,
        sin,
        type,
        linkedUser {
          username
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($firstName: String, $lastName: String, $color: String) {
    updateUser(firstName: $firstName, lastName: $lastName, color: $color) {
      id,
    }
  }
`;

export default function Profile() {
  const {currentUser} = useContext(AppContext);
  const { loading, error, data } = useQuery(GET_ME, { fetchPolicy:'network-only',});
  const [user, setUser] = useState({});
  const [edit, setEdit] = useState(false);
  const [updateUserMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_USER);
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	}

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me);
    }
  }, [data]);

  const toggleEdit = () => {
    if (edit) {
      setUser(data.me);
    }
    setEdit(!edit);
  }

  const updateUser = () => {
    updateUserMutation({variables: {...user}})
      .then(res => {
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }

  return (
    <PaddedPage className="flex gap-4 flex-col" noBottom>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <h1 className='dark:text-bone'>
              <FontAwesomeIcon className="" icon={faUser} /> {user.username}'s Profile
            </h1>
            <small  onClick={toggleEdit} className="hover:text-primary relative -top-2 rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faPenToSquare} /></small>
          </div>
          <div className="flex items-center gap-2">
            <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}  {user.lastName}</small>
            <small><FontAwesomeIcon className="" icon={faEnvelope} /> {user.email}</small>
            {user.type == "admin" && <div className="rounded bg-red text-white text-xs px-2 font-semibold">Admin</div>}
            {user.type == "super-admin" && <div className="rounded bg-red text-white text-xs px-2 font-semibold">Super-Admin</div>}
          </div>
        </div>
        <div>
          <div className="flex  flex-wrap gap-6 bg-light-light-grey dark:bg-shadow border rounded p-2 dark:border-midnight">
            <div className="flex flex-col">
              <label className='dark:text-bone'>User Color</label>
              {
                edit ? 
                <ColorSelector initialValue={user.color} onChange={(color) => setUser({...user, color})}/>
                :
                <div className="w-10 h-10 rounded border-2" style={{backgroundColor: user.color || "white"}}/>
              }
            </div>
            <div className="flex flex-col">
              <label className='dark:text-bone'>First Name</label>
              <hr></hr>
              {edit ?<Input valueOverride={user.firstName} onChange={(firstName) => setUser({...user, firstName})}/> : <p className="text-sm dark:text-bone">{user.firstName}&nbsp;</p>}
            </div>
            <div className="flex flex-col">
              <label className='dark:text-bone'>Last Name</label>
              <hr></hr>
              {edit ?<Input valueOverride={user.lastName} onChange={(lastName) => setUser({...user, lastName})}/> : <p className="text-sm dark:text-bone">{user.lastName}&nbsp;</p>}
            </div>
            <div className="flex flex-col">
              <label className='dark:text-bone'>Email</label>
              <hr></hr>
              <p className="text-sm dark:text-bone">{user.email}&nbsp;</p>
            </div>
          </div>
          {edit && <ButtonPrimary className="self-start mt-2" disabled={updateLoading} onClick={updateUser}>{updateLoading ? <Loading/> : "Update Profile"}</ButtonPrimary>}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 w-full">
          <div>
            <h2 className='dark:text-bone'>User Info for Files</h2>
            <hr className="mt-1 dark:border-bone"></hr>
          </div>
          <ClientInput firmId={user?.firms?.[0]?.id} valueOverride={user.client} type={["lawyer", "notary", "conveyancer"]}/>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <h2 className='dark:text-bone'>Firms</h2>
            <hr className="mt-1 dark:border-bone"></hr>
          </div>
          <div className="flex items-center gap-2">
            {(user?.firms || []).map( firm => (
              <div key={firm.id} className={`flex cursor-pointer rounded px-2 bg-primary gap-2 pr-1 text-sm text-white font-bold dark:text-shadow`}>
                {firm.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ReccentSurveyFiles userId={user.id}/>
    </PaddedPage>
  )
}
