import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBuilding } from '@fortawesome/free-solid-svg-icons';
import AddTrustAccount from './AddTrustAccount';
import { useQuery, useMutation, gql } from "@apollo/client";
import TrustAccount from './TrustAccount';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import { typeToLabel } from 'helpers/general';
import ButtonPrimary from 'components/common/Button';
import ParentContext from 'contexts/ParentContext';
import AppContext from 'contexts/AppContext';

const GET_TRUST_ACCOUNTS = gql`
  query GetTrustAccounts($filters: JSONObject, $firmId: String) {
    trustAccounts(filters: $filters, firmId: $firmId) {
      id,
      name,
      bankName,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      bank {
        id,
        email,
        phoneNumber,
        fax,
        addressLineOne,
        addressLineTwo,
        province,
        city,
        postalCode,
      },
    }
  }
`;

export default function TrustAccountInput({hideLabel, label, description, questionKey, multiple, onSelect, valueOverride}) {
  const [search, setSearch] = useState();
  const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
  const searchRef = useRef();
  const { currentUser } = useContext(AppContext);
  const { row, prevRows, parentPath } = useContext(ParentContext);

  const authorized = useMemo(() => {
    return file?.firmId ? currentUser?.firms?.find(firm => !['guest', 'basic'].includes(firm.type) && firm.id == file.firmId) : true;
  }, [currentUser]);

  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNTS, {
    variables: {filters: {search}, firmId: file && file.firmId},
    skip: !authorized || !search || search === ' ' ? true : false
  });
  const [selected, setSelected] = useState([]);
  const [showNew, setShowNew] = useState();
  const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);


  const handleShowNew = (val) => {
    setShowNew(val);
    if (val) {
      setSearch('');
    } else {
      setSearch(' ');
    }
  }

  const handleSelect = (val, refreshSearch = false) => {
    if (multiple) {
      let newValue = [...selected.filter(current => current.id !== val.id), val];
      setSelected(newValue);
      if (onSelect) {
        onSelect(newValue);
      } else {
        updateAnswers && updateAnswers(path, newValue)
      }
    } else {
      setSelected([val]);
      if (onSelect) {
        onSelect(val);
      } else {
        updateAnswers && updateAnswers(path, val)
      }
    }
    setShowNew(false);
    setSearch('');
    if (refreshSearch) refetch();
  }
  const handleRemove = (i) => {
    let newValue = null;
    if (multiple) {
      newValue = [...selected];
      newValue.splice(i, 1);
      setSelected(newValue);
    } else {
      setSelected([]);
    }
    updateAnswers(path, newValue);
  }

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  useEffect(() => {
    let value = valueOverride || surveyValue;
    setSelected(multiple ? value || [] : (value ? [value] : []));
  }, [surveyValue, multiple]);

  useEffect(() => {
    if (!showNew && search === " ") {
      searchRef.current.focus();
    }
  }, [search, showNew])

  const options = data ? 
    data.trustAccounts.map(trustAccount => (
      <div 
        key={trustAccount.id}
        className="hover:bg-light-light-grey dark:bg-midnight rounded cursor-pointer p-2"
        onClick={() => handleSelect(trustAccount)}
      >
        {trustAccount.name.trim()}
      </div>
    )) 
    : [];

  return (
    authorized ?
    <div className="flex flex-col justify-start gap-2 items-start">
       {!hideLabel && label && (
          description ? 
              <label className="dark:text-bone">
                  {label}
                  <Tooltip message={description} />
              </label>
          :
              <label className="dark:text-bone">
                  {label}
              </label>
      )}
      {
        showNew &&
        <AddTrustAccount firmId={file ? file.firmId : null} close={() => handleShowNew(false)} onCreate={handleSelect}/>
      }
      {
        !showNew && (selected.length < 1 || multiple) &&
        <div className={`flex flex-col ${showNew && 'hidden'}`}>
          <div className="relative">
            <Input placeholder={`Find Trust Account`} valueOverride={search} forwardRef={searchRef} className={`pl-8 ${search && 'rounded-b-none'}`} onChange={setSearch}/>
            <FontAwesomeIcon  className={`absolute top-2 left-1`} icon={faMagnifyingGlass}/>
          </div>
          {search &&
            <div className="flex flex-col border border-t-none p-4 gap-2 bg-white dark:bg-shadow shadow-md rounded-b">
              <div onClick={() => handleShowNew(true)}className="border border-green self-start text-green rounded py-1 px-4 cursor-pointer">New Trust Account!</div>
              <hr></hr>
              <div className="flex flex-col max-h-32 overflow-y-scroll scroller">
                {
                  options.length > 0 ?
                  options
                  :
                  <small className="text-light-grey">None Found</small>
                }
              </div>
            </div>
          }
        </div>
      }
      <div className="flex gap-2 flex-wrap">
        {
          selected.map((trustAccount, i) => 
            <ParentContext.Provider key={i} value={{row: multiple ? i : null, parentPath: path, prevRows: multiple ? [...prevRows, i] : prevRows}}>
              <TrustAccount onUpdate={handleSelect} key={i} trustAccount={trustAccount} onDeselect={() => handleRemove(i)} valueOverride={valueOverride}>
              </TrustAccount>
            </ParentContext.Provider>
          )
        }
      </div>
    </div>
    : <></>
  )
}
