import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding,faFileSignature, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// companyName,
// email,
// phoneNumber,
// fax,
// type,
// addressLineOne,
// addressLineTwo,
// mortgageId,
export default function MortgageLineItem({mortgage}) {
  return (
    <div className={`rounded cursor-pointer flex bg-white border-l-4 border-light-grey shadow hover:shadow-md dark:bg-shadow dark:border-midnight`}>
      <div className="bg-light-grey flex items-center justify-center pr-1 group-hover:bg-grey dark:bg-midnight"><span>📝</span></div>
      <div className={`flex p-2 items-center gap-2 justify-between grow w-full`}>
        <div className="flex items-center gap-2">
          <strong>{mortgage.name}</strong>
          <div className="flex items-center gap-2 ml-4">
            {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
            <small><FontAwesomeIcon className="" icon={faBuilding} /> {mortgage.bank && mortgage.bank.companyName}</small>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {
            mortgage.firm &&
            <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
              <FontAwesomeIcon className="text-xxs" icon={faBuilding}/> {mortgage.firm.name}
            </div>
          }
          {
            mortgage.user &&
            <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
              <FontAwesomeIcon className="text-xxs" icon={faUser}/> {mortgage.user.username}
            </div>
          }
          {
            mortgage.public &&
            <div className={`rounded px-2 bg-primary text-sm text-white font-bold dark:text-shadow`}>
              public
            </div>
          }
        </div>
      </div>
    </div>
  )
}
