import { faClock, faNoteSticky, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp, faCirclePlus, faNotesMedical, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import TooltipBasic from 'components/common/TooltipBasic';
import Input, { IconSelector } from 'components/inputs';
import ColorSelector from 'components/inputs/ColorSelector';
import useSurveyPage from 'hooks/useSurveyPage';
import React, { useState } from 'react'

export default function ChecklistPhase({phase, answers, notes, updateNotes, updateAnswers, updatePhase, removePhase, edit}) {
  const [show, setShow] = useState(true);
  const [editNote, setEditNote] = useState({});

  const addBlock = () => {
    updatePhase({...phase, blocks: [...phase.blocks, {color: "#464646", items: []}]});
  }

  const removeBlock = (block, i) => {
    updatePhase({...phase, blocks: phase.blocks.filter((block, index) => index !== i)});
  }

  const addItem = (block, i) => {
    let newBlocks = [...phase.blocks];
    newBlocks[i] = {...newBlocks[i], items: [...newBlocks[i].items, ""]};
    updatePhase({...phase, blocks: newBlocks});
  }

  const removeItem = (itemIndex, blockIndex) => {
    let newBlocks = [...phase.blocks];
    newBlocks[blockIndex] = {...newBlocks[blockIndex], items: newBlocks[blockIndex].items.filter((item,index) => index !== itemIndex)};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updatePhaseInfo = (info) => {
    updatePhase({...phase, ...info});
  }

  const updateBlockInfo = (change, blockIndex) => {
    let newBlocks = [...phase.blocks];
    newBlocks[blockIndex] = {...newBlocks[blockIndex], ...change};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updateItem = (name, itemIndex, blockIndex) => {
    let newBlocks = [...phase.blocks];
    let newItems = [...newBlocks[blockIndex].items]
    newItems[itemIndex] = name;
    newBlocks[blockIndex] = {...newBlocks[blockIndex], items: newItems};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updateNote = (note, item) => {
    updateNotes(note, item);
  }

  notes = notes || {};
  return (
    <div>
      <div className="flex gap-1 flex-col">
        <div 
          onClick={() => setShow(!show)}
          className="flex justify-between bg-light-grey p-2 rounded items-center cursor-pointer dark:bg-shadow"
        >
          <div className='flex gap-1'>
            <h3 className='dark:text-bone'>
              {edit ?
                <div className="flex gap-2">
                  <IconSelector initialValue={phase.icon} small={true} onChange={(icon) => updatePhaseInfo({icon})}/>
                  <Input className="font-bold px-1 py-0" valueOverride={phase.title} onChange={(title) => updatePhaseInfo({title})} />
                </div>
                : 
                `${phase.icon} ${phase.title}`
              }
            </h3>
            <div className="rounded bg-primary px-1 flex items-center text-xxs gap-1 font-bold text-white dark:text-shadow">
              <FontAwesomeIcon icon={faClock}/>
              {edit ?
                <Input className="font-medium bg-primary border-primary px-1 py-1" valueOverride={phase.dateCalculation} onChange={(dateCalculation) => updatePhaseInfo({dateCalculation})} />
                :
                phase.dateCalculation?.split('.').at(-1)
              }
            </div>
          </div>
          <div className="flex gap-2">
            <FontAwesomeIcon icon={show ? faChevronUp :faChevronDown}/>
            {edit && 
              <TooltipBasic message="Delete Phase" className="relative">
                <div onClick={removePhase} className="cursor-pointer rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
                  <FontAwesomeIcon  className={`text-xs`} icon={faTimes}/>
                </div>
              </TooltipBasic>
              // <FontAwesomeIcon className="mr-1 text-bg-dark cursor-pointer" onClick={removePhase} icon={faTimesCircle}/>
            }
          </div>
        </div>
        <div className={`flex-col gap-4 p-4 ${(show || edit) ? 'flex' : 'hidden'}`}>
          {
            phase.blocks.map((block, i) => (
              <div key={i}>
                <div>
                  <div className="rounded border p-0.5 pr-2 flex justify-between items-center dark:border-shadow" style={{backgroundColor: block.color}}>
                    <div className="flex gap-2">
                      { !edit &&
                        <h4 
                          className="font-bold drop-shadow-lg rounded-l px-2 bg-white inline-block dark:bg-midnight"
                          style={{color: block.color}}
                        >
                          {block.title}
                        </h4>
                      }
                      {
                        edit &&
                        <Input style={{color: block.color}} className="font-bold px-1 py-0" valueOverride={block.title} onChange={(title) => updateBlockInfo({title}, i)} />
                      }
                      {edit && <ColorSelector small={true} initialValue={block.color} onChange={(color) => updateBlockInfo({color}, i)} />}
                    </div>
                    {edit && 
                      <TooltipBasic message="Delete Block" className="relative">
                        <div onClick={() => removeBlock(block,i)} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
                          <FontAwesomeIcon  className={`text-xs`} icon={faTimes}/>
                        </div>
                      </TooltipBasic>
                    // <FontAwesomeIcon className="mr-1 text-white cursor-pointer" onClick={() => removeBlock(block,i)} icon={faTimesCircle}/>
                    }
                  </div>
                </div>
                <div className='flex flex-col gap-1 pt-2 items-start'>
                  {block.items.map((item, itemIndex) => (
                    <React.Fragment>
                      {
                        edit ?
                        <div 
                          key={itemIndex} 
                          className='flex gap-1 items-center'
                        >
                          <input 
                          className="cursor-pointer" 
                          type="checkbox" 
                          id={item} 
                          name={item} 
                          value={item}
                          checked={answers.includes(item)}
                          onClick={() => updateAnswers(item, itemIndex)}
                        />
                          <Input className="text-base font-medium" valueOverride={item} onChange={(name) => updateItem(name, itemIndex, i)} />
                          <TooltipBasic message="Delete Item" className="relative">
                            <div onClick={() => removeItem(itemIndex,i)} className="cursor-pointer rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
                              <FontAwesomeIcon  className={`text-xs`} icon={faTimesCircle}/>
                            </div>
                          </TooltipBasic>
                        </div>
                        :
                        <div 
                          key={itemIndex} 
                          className='flex gap-1 items-center'
                        >
                          <input 
                            className="cursor-pointer" 
                            type="checkbox" 
                            id={item} 
                            name={item} 
                            value={item}
                            checked={answers.includes(item)}
                            onClick={() => updateAnswers(item, itemIndex)}
                          />
                          <div className="flex gap-1 items-center">
                            <div className="flex items-center gap-2">
                              <label className="cursor-pointer dark:text-bone" htmlFor={item}>
                                {item}
                              </label>
                              {(!editNote[item]) &&
                                <div className="flex text-xs gap-0.5 font-semibold items-center">
                                  <TooltipBasic message="Edit Note" className="relative">
                                    <div onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))} className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center dark:text-bone dark:bg-shadow`}>
                                      <FontAwesomeIcon icon={faNoteSticky} className="text-xs"/>
                                    </div>
                                  </TooltipBasic>
                                  {notes[item]}
                                </div>
                              }
                            </div>
                            {(editNote[item]) &&
                              <div className="relative ml-2 flex items-center">
                                <TooltipBasic message="File Note" className="absolute -left-2 top-1">
                                  <div 
                                    onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))}
                                    className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center`}
                                  >
                                    <FontAwesomeIcon icon={faNoteSticky} className="text-xs"/>
                                  </div>
                                </TooltipBasic>
                                <Input autoFocus className="text-xs font-medium border-light-grey leading-5 border pl-3.5 w-full" valueOverride={notes[item] || ''} onBlur={(note) =>  updateNote(note, item) || (note.length > 1 && setEditNote({...editNote, [item]: false}))} />
                              </div>
                            }

                          </div>
                        </div>
                      }
                    </React.Fragment>
                  ))}
                  {edit && 
                    <ButtonPrimary className="text-xs mt-3" onClick={() => addItem(block, i)}>
                      <FontAwesomeIcon className="" icon={faCirclePlus}/>
                       Item
                    </ButtonPrimary>
                  // <ButtonPrimary onClick={() => addItem(block, i)} ><FontAwesomeIcon icon={faPlusCircle}/> New Item</ButtonPrimary>
                  }
                </div>
              </div>
            ))
          }
          {edit && <ButtonPrimary className="text-xs" onClick={addBlock} ><FontAwesomeIcon icon={faCirclePlus}/> New Block</ButtonPrimary>}
        </div>
      </div>
    </div>
  )
}
