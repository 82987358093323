import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBuilding, faPenToSquare, faPhone, faEnvelope, faLocationDot, faFax, faCircleChevronUp, faChevronUp, faChevronDown, faMoneyCheckDollar, faGlobe, faCity, faEnvelopesBulk, faMagnifyingGlass, faLink} from '@fortawesome/free-solid-svg-icons';
import AddBusiness from 'components/surveyInputs/AddBusiness';
import TooltipBasic from 'components/common/TooltipBasic';
import Tooltip from 'components/common/Tooltip';

export default function Business({business, onDeselect, children, onUpdate, type, mini, firmId, specialFields}) {
  const [edit, setEdit] = useState();
  const [expand, setExpand] = useState();

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }

  return (
    edit ?
      <AddBusiness specialFields={specialFields} firmId={firmId} edit={business} onDelete={onDeselect} readOnly={business.public} type={type} close={() => setEdit(false)} onCreate={onCreate}/>
    :
    <div className={`rounded relative flex flex-col min-w-40 ${mini ? 'text-xs' : ''}`}>
        <TooltipBasic message="Edit Business" className="absolute top-1 right-6">
          <div onClick={() => setEdit(true)} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-green flex justify-center items-center">
            <FontAwesomeIcon  className={`text-xxs`} icon={business.public ? faMagnifyingGlass : faPenToSquare}/>
          </div>
        </TooltipBasic>
        {(onDeselect) && 
          <TooltipBasic message="Unselect Business" className="absolute top-1 right-1.5">
            <div onClick={onDeselect} className="cursor-pointer text-white rounded-full w-4 h-4 hover:bg-red flex justify-center items-center">
              <FontAwesomeIcon  className={`text-xs`} icon={faTimes}/>
            </div>
          </TooltipBasic>
        }
        <div className={`flex items-center gap-2 p-3 bg-med-grey dark:bg-midnight dark:border dark:border-transparent-grey ${mini ? 'rounded' : 'rounded-t'} text-white pr-12`}>
            <FontAwesomeIcon className={`text-xs relative ${business.linkedFirm ? 'text-green' : ''}`} icon={faBuilding}/>
            <h3 className="font-semibold text-white text-sm">{business.companyName}
              {
                business?.linkedFirm &&
                <Tooltip
                  icon={faLink}
                  color="green"
                  message={"This business is linked to a firm"}
                />
              }
            </h3>
        </div>
        {
          !mini &&
          <>
            <div className="flex flex-col gap-1.5 p-3 bg-white border-x border-t border-transparent-dark-grey dark:bg-shadow dark:border-transparent-grey">
              <div className="flex gap-1.5">
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faEnvelope}/> {business.email}</small>
              </div>
              <div className="flex gap-1.5">
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faLocationDot}/> {business.addressLineOne && business.addressLineOne + ', '}{business.addressLineTwo}</small>
              </div>
              <div className="flex gap-1.5">
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faPhone}/> {business.phoneNumber}</small>
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faFax}/> {business.fax}</small>
              </div>
              {children && children.length > 0 &&<div className="text-xs flex flex-col gap-4 mt-2">
                {children}
              </div>}
            </div>
            <div 
              onClick={() => setExpand(!expand)}
              className={`
                bg-light-light-grey dark:bg-shadow flex flex-col items-center justify-center border-x border-b border-transparent-dark-grey cursor-pointer rounded-b
                text-med-grey
                hover:bg-light-grey
                hover:text-dark-grey
                dark:border-transparent-grey
            `}>
              <div className={`
                transition ease-in-out ${expand ? "h-auto" : "hidden"}
                flex flex-col gap-1.5 bg-white dark:text-bone text-dark-grey border-t border-transparent-dark-grey w-full p-3
              `}>
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faCity}/> {business.city}</small>
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faGlobe}/> {business.province}</small>
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faEnvelopesBulk}/> {business.postalCode}</small>
                <small className="font-semibold text-xs"><FontAwesomeIcon className={``} icon={faMoneyCheckDollar}/> {business.gstNumber}</small>
              </div>
              <hr className="dark:border-midnight"></hr>
              <FontAwesomeIcon className={`text-xs text-transparent-dark-grey dark:text-bone`} icon={expand ? faChevronUp : faChevronDown}/>
            </div>
          </>
        }
      </div>
  )
}
