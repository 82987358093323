import React, {useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleLeft, faSquarePen, faLocationDot, faBuilding, faFileSignature, faHashtag, faSailboat, faPersonRunning, faHouse, faCommentDollar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Input, { Label, Radios } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import InviteUser from '../user/InviteUser';
import ObjectBuilder from 'components/inputs/ObjectBuilder';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';

const GET_MORTGAGE = gql`
  query GetMortgage($id: String!) {
    mortgage(id: $id) {
      bank {
        companyName
      },
      id,
      interestActStatement,
      assignmentOfRents,
      assignmentOfRentsParagraphNumber,
      assignmentOfRentsPageNumber,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      public,
      principal,
      interestRate,
      interestAdjustmentDate,
      interestCalcPeriod,
      paymentDates,
      firstPaymentDate,
      monthlyPayments,
      lastPaymentDate,
      balanceDueDate,
      termType,
      addnlOrModifiedTerms,
      priorEncumbrances,
      documents
    }
  }
`;

const DELETE_MORTGAGE = gql`
  mutation DeleteMortgage($id: String!) {
    deleteMortgage(id: $id) {
      id
    }
  }
`;

const UPDATE_MORTGAGE = gql`
  mutation UpdateMortgage(
    $id: String!,
    $interestActStatement: String, 
    $assignmentOfRents: Boolean, 
    $placeOfPayment: String, 
    $floatingChargeOnLand: Boolean, 
    $currentOrRunningAcct: Boolean, 
    $dfNumber: String, 
    $name: String, 
    $assignmentOfRentsParagraphNumber: String, 
    $assignmentOfRentsPageNumber: String,
    $principal: String,
    $interestRate: String,
    $interestAdjustmentDate: String,
    $interestCalcPeriod: String,
    $paymentDates: String,
    $firstPaymentDate: String,
    $monthlyPayments: String,
    $lastPaymentDate: String,
    $balanceDueDate: String,
    $termType: String,
    $addnlOrModifiedTerms: String,
    $priorEncumbrances: String,
    $documents: JSONObject
  ) {
    updateMortgage(
      id: $id,
      interestActStatement: $interestActStatement, 
      assignmentOfRents: $assignmentOfRents, 
      placeOfPayment: $placeOfPayment, 
      floatingChargeOnLand: $floatingChargeOnLand, 
      currentOrRunningAcct: $currentOrRunningAcct, 
      dfNumber: $dfNumber, 
      name: $name, 
      assignmentOfRentsParagraphNumber: $assignmentOfRentsParagraphNumber, 
      assignmentOfRentsPageNumber: $assignmentOfRentsPageNumber,
      principal: $principal,
      interestRate: $interestRate,
      interestAdjustmentDate: $interestAdjustmentDate,
      interestCalcPeriod: $interestCalcPeriod,
      paymentDates: $paymentDates,
      firstPaymentDate: $firstPaymentDate,
      monthlyPayments: $monthlyPayments,
      lastPaymentDate: $lastPaymentDate,
      balanceDueDate: $balanceDueDate,
      termType: $termType,
      addnlOrModifiedTerms: $addnlOrModifiedTerms,
      priorEncumbrances: $priorEncumbrances,
      documents: $documents
    ) {
      bank {
        companyName
      },
      id,
      interestActStatement,
      assignmentOfRents,
      assignmentOfRentsParagraphNumber,
      assignmentOfRentsPageNumber,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      public,
      principal,
      interestRate,
      interestAdjustmentDate,
      interestCalcPeriod,
      paymentDates,
      firstPaymentDate,
      monthlyPayments,
      lastPaymentDate,
      balanceDueDate,
      termType,
      addnlOrModifiedTerms,
      priorEncumbrances,
      documents
    }
  }
`;

export default function Mortgage() {
  const navigate = useNavigate();
  const params = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_MORTGAGE, {
    variables: { id: params.id },
  });
  const [deleteMortgageMutation, { loading: deleteLoading, error:deleteError }] = useMutation(DELETE_MORTGAGE);
  const [updateMortgageMutation, { loading: updateLoading, error:updateError }] = useMutation(UPDATE_MORTGAGE);

  const handleDelete = () => {
    setShowDelete(false);
    deleteMortgageMutation({variables: {id: params.id}})
      .then(() => navigate("/admin/mortgages"));
  }

  const handleUpdate = () => {
    setEdit(false);
    updateMortgageMutation({variables: {
      id: params.id,
      ...updateInfo
    }})
      .then(() => refetch());
  }

  const toggleEdit = () => {
    if (!edit) {
      setUpdateInfo(data.mortgage ? data.mortgage : {})
    }
    setEdit(!edit)
  }

  const valid = () => {
		return updateInfo;
	}

  return (
    data ?
    <PaddedPage bottom>
      {showDelete && <ConfirmNotice 
        title={`Are you sure you want to delete ${data.mortgage.name}?`}
        message={`To delete this mortgage type "${data.mortgage.name}" below`}
        confirmText={data.mortgage.name}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="flex flex-col gap-6 items-start">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <h1 className='dark:text-bone'>
              📝
              {edit ? <Input className="text-2xl font-medium" valueOverride={data.mortgage.name} onChange={(name) => setUpdateInfo({...updateInfo, name})} /> : data.mortgage.name}
            </h1>
            {data.mortgage.public && <div className="rounded bg-primary text-white px-3 font-bold">PUBLIC</div>}
          </div>
          <div className="flex items-center gap-2 pl-2">
            <small><FontAwesomeIcon className="" icon={faBuilding} /> {data.mortgage.bank && data.mortgage.bank.companyName}</small>
            <small  onClick={()=> setShowDelete(true)} className="hover:text-red rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faTrash} /></small>
            <small  onClick={()=> setEdit(!edit)} className="hover:text-primary -ml-2 rounded-full w-6 h-6 hover:bg-light-grey cursor-pointer flex items-center justify-center"><FontAwesomeIcon icon={faSquarePen} /></small>
          </div>
        </div>
        <div className="bg-light-grey rounded p-2 flex flex-col gap-2 dark:bg-shadow">
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Principal</strong><br></br> {edit ? <Input valueOverride={data.mortgage.principal} onChange={(principal) => setUpdateInfo({...updateInfo, principal})} /> : data.mortgage.principal}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Interest Rate</strong><br></br> {edit ? <Input valueOverride={data.mortgage.interestRate} onChange={(interestRate) => setUpdateInfo({...updateInfo, interestRate})} /> : data.mortgage.interestRate}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Interest Adjustment Date</strong><br></br> {edit ? <Input valueOverride={data.mortgage.interestAdjustmentDate} onChange={(interestAdjustmentDate) => setUpdateInfo({...updateInfo, interestAdjustmentDate})} /> : data.mortgage.interestAdjustmentDate}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Interest Calculation Period</strong><br></br> {edit ? <Input valueOverride={data.mortgage.interestCalcPeriod} onChange={(interestCalcPeriod) => setUpdateInfo({...updateInfo, interestCalcPeriod})} /> : data.mortgage.interestCalcPeriod}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Payment Date</strong><br></br> {edit ? <Input valueOverride={data.mortgage.paymentDates} onChange={(paymentDates) => setUpdateInfo({...updateInfo, paymentDates})} /> : data.mortgage.paymentDates}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> First Payment Date</strong><br></br> {edit ? <Input valueOverride={data.mortgage.firstPaymentDate} onChange={(firstPaymentDate) => setUpdateInfo({...updateInfo, firstPaymentDate})} /> : data.mortgage.firstPaymentDate}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Periodic Payment</strong><br></br> {edit ? <Input valueOverride={data.mortgage.monthlyPayments} onChange={(monthlyPayments) => setUpdateInfo({...updateInfo, monthlyPayments})} /> : data.mortgage.monthlyPayments}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faCommentDollar} /> <strong>Interest Act Statement</strong> <br></br>{edit ? <Input valueOverride={data.mortgage.interestActStatement} onChange={(interestActStatement) => setUpdateInfo({...updateInfo, interestActStatement})} /> : data.mortgage.interestActStatement}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Last Payment Date</strong><br></br> {edit ? <Input valueOverride={data.mortgage.lastPaymentDate} onChange={(lastPaymentDate) => setUpdateInfo({...updateInfo, lastPaymentDate})} /> : data.mortgage.lastPaymentDate}</p>
          <p className='dark:text-bone'>
            <FontAwesomeIcon className="" icon={faHouse} />
            <strong> Assignment of Rents</strong>
            <Radios readOnly={!edit} valueOverride={data.mortgage.assignmentOfRents} options={[{name: 'yes', value: true}, {name: 'no', value: false}]} onChange={(assignmentOfRents) => setUpdateInfo({...updateInfo, assignmentOfRents})} />
          </p>
          {(data.mortgage.assignmentOfRents || updateInfo.assignmentOfRents) &&
            <>
              <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> A/R Page #</strong><br></br> {edit ? <Input valueOverride={data.mortgage.assignmentOfRentsPageNumber} onChange={(assignmentOfRentsPageNumber) => setUpdateInfo({...updateInfo, assignmentOfRentsPageNumber})} /> : data.mortgage.assignmentOfRentsPageNumber}</p>
              <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> A/R Paragraph #</strong><br></br> {edit ? <Input valueOverride={data.mortgage.assignmentOfRentsParagraphNumber} onChange={(assignmentOfRentsParagraphNumber) => setUpdateInfo({...updateInfo, assignmentOfRentsParagraphNumber})} /> : data.mortgage.assignmentOfRentsParagraphNumber}</p>
            </>
          }
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faLocationDot} /> <strong>Place of Payment</strong><br></br> {edit ? <Input valueOverride={data.mortgage.placeOfPayment} onChange={(placeOfPayment) => setUpdateInfo({...updateInfo, placeOfPayment})} /> : data.mortgage.placeOfPayment}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Balance Due Date</strong><br></br> {edit ? <Input valueOverride={data.mortgage.balanceDueDate} onChange={(balanceDueDate) => setUpdateInfo({...updateInfo, balanceDueDate})} /> : data.mortgage.balanceDueDate}</p>
          <p className='dark:text-bone'>
            <FontAwesomeIcon className="" icon={faSailboat} /> 
            <strong> Floating Charge on Land</strong>
            <Radios readOnly={!edit} valueOverride={data.mortgage.floatingChargeOnLand} options={[{name: 'yes', value: true}, {name: 'no', value: false}]} onChange={(floatingChargeOnLand) => setUpdateInfo({...updateInfo, floatingChargeOnLand})} />
          </p>
          <p className='dark:text-bone'>
            <FontAwesomeIcon className="" icon={faPersonRunning} /> 
            <strong> Current or Running Account</strong>
            <Radios readOnly={!edit} valueOverride={data.mortgage.currentOrRunningAcct} options={[{name: 'yes', value: true}, {name: 'no', value: false}]} onChange={(currentOrRunningAcct) => setUpdateInfo({...updateInfo, currentOrRunningAcct})} />
          </p>
          <p className='dark:text-bone'>
            <FontAwesomeIcon className="" icon={faHouse} />
            <strong> Term Type</strong>
            <Radios 
              readOnly={!edit} 
              valueOverride={data.mortgage.termType} 
              options={[ 
                {name: 'Prescribed Standard Mortgage Terms', value: 'PresStdMortgageTerm'},
                {name: 'Filed Standard Mortgage Terms', value: 'FiledStdMortgageTerm'},
                {name: 'Express Mortgage Terms', value: 'ExpressMortgageTerm'},
              ]} 
              onChange={(termType) => setUpdateInfo({...updateInfo, termType})} 
            />
          </p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> dfNumber</strong><br></br> {edit ? <Input valueOverride={data.mortgage.dfNumber} onChange={(dfNumber) => setUpdateInfo({...updateInfo, dfNumber})} /> : data.mortgage.dfNumber}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Additional Or Modified Terms</strong><br></br> {edit ? <Input type="textarea" valueOverride={data.mortgage.addnlOrModifiedTerms} onChange={(addnlOrModifiedTerms) => setUpdateInfo({...updateInfo, addnlOrModifiedTerms})} /> : data.mortgage.addnlOrModifiedTerms}</p>
          <p className='dark:text-bone'><FontAwesomeIcon className="" icon={faHashtag} /><strong> Prior Encumbrances</strong><br></br> {edit ? <Input type="textarea" valueOverride={data.mortgage.priorEncumbrances} onChange={(priorEncumbrances) => setUpdateInfo({...updateInfo, priorEncumbrances})} /> : data.mortgage.priorEncumbrances}</p>
          <div className="flex flex-col">
              <Label text="Documents"/>
              <ObjectBuilder 
                readOnly={!edit}
                value={edit ? updateInfo.documents : data.mortgage.documents} 
                onChange={(documents) => setUpdateInfo({...updateInfo, documents})} 
                keyName="Document Name"
                valueName="Document Link"
              />
          </div>
        </div>
        {
          edit && <ButtonPrimary disabled={updateLoading || deleteLoading || !valid()} onClick={handleUpdate}>Update</ButtonPrimary>
        }
      </div>
    </PaddedPage>
    :
    <></>
  )
}
