import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import AppContext from 'contexts/AppContext';

const NotificationBanner = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  z-index: 20;
`;

const Footer = ({ childRef }) => {
  const {currentUser} = useContext(AppContext);
  return (
    <div 
      className="
        bg-light-light-grey dark:bg-shadow
        mt-auto
        sm:p-6 
        md:px-12 
        md:py-6 
        lg:px-16 
        lg:py-8 
        xl:px-24 
        xl:py-12
        z-20
        "
      ref={childRef}
    >
      <div className="2xl:mx-auto 2xl:max-w-8xl">
        <div className="flex sm:flex-col md:flex-row-reverse">
              <div className="flex-auto text-right my-3">
                <a className="text-xl ml-3" href="https://ca.linkedin.com/company/conveyme-software-inc"><FontAwesomeIcon icon={faLinkedin} /></a>
              </div>
        </div>
        <hr className="border-t border-gray-400" />
        <div className="flex sm:flex-col justify-between">
            <div className="flex py-2 gap-3 text-xs flex-col">
              <div className="flex flex-col">
                <Link to="/faqs">FAQs</Link>
                {currentUser && currentUser.firmAdmin && <Link to="/products">File Bundles</Link>}
                {currentUser && <Link to="/tools">Stewart Title Resources</Link>}
                <a href="mailto:support@conveyme.ca"><span className="text-xs">Contact Us</span></a>
                <p>support@conveyme.ca</p>
              </div>
              <p className="">
                <u className="font-medium">Support Hours</u><br></br>
                Mon-Fri: 8:30am - 5:30pm<br></br>
                Sat: Check in times at 10:00am and 2:00pm<br></br>
                Closed Holidays
              </p>
            </div>
            <div>
              <div className="my-2 text-xs">
                &copy; 2022 ConveyMe Software Inc.
              </div>
              <div className="text-xs">
                <Link to={'/terms-and-conditions'}><span className="">TERMS AND CONDITIONS</span></Link>
                <span> | </span>
                <Link to={'/privacy'}><span className="">PRIVACY POLICY</span></Link>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
