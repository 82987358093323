import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import TableContext from 'contexts/TableContext';
import ParentContext from 'contexts/ParentContext';
import { getCalculatedValue, pathMaker } from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import { useLocation } from 'react-router-dom';

export default function Table({
  title,
  hideLabel,
  hideTotal,
  skipPath,
  same,
  hidden,
  id,
  collapsible,
  calculatedLabel,
  subtle,
  formStyles,
  description,
  questionKey,
  children
}) {
  // pr-8
  const [bottomPadded, setBottomPadded] = useState();
  const {row, prevRows, parentPath, subscribeCleanup} = useContext(ParentContext);
  const { file } = useContext(SurveyContext);
  const { hash } = useLocation();
  const titleRef = useRef();
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const arrayOfChildredn = useMemo(() => Array.isArray(children) ? children : [children], [children]);
  const calculatedLabelValue = useMemo(() => {
    return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  useEffect(() => {
    if (id) {
      console.log("test", id, hash);
    }
    if (id && (hash.replace('#', '') == id.replaceAll(' ', ''))) {
      titleRef?.current?.scrollIntoView({block: 'center'});
    }
  }, [hash]);

  // -mr-2
  return (
    <TableContext.Provider value={{setBottomPadded}}>
      {
        subtle ?
          <>
            {arrayOfChildredn.map((child, i) => 
              <ParentContext.Provider key={i} value={{subscribeCleanup, parentPath: skipPath ? path.substr(0, path.lastIndexOf(".")) : path, row: (skipPath || !same) ? undefined : i ,prevRows: (skipPath || !same) ? prevRows : [...prevRows, i] }}>
                {child}
              </ParentContext.Provider>  
            )}
          </>
        :
          <div id={id} ref={titleRef} className={`survey-table-parent flex flex-col-reverse gap-2 my-2 ${formStyles && formStyles.includes("bg-") ? '' : 'bg-transparent-grey dark:bg-transparent-bone'} border border-transparent-dark-grey rounded p-3 ${(bottomPadded && !hideTotal && !formStyles?.includes('pb')) ? 'pb-18' : 'pb-2'}  ${hideTotal ? 'hide-total' : ''} w-full ${formStyles} ${hidden ? 'hidden' : ''}`}>
            <div className={`flex flex-col gap-2 w-full survey-table`}>
              {arrayOfChildredn.map((child, i) => 
                <ParentContext.Provider key={i} value={{subscribeCleanup, parentPath: skipPath ? path.substr(0, path.lastIndexOf(".")) : path, row: (skipPath || !same) ? undefined : i ,prevRows: (skipPath || !same) ? prevRows : [...prevRows, i] }}>
                  {child}
                </ParentContext.Provider>  
              )}
            </div>
            {!hideLabel && 
              <>
                {description && <small>{description}</small>}
                {(title || calculatedLabelValue) && <h2 className='dark:text-bone'>{calculatedLabelValue || title} {(row !== undefined && row !== null)? row + 1 : ''}</h2>}
              </>
            }
          </div>
      }
    </TableContext.Provider>
  )
}
