import { gql, useQuery } from '@apollo/client';
import Loading from 'components/common/Loading';
import Search from 'components/common/Search'
import MiniFileItem from 'components/survey/MiniFileItem';
import React, { useEffect, useState } from 'react'

const GET_FILES = gql`
  query GetFiles($filters: JSONObject, $pageId: String) {
    files(filters: $filters, pageId: $pageId) {
      id,
      date,
      surveyId,
      status,
      icon,
      nickname,
      fileNumber,
      completionDate,
      searchMatch
    }
  }
`;

export default function FileSearch({className}) {
  const [filters, setFilters] = useState({orderBy: ""});
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!filters.search && files.length > 0) {
      setFiles([]);
    }
  },[filters]);

  const { loading, error, data } = useQuery(GET_FILES, {
    variables: {filters},
    fetchPolicy:'no-cache',
    skip: !filters.search,
    onCompleted (data) {
      setFiles(data.files);
    }
  });

  const clear = () => {
    setFiles([]);
    setFilters({orderBy: ""});
  }

  return (
    <div className="">
      <Search 
        filters={filters}
        setFilters={setFilters}
        className={className}
        useOverride={true}
        valueOverride={filters.search}
      />
      {
        filters.search &&
        <div onClick={clear} className="w-screen h-screen flex flex-col items-center absolute left-0 backdrop-brightness-75 top-12">
          <div className="rounded mt-2 bg-dark-grey p-2 flex grow-0 flex-col">
            {
              loading ?
                <div className="md:min-w-24 h-8 text-white flex items-center justify-center">
                  <Loading/>
                </div>
              : files.length > 0 ?
                files.map((file, i) => (
                  <MiniFileItem key={i} file={file} search={filters.search}/>
                ))
              :
                <small className="md:min-w-24 h-8 flex items-center justify-center font-semibold text-med-grey">No Files</small>
            }
          </div>
        </div>
      }
    </div>
  )
}
