import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import React, { useState } from 'react'
import Input from './Input';

export default function ObjectBuilder({value, onChange, keyName, valueName, readOnly}) {
  const [add, setAdd] = useState();
  const entries = value ? Object.entries(value) : [];

  const saveValue = () => {
    if (add && add.key &&  add.value) {
      onChange({...value, [add.key]: add.value});
      setAdd();
    }
  }

  const remove = (key) => {
    let newValue = {...value};
    delete newValue[key];
    onChange(newValue);
  }

  return (
    <div>
      <div className="p-1 flex gap-1 flex-wrap">
        {entries.map(([key, value]) => <div className="rounded px-2 bg-primary font-bold dark:text-shadow">
          {key}: {value}&nbsp;
          {!readOnly && <FontAwesomeIcon onClick={() => remove(key)} className="text-white cursor-pointer" icon={faTimes}/>}
        </div>)}
        {entries.length == 0 && <span>none</span>}
      </div>
      {!readOnly && <ButtonPrimary onClick={() => setAdd({})}>Add</ButtonPrimary>}
      {
        add &&
        <div className="flex gap-2">
          <div className="flex flex-col text-sm">
            <label>{keyName}</label>
            <Input valueOverride={add.key} onChange={(key) => setAdd({...add, key})} onBlur={saveValue} />
          </div>
          <div className="flex flex-col text-sm">
            <label>{valueName}</label>
            <Input valueOverride={add.value} onChange={(value) => setAdd({...add, value})} onBlur={saveValue} />
          </div>
        </div>
      }
    </div>
  )
}
