import React from 'react'

export default function LoadingBlock({color, random, className}) {

  return (
    <div className={`inline-block animate-pulse rounded ${color ? `bg-${color} text-${color} opacity-10` : 'h-40 min-w-40 bg-light-light-grey dark:bg-shadow text-light-light-grey'} ` + className}>
      { random ? "-----".repeat(Math.random()*10 + 1) : "-- ---- ----"}
    </div>
  )
}
