import React, {useContext} from 'react';
import { dayOfWeekFromOffset, getFirstDayOfWeek } from 'helpers/calendar';
import WeekDay from './WeekDay';
import CalendarContext from 'contexts/CalendarContext';

export default function CalendarWeek() {
	const {date, setDate} = useContext(CalendarContext);

	const firstDayofWeek = getFirstDayOfWeek(date);
  	const getDays = () => {
		let days = [];
		for (let x = 0; x < 7; x++) {
			days.push(<WeekDay key={x} dayOfWeek={x} selectedDay={date.getDate()} currentDate={dayOfWeekFromOffset(firstDayofWeek, x)} />);
		}
		return days;
	}
	const dayName = new Intl.DateTimeFormat('en-Us', { weekday: 'long' }).format(new Date(date));

  return (                               
    <div className="flex flex-col gap-6 pb-4">
			<p className="text-center dark:text-bone"><strong>{dayName} {date.getDate()}</strong></p>

			<div className="flex rounded shadow-md">
				{getDays()}
			</div>
    </div>
  )
}
