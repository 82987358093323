import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from "@apollo/client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import MortgageLineItem from './MortgageLineItem';
import ActivePageContext from 'contexts/ActivePageContext';
import Search from 'components/common/Search';
import { FullWidthPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';


const GET_MORTGAGES = gql`
  query GetMortgages($filters: JSONObject, $pageId: String, $asAdmin: Boolean) {
    mortgages(filters: $filters, pageId: $pageId, asAdmin: $asAdmin) {
      bank {
        companyName
      },
      id,
      interestActStatement,
      assignmentOfRents,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      public,
      firm {
        name
      },
      user {
        username
      }
    }
  }
`;

export default function Mortgages() {
  const {setActivePage} = useContext(ActivePageContext);
  const [filters, setFilters] = useState({strictSearch: true});
  const [pageId, setPageId] = useState();
  const [mortgages, setMortgages] = useState([]);
  useEffect(() => {
    setMortgages([]);
    setPageId();
  },[filters]);

  const { loading, error, data } = useQuery(GET_MORTGAGES, {
    fetchPolicy:'no-cahce',
    variables: {filters, pageId, asAdmin: true},
    onCompleted (data) {
      setMortgages((mortgages) => ([...mortgages, ...data.mortgages]));
    }
  });

  setActivePage('mortgage');

  const loadMore = () => {
    if (!loading) {
      setPageId(mortgages.at(-1).id);
    }
  }

  return (
    <FullWidthPage bottom className="pt-4 flex flex-col gap-2"> 
      <div className="flex gap-2 items-center">
        <h2 className="dark:text-bone">Mortgages <Link to="new"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link></h2>
        <Search filters={filters} setFilters={setFilters}/>
      </div>
      <div className="flex flex-col gap-1">
        {mortgages.map(mortgage => <Link className="no-underline" key={mortgage.id} to={`/admin/mortgages/${mortgage.id}`} ><MortgageLineItem mortgage={mortgage}/></Link>)}
        {loading &&
          <Loading/>
        }
        {
          data && data.mortgages.length > 9 &&
          <div className="text-med-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {mortgages.length === 0 && <small className="text-med-grey">No Mortgages</small> }
      </div>
    </FullWidthPage>
  )
}
