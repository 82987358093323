import React, {useMemo, useState} from 'react';
import CalendarDay from './CalendarDay';
import CalendarMonth from './CalendarMonth';
import CalendarWeek from './CalendarWeek';
import CalendarContext from 'contexts/CalendarContext';
import { addMonths, monthsBetween } from 'helpers/calendar';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import CalendarFilters from './CalendarFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const testEventsForMonth = [
  {
    date: new Date(),
    type: 'Closing',
    icon: '🎏',
    name: "Dave's House closing"
  },
  {
    date: new Date(),
    type: 'Task',
    icon: '🎉',
    name: "Bring Forward Item"
  },
  {
    date: new Date(),
    type: 'Task',
    icon: '💎',
    name: "Bring Forward Item Two"
  },
]

const GET_CALENDAR_ITEMS = gql`
  query GetCalendarItems($firmId: String, $filters: JSONObject) {
    calendarItems(firmId: $firmId, filters: $filters) {
      id,
      date,
      closing,
      type,
      name,
      subText,
      icon,
      colorOne,
      colorTwo,
      percentage,
      link,
      fileId,
      userId,
      user {
        color,
        username
      }
      surveyId,
      tag
    }
  }
`;

const getFirstLast = (date) => {
  return {
    from: new Date(date.getFullYear(), date.getMonth(), 1),
    to: new Date(date.getFullYear(), date.getMonth() + 1, 0)
  }
}


const CalendarModal = ({max, min, filtersOpen, showTitle, large}) => {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({});
  const [subFilters, setSubFilters] = useState({fileType: ""});
  const [showFilters, setShowFilters] = useState(filtersOpen);
  const {firmChoices, firmId, setFirmId, settings} = useFirmChoices(false, false, false, false, true);
  const { loading, error, data, refetch } = useQuery(GET_CALENDAR_ITEMS, {
    variables: {firmId, filters},
    fetchPolicy:'no-cache',
    skip: !filters.to,
    onCompleted(res){
      setEvents(res.calendarItems);
    }
  });

  const dateMonth = useMemo(() => {
    return `${date.getFullYear()}-${date.getMonth()}`
  }, [date])

  useEffect(() => {
    setEvents([]);
    setFilters({...filters, ...getFirstLast(date)})
  }, [dateMonth]);

	const handleSetDate = (val) => {
		setDate(val);
	}

  const addEvent = (event) => {
    setEvents([...events, event]);
  }

  
  const removeEvent = (id) => {
    setEvents(events.filter(event => event.id !== id));
  }

  const filteredEvents = useMemo(() => {
    return events.filter(event => {
      let valid = true;
      if (subFilters.search && (!event.tag.toLowerCase().includes(subFilters.search) && !event.name.toLowerCase().includes(subFilters.search) && !event.subText.toLowerCase().includes(subFilters.search))) {
        return false;
      }
      if (subFilters.fileType && subFilters.fileType !== event.surveyId){
        return false;
      }
      if (subFilters.type && subFilters.type !== event.type){
        return false;
      }
      if (subFilters.user && subFilters.user !== event.userId){
        return false;
      }
      return valid;
    })
  }, [subFilters, events])

  const closingsCount = useMemo(() => {
    let closings = {};
    for (let event of filteredEvents) {
      let closing = event.closing && new Date(event.closing + 'T00:00');
      if ((new Date(filters.to) >= closing) && (new Date(filters.from) <= closing)) {
        closings[event.fileId] = true;
      }
    }
    return Object.keys(closings).length;
  }, [filteredEvents]);

  const nextMonth = useMemo(() => {
    let newMonth = addMonths(1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min]);

  const prevMonth = useMemo(() => {
    let newMonth = addMonths(-1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min])

  return (
		<CalendarContext.Provider value={{
      date, 
      setDate: handleSetDate, 
      monthEvents: filteredEvents,
      firmId,
      prevMonth,
      nextMonth,
      refetch,
      colorMode: settings?.calendar?.colors,
      addEvent,
      removeEvent
    }}>
			<div>
        <div className="flex gap-2 items-center mb-2">
          {showTitle ? (large ? <h1 className="pb-1 dark:text-bone">📅Calendar</h1> : <h2 className="dark:text-bone">📅Calendar</h2>) : ''}
          {
            firmChoices.length > 1 && <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={firmChoices}/>
          }
          <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
        </div>
        {
          showFilters && <CalendarFilters filters={subFilters} setFilters={setSubFilters} closingsCount={closingsCount}/>
        }
				<hr className="mt-2 dark:border-bone"></hr>
				<div className="flex gap-4 py-4 flex-col md:flex-row">
            <div className="md:w-1/2 flex flex-col gap-6">
							<CalendarWeek/>
						  <CalendarDay/>
            </div>
						<div className="flex flex-col gap-2 grow">
							<CalendarMonth/>
						</div>
				</div>
			</div>
		</CalendarContext.Provider>
  )
}

export default CalendarModal;
