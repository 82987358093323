import { gql, useQuery } from '@apollo/client';
import { List } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import CalendarContext from 'contexts/CalendarContext';
import React, { useContext } from 'react';

const GET_SURVEYS = gql`
  query GetSurveys {
    surveys {
      name,
      id,
      linkId
    }
  }
`;


const GET_USERS = gql`
  query GetUsers($firmId: String, $filters: JSONObject, $pageId: String) {
    users(firmId: $firmId, filters: $filters, pageId: $pageId) {
      id,
      firstName,
      lastName
    }
  }
`;


export default function CalendarFilters({filters, setFilters, closingsCount}) {
  const {firmId} = useContext(CalendarContext);
  const { currentUser } = useContext(AppContext);
  const { error, data } = useQuery(GET_SURVEYS);
  const { loading, data:usersData } = useQuery(GET_USERS, {
    variables: {firmId},
    fetchPolicy: 'no-cache',
    skip: !firmId || (!currentUser.firmAdmin && !["admin", "super-admin"].includes(currentUser.type))
  });
  
  const userOptions = usersData && [{name: '', value: ''}, ...usersData.users.map(user => ({name: `${user.firstName} ${user.lastName}`, value: user.id}))];
  return (
    <div className="border flex flex-col md:flex-row p-2 gap-4 bg-light-light-grey dark:bg-shadow border-transparent-dark-grey">
      <div> 
        <label className="dark:text-bone">Search</label>
        <hr></hr>
        <div className="flex gap-2">
          <input className="border px-2" onChange={(e) => setFilters({...filters, search: e.target.value})} value={filters.search}/>
        </div>
      </div>
      <div> 
        <label className="dark:text-bone">File Type</label>
        <hr></hr>
        <div className="flex flex-col md:flex-row gap-2">
          <div>
            <input className="cursor-pointer" checked={filters.fileType === ""} onClick={() => setFilters({...filters, fileType: ""})} type="radio" id="all" name="subscribe" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="all">All</label>
          </div>
          {data && data.surveys && 
            data.surveys.map(survey => 
            <div>
              <input className="cursor-pointer" checked={filters.fileType === survey.id} onClick={() => setFilters({...filters, fileType: survey.id})} type="radio" id={survey.name} name={survey.name} value={survey.id}/>
              <label className="font-normal cursor-pointer dark:text-bone" htmlFor={survey.name}>{survey.name}</label> 
            </div>
            ) 
          }
        </div>
      </div>
      <div> 
        <label className="dark:text-bone">Type</label>
        <hr className="dark:border-bone"></hr>
        <div className="flex gap-2">
          <List onChange={(type) => setFilters({...filters, type})} className="py-0.5" options={[
                {name: "All", value: ""},
                {name: "Other", value: "Other"},
                {name: "Checklist", value: "Checklist"},
                {name: "Builder's Lien Holdback", value: "Builder's Lien Holdback"},
                {name: "Court Ordered Sale", value: "Court Ordered Sale"},
                {name: "D/M Particulars", value: "D/M Particulars"},
                {name: "Discharge of Mortgage", value: "Discharge of Mortgage"},
                {name: "Draw Mortgage", value: "Draw Mortgage"},
                {name: "Meeting", value: "Meeting"},
                {name: "Holdback", value: "Holdback"},
                {name: "NHWC to be sent to bank", value: "NHWC to be sent to bank"},
                {name: "Property Taxes", value: "Property Taxes"},
                {name: "STC", value: "STC"},
                {name: "Transfer", value: "Transfer"},
                {name: "TSJT", value: "TSJT"},
                {name: "T2E", value: "T2E"},
                {name: "X-LOTA", value: "X-LOTA"},
          ]}/>        
        </div>
      </div>
      {
        usersData &&
        <div> 
          <label className="">User</label>
          <hr></hr>
          <div className="flex gap-2">
            {data && data.surveys && 
              <List options={userOptions} valueOverride={filters.user} onChange={(user) => setFilters({...filters, user})}/>
            }
          </div>
        </div>
      }
      <div className='ml-auto self-center mr-4'>
        <label className=" font-semibold dark:text-bone">📑Closings <span className="text-primary">{closingsCount}</span></label>
      </div> 
    </div>
  )
}
