import React, {useContext, useState} from 'react'
import { useQuery, useMutation, gql, applyNextFetchPolicy } from "@apollo/client";
import Badge from 'components/common/Badge';
import FileItem from './FileItem';
import { List } from 'components/inputs';
import useFirmChoices from 'hooks/useFirmChoices';
import LoadingItem from 'components/loading/LoadingItem';

const GET_FILES = gql`
  query GetReccentFiles($firmId: String, $userId: String) {
    files(firmId: $firmId, userId: $userId) {
      id,
      surveyId,
      date,
      status,
      icon,
      nickname,
      fileNumber,
      completionDate
    }
  }
`;

export default function ReccentSurveyFiles({userId, admin}) {
  const {firmChoices, firmId, setFirmId} = useFirmChoices(admin);
  const { loading, error, data } = useQuery(GET_FILES, {
    variables: {firmId, userId},
    fetchPolicy:'no-cache'
  });
  
  return (
    <div>
      <div className="flex gap-2 items-end">
        <h2 className="dark:text-bone">📂Recent Files</h2>
        {
          firmChoices.length > 1 && <List onChange={setFirmId} valueOverride={firmId} className="text-xs py-0.5" options={firmChoices}/>
        }
      </div>
      <hr className="mt-2"></hr>
      <div className="flex flex-wrap gap-2 py-4 w-full">
        {
          loading ?
            <>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
            </>
          :
          <>
            {data && data.files.map(file => (
              <FileItem key={file.id} file={file} />
              
            ))}
            {
              data && data.files.length < 1 &&
              <small className="text-med-grey">No Files</small>
            }
          </>
        }
      </div>
    </div>
  )
}
