import React, {useContext, createContext, useState} from 'react';
import { Link } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import { FullWidthPage } from 'components/common/Layout';

export default function AdminNav({children}) {
  const [activePage, setActivePage] = useState();
  const {currentUser} = useContext(AppContext); 
  return (
    <ActivePageContext.Provider value={{setActivePage}}>
      {children}
    </ActivePageContext.Provider>
  )
}
