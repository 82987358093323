import React, {useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'components/surveyInputs';

const NEW_FIRM = gql`
  mutation newFirm($name: String!, $subdomain: String!, $email: String!) {
    newFirm(name: $name, subdomain: $subdomain, email: $email) {
      id,
	  name,
	  subdomain,
	  email
    }
  }
`;

export default function CreateFirm() {
  	const [file, setFile] = useState();
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [subdomain, setSubdomain] = useState();
	const [email, setEmail] = useState();
	const [newFirmMutation, { loading, data }] = useMutation(NEW_FIRM);
	if (data && data.newFirm) {
		navigate(`/admin/firms/${data.newFirm.id}`);
	}

	const onSubmit = () => {
		newFirmMutation({
			variables: {
				name,
				subdomain,
				email
			}
		})
	}

  return (
    <PaddedPage bottom>
      <div className="flex flex-col gap-2 items-start">
        <Table title="⚖️Create Firm">
          <div className="flex flex-col items-start gap-2"> 
            <label>Name</label>
            <Input onChange={setName}/>
            <label>Subdomain</label>
            <Input onChange={setSubdomain}/>
            <label>Email</label>
            <Input onChange={setEmail}/>
            <ButtonPrimary className="mt-4" onClick={onSubmit}> 
              Create!
            </ButtonPrimary> 
          </div> 
        </Table>
      </div>
    </PaddedPage>
  )
}
