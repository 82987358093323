import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import Calendar from 'components/calendar';

const CalendarPage = () => {
  return (
    <PaddedPage noBottom>
      <Calendar large={true} filtersOpen={true}/>
    </PaddedPage>
  );
};

export default CalendarPage;
