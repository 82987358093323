import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage } from 'components/common/Layout'
import { List, Radios } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';
import useFirmChoices from 'hooks/useFirmChoices';
import React, { useContext, useState } from 'react'

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month > 9 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function PaymentsNav({children}) {
  const [from, setFrom] = useState(startAndEndOfMonth()[0]);
  const [to, setTo] = useState(startAndEndOfMonth()[1]);
  const [exportCSV, setExportCSV] = useState();
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true);
  const [filters, setFilters] = useState({});
  const {setActivePage} = useContext(ActivePageContext);
  setActivePage("payments")
  // const [activePage, setActivePage] = useState("");
  return (
    <FullWidthPage top bottom>
      <div className="flex flex-col gap-4">
        {/* <div className="flex gap-4">
          <Link className={`pb-3 no-underline ${activePage === '' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account`}>
          Trust Account
          </Link>
        </div> */}
        <div className="flex justify-between border rounded p-2 border-med-grey items-center">
          <div className="flex gap-2 items-center">
            <div className="flex flex-col">
              <label className="text-sm dark:text-bone">Firm</label>
              <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={[...firmChoices, {name: "Mine", value: ""}]}/>
            </div>
            <div className="flex flex-col">
              <label className="text-sm dark:text-bone">Filter By</label>
              <Radios inline options={[
                {name: "Closing Date", value: ""}, 
                {name: "Created Date", value: 'created'},
                {name: "Payment Date", value: 'paid'}
              ]} valueOverride={filters.filterByCreated} onChange={(filterByCreated) => setFilters({...filters, filterByCreated})} />
            </div>
            <div className="flex flex-col">
              <label className="text-sm dark:text-bone">Month</label>
              <div className="flex gap-2 items-center">
                <input type="date" value={from} onChange={(e) => setFrom(e.target.value)}/>
                <FontAwesomeIcon icon={faArrowRight}/>
                <input type="date" value={to} onChange={(e) => setTo(e.target.value)}/>
              </div>
            </div>
          </div>
          {exportCSV && <ButtonPrimary onClick={() => exportCSV.call()} className="justify-self-end text-sm border-med-grey`">Export to CSV</ButtonPrimary>}
        </div>
        <div>
          <ActivePageContext.Provider value={{firmId, from, to, filters, setExportCSV}}>
            {children}
          </ActivePageContext.Provider>
        </div>
      </div>
    </FullWidthPage>
  )
}
