import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Checkboxes from 'components/inputs/Checkboxes';
import ParentContext from 'contexts/ParentContext';
import { softSubset } from 'helpers/general';

const SurveyCheckboxes = ({ 
    type, 
    questionKey, 
    label, 
    options,
    hideLabel, 
    total, 
    query,
    required,
    collapsible,
    isReadOnly,
    defaultValue,
    inputSymbol,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
    const { row, parentPath }= useContext(ParentContext);
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    const [error, setError ] = useState();

    const surveyValue = useMemo(() => {
        let value = file && getSurveyValue(file.answers, path);
        if (!value && defaultValue && value !== 0) {
            updateValue(defaultValue);
        }
        return value;
    }, [file, path]);

    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    const updateValue = (val) => {
        if (!checkError(val)) {
            let newVal = val.filter(value => options?.find?.(option =>  typeof value === 'object' ? softSubset(value, option.value) : value == option.value));
            updateAnswers(path, newVal);
        }
    }

    return (
        <div>
            {!hideLabel && label && (
                description ? 
                    <label className='dark:text-bone'>
                        {label}
                        <Tooltip message={description} />
                    </label>
                :
                    <label className='dark:text-bone'>
                        {label}
                    </label>
            )}
            <Checkboxes className={formStyles} readOnly={isReadOnly} options={options} valueOverride={surveyValue || []} onChange={(val) => updateValue(val)} />
            <p>{error}</p>
        </div>
    )
};

export default SurveyCheckboxes;