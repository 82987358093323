import React, {useContext, useEffect, useState, useMemo, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import fctLogo from "assets/images/survey/fctLogo.png";
import ParentContext from 'contexts/ParentContext';
import Transparent from 'components/notices/Transparent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import useSurveyErrors from 'hooks/useSurveyErrors';
import { validFCTInputs, validLTSAInputs } from 'helpers/document';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { softSubset } from 'helpers/general';

const GET_FCT_INSURANCE = gql`
  mutation GetFCTTitleInsurance($tokenId: String!, $fileId: String!, $surveyId: String!, $updateData: Boolean) {
    getFCTTitleInsurance(tokenId: $tokenId, fileId: $fileId,  surveyId: $surveyId, updateData: $updateData) {
      URL
    }
  }
`;

const GET_FCT_STATUS = gql`
  mutation GetFCTTitleInsuranceStatus($tokenId: String!, $fileId: String!, $surveyId: String!) {
    getFCTTitleInsuranceStatus(tokenId: $tokenId, fileId: $fileId, surveyId: $surveyId) {
      status,
      statusDisplayName
    }
  }
`;

let requiredFields = (type) => {
  if (type === "Refinance") {
    return ["answers.pid", "answers.closingDate", "answers.newPurchaseAddress", "answers.addressDetails.newPurchaseCity", "answers.titleInsuranceMortgageAmount && answers.lender", "answers.clientIs !== 'borrower' ? answers.ourFirm : answers.otherPartiesSupervisingLawyer", "['Multi Family Units','Rooming House'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true",
    "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0"
    ];
  }
  return ["answers.pid", "answers.purchasePriceTotal", "answers.closingDate", "answers.newPurchaseAddress", "answers.addressDetails.newPurchaseCity", "(answers.lender || answers.hasMortgage) ? (answers.titleInsuranceMortgageAmount && answers.lender) : true", "['Multi Family Units','Rooming House'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true",
  "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0"
  ];
} 
// sellelr birthdate
// if other rep lender then otherparties soli and supervising
const FCTTitleInsurcneInput = ({ 
  questionKey, 
  tokenIdPath
}) => {
    const { file, pages, fileId, surveyId, updateAnswers } = useContext(SurveyContext);
    const [getFCTTitleInsurance, { loading, error }] = useMutation(GET_FCT_INSURANCE);
    const [getFCTStatus, { loading: createLoading, error: createError }] = useMutation(GET_FCT_STATUS);
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const {errorMessages} = useSurveyErrors(pages, file);
    const [updateNotice, setUpdateNotice] = useState();
    const iframeRef = useRef();
    const [updateFlag, setUpdateFlag] = useState();
    const [iframeURL, setIframeUrl] = useState();
    const [showWarining, setShowWarning] = useState();

    const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    const tokenId = useMemo(() => {
      let value = file && getSurveyValue(file.answers, tokenIdPath);
      return value;
    }, [file, tokenIdPath]);

    const surveyValue = useMemo(() => {
      let value = file && getSurveyValue(file.answers, path);
      return value;
    }, [file, path]);

    const dataDependencies = useMemo(() => {
      if (file) {
        let deps = validFCTInputs(file, requiredFields(file.survey?.name));
        if (surveyValue?.status && surveyValue?.data && ['completed', 'in-progress', 'pending processing', 'pending update', 'draft', 'cancelled', 'in underwriting'].includes(surveyValue?.status?.toLowerCase())) {
          if (!softSubset(surveyValue.data, deps.data)) {
            setUpdateFlag(true);
          }
        }
        return deps;
      }
    }, [file, surveyValue]);

    const errors = useMemo(() => {
      if (dataDependencies) {
        let customErrorMessages = errorMessages ? {
          ...errorMessages, 
          "(answers.lender || answers.hasMortgage) ? (answers.titleInsuranceMortgageAmount && answers.lender) : true": 'Missing mortgage information',
          "answers.titleInsuranceMortgageAmount && answers.lender": 'Missing mortgage information',
          "answers.clientIs !== 'borrower' ? answers.ourFirm : answers.otherPartiesSupervisingLawyer": 'Missing Lender Solicitor',
          "['Multi Family Units','Rooming House'].includes(answers.propertyType) ? (answers.numberOfUnits >=2 && answers.numberOfUnits <= 6) : true" : "Number of units is not within valid range (2 - 6)",
          "[...(file.answers.buyerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.buyers || []),...(group.corporateBuyers || [])]).sort(buyer => buyer.useAddress ? -1 : 0)], []), ...(file.answers.borrowerGroups || []).reduce((prev,{group}) => [...prev, ...([...(group.borrowers || []),...(group.corporateBorrowers || [])])], [])].filter(owner => (!owner.addressLineOne && owner.province) || (owner.addressLineOne && !owner.province)).length == 0": "Owner address info must be empty or include province and street address."
        } : undefined;
        return dataDependencies?.errors?.length > 0 ? (customErrorMessages ? dataDependencies?.errors?.map(error => customErrorMessages[error] || error) : dataDependencies?.errors) : false;
      }
    }, [dataDependencies]);

    const submit = (updateData) => {
      setUpdateNotice(false);
      getFCTTitleInsurance({
        variables: {fileId, surveyId, tokenId, updateData: updateData || false}
      })
      .then(res => {
        updateAnswers(path, {status: dataDependencies.status || 'in-progress', data: dataDependencies?.data});
        setUpdateFlag(false);
        setIframeUrl(res.data.getFCTTitleInsurance?.URL);
      });
    }

    const onReturn = () => {
      setIframeUrl();
      setShowWarning(false);
      if (surveyValue) {
        getFCTStatus({
          variables: {fileId, surveyId, tokenId}
        })
        .then(res => {
          updateAnswers(path + '.status', res.data.getFCTTitleInsuranceStatus.status);
        })
      }
    }

    useEffect(() => {
      window.onmessage = (event) => {
        if (event.data === 'dirty') {
          setShowWarning(true);
        } else if (event.data === 'untouched') {
          onReturn();
        }
      }
    }, [onReturn]);
    
    const onClose = () => {
      iframeRef.current.contentWindow.postMessage('OK', iframeURL); 
      console.log("onClose");
      // setShowWarning(true);
    }

    return (
      <>
        {updateNotice && <ConfirmNotice
          title={`Are you sure?`}
          message={`This will replace all title insurance info with info from the ConveyMe Survey.`}
          onConfirm={() => submit(true)}
          onClose={() => setUpdateNotice(false)}
        /> }
        {
          iframeURL &&
          <Transparent>
            <div onClick={onClose} className="absolute flex justify-center items-center w-full h-full">
              {
                showWarining &&
                <div onClick={(e) => e.stopPropagation()} className="absolute z-50 w-full h-full flex justify-center bg-transparent-darkest-grey items-center">
                  <div className="absolute z-50 bg-fct-red p-8 max-w-200">
                    <p className="text-white leading-normal">Are you sure you want to leave this page? All information entered will be lost if you proceed to navigate away from this screen. Do you wish to proceed?</p>
                    <div className="flex justify-center gap-4 mt-10">
                      <button onClick={onReturn} className="border p-2 rounded border-white text-white">Leave this Page</button>
                      <button onClick={() => setShowWarning(false)} className="border p-2 rounded border-white text-white">Stay on this Page</button>
                    </div>
                  </div>
                </div>
              }
              <div onClick={(e) => e.stopPropagation()} onScroll={(e) => e.stopPropagation()} className="bg-white rounded border shadow-md relative">
                <FontAwesomeIcon onClick={onClose} className="cursor-pointer absolute -top-3 -right-2" icon={faTimes} />
                <iframe src={iframeURL} ref={iframeRef} className="h-3/4" width="1250" title="FCT Iframe"></iframe>
              </div>
            </div>
          </Transparent>
        }
        <div className="rounded border flex flex-col bg-transparent-grey border-grey dark:border-transparent-grey dark:bg-midnight">
          <div className="flex flex-col gap-2 p-4">
            <div className="flex items-center gap-8">
              <img className="h-16 self-start" src={fctLogo} alt="fct logo"/>
              {(tokenId && surveyValue) &&
                <div className="font-bold">
                  Status: {surveyValue?.status  || 'No Title Insurance'}
                </div>
              }
            </div>
            <div className="flex justify-end">
              {(!surveyValue || ['completed', 'in-progress', 'pending processing', 'pending update', 'in underwriting', 'draft', 'cancelled'].includes(surveyValue?.status?.toLowerCase())) && <ButtonPrimary className="self-end" disabled={errors || !tokenId || loading} onClick={() => surveyValue ? setUpdateNotice(true) : submit(true)}>{surveyValue ? 'Update Title Insurance' : 'Get Title Insurance'}</ButtonPrimary>}
              {surveyValue && <ButtonPrimary className="self-end" disabled={errors || !tokenId || loading} onClick={() => submit()}>{'See Title Insurance'}</ButtonPrimary>}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {errors &&<div className="rounded border-red border bg-light-light-red text-red p-4">
              <h3 className="text-red">Missing Title Insurance Info</h3>
              {errors.map(error => (
                <div>
                  {error}
                </div>
              ))}
            </div>}
            {updateFlag &&<div className="rounded flex gap-2 items-center border-orange border bg-light-yellow text-orange p-4">
              <FontAwesomeIcon icon={faWarning}/>
              <h3 className="text-red">ConveyMe Title Insurance Info has been updated since last FCT submission</h3>
            </div>}
          </div>
        </div>
      </>
    )
  };

export default FCTTitleInsurcneInput;