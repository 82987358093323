import React, {useContext, useState} from 'react'
import CalendarItem from './CalendarItem';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import NewEventModal from './NewEventModal';

export default function CalendarDay() {
	const {date, setDate, monthEvents, addEvent, colorMode} = useContext(CalendarContext);
  const [showAddCalendarItem, setShowAddCalendarItem] = useState();

  const dayName = new Intl.DateTimeFormat('en-Us', { weekday: 'long' }).format(new Date(date));
  const todaysEvents = monthEvents.filter(event => sameDay(new Date(event.date + "T00:00"), date));
	return (
    <React.Fragment>
      <div className={`flex flex-col justify-center gap-2`}>
        <p className="text-center dark:text-bone"><strong>{dayName} {date.getDate()}</strong></p>
        <div className="border-b border-t dark:border-bone py-2 flex flex-col grow gap-2 h-full">
          {
            todaysEvents.map((event, i) => <CalendarItem key={i + (colorMode ? event.user?.color : event.colorOne)} event={event}/>)
          }
        </div>
        {showAddCalendarItem || <ButtonPrimary onClick={() => setShowAddCalendarItem(!showAddCalendarItem)}><FontAwesomeIcon icon={faPlusCircle}/>Add</ButtonPrimary>}
      </div>
      {showAddCalendarItem &&
        <NewEventModal date={date} close={() => setShowAddCalendarItem(!showAddCalendarItem)} addEvent={addEvent}/>
      }
    </React.Fragment>
  )
}
