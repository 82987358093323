import React from 'react';
import { Link } from 'react-router-dom';
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faFileLines, faFolderOpen, faPaste } from '@fortawesome/free-regular-svg-icons';

export default function Welcome() {
  return (
    <PaddedPage noBottom>
      <div className="flex flex-col gap-6">
        <h2>Welcome</h2>
        <p>
          ConveyMe wants to make it as simple as possible to generate your conveyancing documents.
          <br></br><br></br>
          We start with a quick survey about your sale or purchase, collecting only the necessary information needed.
          <br></br>
          This is followed by a quick summary of your file, and finally a document page where you can create and manage all your documents in one place.
        </p>
        <div className="flex items-center gap-2 rounded bg-light-grey p-4 self-start">
          <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faPaste}/>
            <small>Survey</small>
          </div>
          <FontAwesomeIcon icon={faArrowRight}/> 
          <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faFileLines}/>
            <small>Summary</small>
          </div>
          <FontAwesomeIcon icon={faArrowRight}/> 
          <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faFolderOpen}/>
            <small>Documents</small>
          </div>
        </div>
        <p>To start the survey click below.</p>
        <Link to="1">
          <ButtonPrimary>
            Start Survey<FontAwesomeIcon icon={faPaste}/> 
          </ButtonPrimary>
        </Link>
      </div>
    </PaddedPage>
  )
}
