import React, { useState, useContext, useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { ButtonPrimary } from 'components/common/Button';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import Message from 'components/common/Message';
import { useQuery, gql, useMutation } from "@apollo/client";
import Login from './Login';
import SignUp from './Signup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'assets/images/logo/logo.png';
import {faEnvelopeOpenText} from '@fortawesome/free-solid-svg-icons';
import RequestFlagsContext from 'contexts/RequestFlagsContext';

const GET_INVITE = gql`
  query GetInvite($id: String!) {
    invite(id: $id) {
      id,
      firmId,
      firm {
        name
      },
      fromUser {
        firstName,
        lastName,
        email
      }
      type,
      email,
      used,
      existingUser
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($id: String!) {
    acceptInvite(id: $id) {
      id
    }
  }
`;

const Invited = () => {
  const { currentUser, logout } = useContext(AppContext);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  let {setErrorHandler} = useContext(RequestFlagsContext);
  const [error, setError] = useState();
  const [acceptInviteMutation, { loading: acceptLoading }] = useMutation(ACCEPT_INVITE);
  const { loading, error:dataError, data } = useQuery(GET_INVITE, {
    variables: {id: params.id},
    skip: params.id ? false : true
  });

  useEffect(() => {
    if (data) {
      setErrorHandler('Invite', (error) => {
        setError(error);
      });
      return () => setErrorHandler('Invite', undefined);
    }
  }, []);

  const handleAccept = () => {
    acceptInviteMutation({variables: {id: data && data.invite.id}})
      .then(res => {
        setSuccess(true);
      })
  }
  const handleDecline = () => {
    navigate("/");
  }

  return (
    <>
      {data && (data.invite.used || currentUser) ? 
        <div className="top-0 left-0 absolute w-screen h-screen bg-gradient-to-r from-light-light-yellow dark:from-dark-coffee to-light-yellow dark:to-shadow flex flex-col z-60 justify-center items-center">
          <form className="flex flex-col justify-between p-6 gap-4 w-80 h-110 rounded-xl bg-white border">
            <div className="flex flex-col gap-4">
              <Link className="no-underline text-sm self-center" to={'/'}>
                <div className="bg-contain bg-no-repeat w-20 h-12 self-center" style={{backgroundImage: `url(${Logo})`}}/>
              </Link>
              {success && 
                <>
                  <h2 className='font-bold'>🎊Success🎊</h2>
                  <p className="text-sm">You are now a member of {(data.invite.firmId) ? `${data.invite.firm.name} on ` : ''} 
                  ConveyMe!</p>
                </>
              }
              {!success && data && 
                (data.invite.used ?
                  <>
                    <h1>This invite has already been used by {data.invite.email}.</h1>
                  </>
                : (data.invite.email == currentUser.email) ?
                  <>
                    <div>
                      <h2 className="mb-2 font-bold">
                        <FontAwesomeIcon icon={faEnvelopeOpenText}/>&nbsp;
                        {currentUser ? currentUser.firstName : data.invite.email}
                      </h2>
                      <p className="text-sm">
                        You have been invited to join&nbsp;
                        {(data.invite.firmId) ? `${data.invite.firm.name} on ` : ''} 
                        ConveyMe by {data.invite.fromUser.firstName}.
                      </p>
                    </div>
                  </>
                  : 
                  <>
                    <div>
                      <h2 className="mb-2 font-bold">
                        <FontAwesomeIcon icon={faEnvelopeOpenText}/>&nbsp;
                        {currentUser.firstName}
                      </h2>
                      <p className="text-sm">
                        You are currently logged in with the email {currentUser.email}. <br></br> <br></br>
                        This invite was intented for the user registered with the email {data.invite.email}.<br></br> <br></br>
                        if this is you please logout and login with the invited user account.
                      </p>
                    </div>
                  </>
                )
              }
            </div>
            {data.invite.existingUser && currentUser && (data.invite.email == currentUser.email) && !success && <div className="flex gap-2">
              <div className="flex w-full justify-center gap-2">
                <ButtonPrimary disabled={acceptLoading} onClick={handleDecline}>Decline</ButtonPrimary>
                <ButtonPrimary disabled={acceptLoading} onClick={handleAccept}>Accept</ButtonPrimary>
              </div>
            </div>}
            {
              success && 
              <div className="flex w-full justify-center gap-2">
                <Link className="no-underline" to={'/'}>
                  <ButtonPrimary disabled={acceptLoading}>Continue</ButtonPrimary>
                </Link>
              </div>
            }
            {
              (data.invite.email !== currentUser.email) &&
              <div className="flex w-full justify-center gap-2">
                <ButtonPrimary disabled={acceptLoading} onClick={() => logout()}>Logout</ButtonPrimary>
              </div>
            }
          </form>
          <div className="flex p-4 gap-4">
            <Link className="no-underline text-sm" to={'/'}><span className="">About</span></Link>
            <Link className="no-underline text-sm" to={'/terms-and-conditions'}><span className="">Terms</span></Link>
            <Link className="no-underline text-sm" to={'/privacy'}><span className="">Privacy</span></Link>
            {/* <Link className="no-underline text-sm" to="/faqs">Help</Link> */}
          </div>
        </div>
        :
        <>
          {data && data.invite.existingUser && <Login invite={data.invite} errorOverride={error} skipErrorHandling={true}/> }
          {data && !data.invite.existingUser && <SignUp invite={data.invite} errorOverride={error} skipErrorHandling={true}/>}
        </>
      }
    </>
  );
};

export default Invited;
