import { faClock, faNoteSticky, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp, faNotesMedical, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import TooltipBasic from 'components/common/TooltipBasic';
import Input, { IconSelector } from 'components/inputs';
import ColorSelector from 'components/inputs/ColorSelector';
import useSurveyPage from 'hooks/useSurveyPage';
import React, { useState } from 'react'

export default function ChecklistBlock({block, answers, notes, updateNotes, updateAnswers}) {
  const [editNote, setEditNote] = useState({});
  const updateNote = (note, item) => {
    updateNotes(note, item);
  }

  notes = notes || {};
  return (
    <div>
        <div className="flex justify-between items-center" style={{borderColor: block.color}}>
            <div className="flex gap-2">
                <h4 
                className="font-bold"
                style={{color: block.color}}
                >
                    {block.title}
                </h4>
            </div>
        </div>
        <div className='flex flex-col gap-0.5 items-start'>
            {block.items && block.items.map((item, itemIndex) => (
            <React.Fragment>
                <div 
                key={itemIndex} 
                className='flex items-center gap-1'
                >
                    <input 
                        className="cursor-pointer" 
                        type="checkbox" 
                        id={item} 
                        name={item} 
                        value={item}
                        checked={answers.includes(item)}
                        onClick={() => updateAnswers(item, itemIndex)}
                    />
                    <div className="flex gap-1 items-center">
                        <div className="flex items-center gap-2">
                            <label className="cursor-pointer dark:text-bone" htmlFor={item}>
                                {item}
                            </label>
                            {(!editNote[item]) &&
                            <div className="flex text-xs gap-0.5 font-semibold items-center">
                                <TooltipBasic message="Edit Note" className="relative">
                                    <div onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))} className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey dark:bg-dark-grey cursor-pointer flex items-center justify-center`}>
                                        <FontAwesomeIcon icon={faNoteSticky} className="text-xs dark:text-light-grey"/>
                                    </div>
                                </TooltipBasic>
                                {notes[item]}
                            </div>
                            }
                        </div>
                        {(editNote[item]) &&
                            <div className="relative ml-2 flex items-center">
                            <TooltipBasic message="File Note" className="absolute -left-2 top-1">
                                <div 
                                onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))}
                                className={` hover:text-white rounded-full w-5 h-5 hover:bg-med-grey bg-light-grey dark:bg-dark-grey cursor-pointer flex items-center justify-center`}
                                >
                                <FontAwesomeIcon icon={faNoteSticky} className="text-xs dark:text-light-grey"/>
                                </div>
                            </TooltipBasic>
                            <Input autoFocus className="text-xs font-medium border-light-grey leading-5 border pl-3.5 w-full" valueOverride={notes[item] || ''} onBlur={(note) => updateNote(note, item) || (note.length > 1 && setEditNote({...editNote, [item]: false}))} />
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
            ))}
        </div>
    </div>
  )
}
