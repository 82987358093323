import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ParentContext from 'contexts/ParentContext';
import useSurveyPage from 'hooks/useSurveyPage';
import React, { useContext, useMemo } from 'react'
import { useState } from 'react'

export default function DocumentQuestions({document}) {
  const [showQuestions, setShowQuestions] = useState(true);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const page = useMemo(() => {
    return {questions: document.info.questions}
  }, [document]);
  const { pageComponents } = useSurveyPage(page);
  return (
    <div className="text-sm border-t border-t p-2 items center dark:border-bone">
      <div onClick={() => setShowQuestions(!showQuestions)} className="flex cursor-pointer hover:bg-light-green dark:hover:bg-midnight justify-between items-center text-med-grey">
        <h3 className={`${showQuestions ? 'text-background-dark' : 'text-med-grey'} text-xs dark:text-bone`}>Document Questions</h3>
        <FontAwesomeIcon icon={showQuestions ? faCaretUp: faCaretDown}/>
      </div>
      {
        showQuestions &&
        <div className="rounded bg-light-light-grey dark:bg-shadow p-1 mt-1 text-xs">
          <ParentContext.Provider value={{parentPath: parentPath || "documentData", prevRows: prevRows}} >
            {pageComponents}
          </ParentContext.Provider>  
        </div>
      }
    </div>
  )
}
