import React, { useState, useContext, useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Input, Label } from 'components/inputs';
import { ButtonPrimary } from 'components/common/Button';
import { Link, useSearchParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import Message from 'components/common/Message';
import { useMutation, gql } from "@apollo/client";
import Logo from 'assets/images/logo/logo.png';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const RESEND = gql`
  mutation ResendConfirmEmail($username: String!) {
    resendConfirmEmail(username: $username) {
      message
    }
  }
`;

const Login = ({invite, errorOverride, skipErrorHandling}) => {
  const { login, loading } = useContext(AppContext);
  const [search] = useSearchParams();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [confirmSend, setConfirmSent] = useState();
  let {setErrorHandler} = useContext(RequestFlagsContext);
  const [resendMutation, { loading:loadingNewConfirm }] = useMutation(RESEND);
  const [lastLogin, setLastLogin] = useState(localStorage.getItem('last-login'));

  useEffect(() => {
    if (!skipErrorHandling) {
      setErrorHandler('Login', (error) => {
        setError(error);
      });
      return () => setErrorHandler('Login', undefined);
    }
  }, []);

  const handleLogin = (e) => {
    setError();
    e.preventDefault();
    login(username || lastLogin, password);
  }

  const notLastLogin = () => {
    localStorage.removeItem('last-login');
    setLastLogin();
  }

  const handleResendConfirm = () => {
    if (username) {
      resendMutation({variables: { username: username}})
        .then(res => {
          setConfirmSent(true);
        })
    }
  }

  return (
    <div className="top-0 left-0 absolute w-screen h-screen bg-gradient-to-r from-light-light-yellow dark:from-dark-coffee to-light-yellow dark:to-midnight flex flex-col z-60 justify-center items-center">
      <form className="flex flex-col justify-between p-6 gap-4 w-80 h-110 rounded-xl bg-white border dark:bg-midnight dark:border-black" onSubmit={handleLogin}>
        <div className="flex flex-col gap-4 dark:text-bone">
          <Link className="no-underline text-sm self-center" to={'/'}>
            <div className="bg-contain bg-no-repeat w-20 h-12 self-center" style={{backgroundImage: `url(${Logo})`}}/>
          </Link>
          {
            lastLogin ?
              <>
                <h2 className="font-bold dark:text-bone">Welcome {lastLogin}👋</h2>
                <p className="text-sm dark:text-bone">
                  Not you? <span className="cursor-pointer font-medium text-primary" onClick={notLastLogin}>Click Here</span>
                </p>
              </>
            :
            <>
              <h2 className="font-bold dark:text-bone">Login</h2>
              {!invite && 
                <p className="text-sm dark:text-bone">
                  Dont have an account? Sign Up <Link className="no-underline text-primary" to="/sign-up">Here</Link>
                </p>
              }
            </>
          }
          {invite && 
            <p className="text-sm dark:text-bone">
              You have been invited to join&nbsp;
              {(invite.firmId) ? `${invite.firm.name} on ` : ''} 
              ConveyMe by {invite.fromUser.firstName}.
            </p>
          }
          {
            (error || errorOverride) &&
            <Message negative>
              <FontAwesomeIcon icon={faCircleExclamation}/>
              &nbsp;
              {(error || errorOverride).message}
            </Message>
          }
          {search.get('confirm') == "true" &&
            <Message positive>
              Email Confirmed!
            </Message>
          }
          {search.get('confirm') == "false" &&
            <Message >
              {confirmSend ?
                "Confirmation email sent!"
              :
              <div className="flex flex-col items-center gap-2">
                Resend Confirmation Email?
                <ButtonPrimary disabled={!username || loadingNewConfirm} onClick={handleResendConfirm}>Resend</ButtonPrimary>
              </div>
              }
            </Message>
          }
          {search.get('reset') &&
            <Message positive>
              Password Reset!
              Login with your new credentials.
            </Message>
          }
          {!lastLogin &&
            <div className="flex flex-col">
              <Label text="username" />
              <Input onChange={setUsername} className="dark:bg-shadow"/>
            </div>
          }
          <div className="flex flex-col">
            <Label text="password" />
            <Input type="password" onChange={setPassword} className="dark:bg-shadow"/>
            <Link className="self-end text-sm no-underline text-primary" to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
        <ButtonPrimary className="self-end" loading={loading} disabled={loading || !(username || lastLogin) || !password}>Submit</ButtonPrimary>
      </form>
      <div className="flex p-4 gap-4">
        <Link className="no-underline text-sm" to={'/'}><span className="">About</span></Link>
        <Link className="no-underline text-sm" to={'/terms-and-conditions'}><span className="">Terms</span></Link>
        <Link className="no-underline text-sm" to={'/privacy'}><span className="">Privacy</span></Link>
        {/* <Link className="no-underline text-sm" to="/faqs">Help</Link> */}
      </div>
    </div>
  );
};

export default Login;
