import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { useQuery, useMutation, gql } from "@apollo/client";
import jwt from "jwt-decode";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
    }
  }
`;

const IMPERSONATE = gql`
  mutation Impersonate($userId: String!) {
    impersonate(userId: $userId) {
      id
    }
  }
`;

// const GET_ME = gql`
//   query GetMe {
//     me {
//       name,
//       linkId,
//       order,
//       pages
//     }
//   }
// `;

const useCurrentUser = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {unauthorized} = useContext(RequestFlagsContext);
  const token = localStorage.getItem('token');
  const impersonationToken = localStorage.getItem('impersonationToken');
  const currentUser = useMemo(() => {
    let userToken = impersonationToken || token;
    userToken = userToken ? jwt(userToken).user : null;
    if (userToken) userToken.firmAdmin = userToken.firms.reduce((acc,curr) => curr.type === 'admin' ? curr.id : acc, false);
    return userToken;
  }, [token, impersonationToken]);
  // TO BE USED IF USER OBJECT GETS TOO BIG for TOKEN
  // const { loading, userError, currentUser } = useQuery(GET_ME, {
  //   variables: { id: impersonationToken ? impersonationToken.user.id : token.user.id },
  //   skip: (!token && !impersonationToken),
  //   onError(){
  //     navigate("/login");
  //   }
  // });

  const [loginMutation, { loading:loginLoading, reset }] = useMutation(LOGIN);
  const [impersonateMutation, { loading:impersonateLoading }] = useMutation(IMPERSONATE);

  const logout = useCallback((redirect = false) => {
    localStorage.removeItem('token');
    redirect && navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (unauthorized && currentUser) {
      logout();
    }
  }, [unauthorized, logout, currentUser]);

  const login = (username, password) => {
    return loginMutation({ variables: { password, username } })
      .then(res => {
        localStorage.setItem('last-login', username);
      })
      .catch(error => {
        reset();
        if (error.toString().includes("confirm your email")) {
          setSearchParams(new URLSearchParams({confirm : false}));
        }
      })
  }

  const impersonate = (userId) => {
    impersonateMutation({ variables: { userId } });
  }

  const stopImpersonating = () => {
    localStorage.removeItem('impersonationToken');
    navigate('/');
  }

  const tokenWithMethods = (token) => {
    if (token) {
      token.auth = function (type, firmId) {
        if (!firmId) return true;
        let firm = this.firms.find(firm => firm.id === firmId);
        if (!firm) return ['admin', 'super-admin'].includes(this.type);
        switch (type) {
          case 'admin':
            return firm.type === 'admin';
          case 'priviledged':
            return !firm.type || firm.type === 'admin'
          case 'basic':
            return firm.type === 'basic'
          default:
            return true
        }
      }

      return token;
    }
  }
  
  return {
    currentUser: tokenWithMethods((token || impersonationToken) && currentUser),
    login,
    logout,
    impersonating: impersonationToken ? true : false,
    impersonate,
    stopImpersonating,
    loading: loginLoading || impersonateLoading,
  }
};

export default useCurrentUser;
