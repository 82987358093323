import { faPerson } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function LoadingIcon() {
  return (
    <span className="animate-pulse bg-light-light-grey dark:bg-shadow text-light-light-grey rounded-full dark:text-bone">
      &nbsp;&nbsp;<FontAwesomeIcon icon={faPerson}/>&nbsp;&nbsp;
    </span>
  )
}
