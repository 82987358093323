import React, { useEffect, useRef } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';

const Testimonial = ({testimonial, company, name}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg flex items-start flex-col gap-2 p-6 grow-1 shrink-1 w-80 animate-elevator">
      <p className="leading-5 text-med-grey text-left">
        <span className="">"</span>
          {testimonial}
        <span className="">"</span>
      </p>
      <div>
        <h3 className="text-left">{name}</h3>
        <p className="text-left text-xs">{company}</p>
      </div>
    </div>
  )
}

const Testimonials = () => {
  const itemsRef = useRef([]);
  const positionRef = useRef(0);

  useEffect(() => {
    let shift = () => {
      itemsRef.current[positionRef.current % itemsRef.current.length].style.transform = "scale(0.5)";
      itemsRef.current[positionRef.current % itemsRef.current.length].style.opacity = "50%";
      itemsRef.current[positionRef.current % itemsRef.current.length].style.left = "0";
      itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.transform = "scale(1) translate(-50%)";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.opacity = "100%";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left = "50%";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = "10";

      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.transform = "scale(0.5) translate(-200%)";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.opacity = "50%";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.left = "100%";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.transform = "scale(0.5) translate(200%, -50%)";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.opacity = "20%";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.left = "-0%";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.transform = "scale(0.5) translate(200%, -50%)";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.opacity = "20%";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.left = "-0%";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style['z-index'] = "1";
      positionRef.current ++;
    }

    // shift();
    let interval = setInterval(() => {
      // shift();
    }, 8000);

    return () => {
      clearInterval(interval);
    }

  }, [itemsRef]);

  return (
    <>
      <div className="w-full" style={{background: "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)"}}>
        <PaddedPage className="self-center 2xl:mx-auto 2xl:max-w-8xl">
          <div className="flex flex-col items-center pt-12 gap-8 text-center">
            <div className='flex flex-col gap-2'>
              <h1 className="text-white font-medium flex"><span className="transform -scale-x-100 block">🎉</span>What Our Clients Say🎉</h1>
              {/* <h2 className="text-noromal text-lg">Focussing on good old fashioned customer service allows us to listen, customize documents, and build out the features that our clients need.</h2> */}
            </div>
            <div className="flex gap-4 flex-wrap pb-20 sm:justify-center">
              <div className="flex flex-col gap-4 animate-elevator relative" ref={(el) => el && el.style.setProperty('animation-delay', `2s`, "important")}>
                <Testimonial
                  testimonial="Staff are friendly and exceptionally professional, always willing to help without hesitation."
                  company="Wendy Tang Notary Public"
                  name="Wendy"
                />
                <Testimonial
                  testimonial="ConveyMe is a full package conveyance software where everything is integrated onto its cloud platform. From ordering title insurance, title searches, and generating package directly onto LTSA, seamless document creation, to Trust Accounting – everything is so easy and intuitive! Within a day or two, I felt super comfortable using and navigating the software. I am highly satisfied with this product and the price point for using this software is even more satisfying. On top of that, the service that I get from ConveyMe’s support team is always top notch and always quick and they are all very friendly. ConveyMe’s customer service team always goes above and beyond. I highly recommend ConveyMe."
                  company="Brightside Notary"
                  name="Hani"
                />
              </div>
              <div className="flex flex-col gap-4 self-center animate-elevator relative" ref={(el) => el && el.style.setProperty('animation-delay', `16s`, "important")}>
                <Testimonial
                  testimonial="ConveyMe interface is a testament to thoughtful design. It’s evident that the program was crafted by a team with a deep understanding of daily conveyance files and a keen awareness of what makes the process run smoothly. ConveyMe’s customer service is unparalleled. My experience with ConveyMe was nothing short of exceptional. I highly recommend ConveyMe to anyone in need of reliable and efficient conveyance program."
                  company="SF Notary Public"
                  name="Sherry"
                />
                <Testimonial
                  testimonial="ConveyMe's intuitive platform and dedicated, amazing support team have been invaluable to our real estate practice. Their expertise in customizing our documents and their commitment to providing thorough training have allowed us to quickly and confidently integrate ConveyMe into our workflow."
                  company="Northam Law Corporation"
                  name="Melissa"
                />
                <Testimonial
                  testimonial="I cannot say enough with how pleased we are to be working with Conveyme. My absolute favourite part of working with ConveyMe, is their passion for creating something special and how open they have been to wish list items. You can tell the people behind the scenes are very experienced, enjoy what they are doing and get what works and what doesn't. We are so pleased to be working with a program superior to what we had.  They made the switch really simple for us too."
                  company="Linley Welwood"
                  name="Zoë"
                />
              </div>
              <div className="flex flex-col gap-4 animate-elevator relative" ref={(el) => el && el.style.setProperty('animation-delay', `12s`, "important")}>
                <Testimonial
                  testimonial="I was surprised by the ease of the software. I was also super happy with the function to print checks. The price is reasonable. With a couple days, I was able to work on the first file. I only had the time to train my staff one week after. Now (2 weeks from the first Demo) all my staff are able to use the Conveyme to do their conveyances. The Conveyme staff are readily available and they listen. They are making improvement with the input from the users."
                  company="First Class Notary"
                  name="Jason"
                />
                <Testimonial
                  testimonial="What I love most about your system, not just the support but also how proactive you are on continually working with us and adding to create what I think will be the best conveyancing system."
                  company="Bryshun Mace Lawyers"
                  name="Noreen"
                />
              </div>
            </div>
          </div>
        </PaddedPage>
      </div>
    </>
  );
};

export default Testimonials;
