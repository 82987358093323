import { FullWidthPage, PaddedPage } from 'components/common/Layout'
import React from 'react'
import InviteUser from './InviteUser'

export default function InviteUserPage() {
  return (
    <PaddedPage bottom>
        <InviteUser/>
    </PaddedPage>
  )
}
