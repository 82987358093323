import React, {useContext, useState, useMemo, useCallback, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import _ from "lodash";
import locationAutocomplete from 'helpers/locationAutocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboard, faCopy } from '@fortawesome/free-regular-svg-icons';
import { pdfjs } from 'react-pdf';
import { firstBusinessDay } from 'helpers/calendar';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${'3.6'}/build/pdf.worker.min.js`;
const PropertyTaxUpload = ({ 
  type, 
  questionKey, 
  title, 
  hideLabel, 
  hidden, 
  required,
  calculatedLabel,
  readOnly, 
  info,
  formStyles,
  description,
  value
}) => {
  const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const [pdfFile, setPdfFile] = useState();
  const [pdfText, setPdfText] = useState();
  const [parsedData, setParsedData] = useState();

  const calculatedLabelValue = useMemo(() => {
      return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  const completionDate = useMemo(() => {
    return (file && getSurveyValue(file.answers, 'completionDate')) || new Date();
  }, [file, path]);

  const onFileChange = (event) => {
		setPdfFile(event.target.files[0]);
    parsePdf(URL.createObjectURL(event.target.files[0]));
	}

  const parsePdf = (doc) => {
    const loadingTask = pdfjs.getDocument(doc)
    loadingTask.promise
      .then(pdf => {
        var maxPages = pdf._pdfInfo.numPages;
        var countPromises = []; // collecting all page promises
        for (let j = 1; j <= maxPages; j++) {
          countPromises.push(pdf.getPage(j))
        }
        return Promise.all(countPromises)
      })
      .then(pages => {
        let extractedText = '';
        let contentPromises = [];
        for (const page of pages) {
          contentPromises.push(page.getTextContent());
        }
        return Promise.all(contentPromises);
      })
      .then(pages => {
        let totalText = '';
        for(let page of pages) {
          let pageItems = _.orderBy(page.items.map(el => ({ 'x': el.transform[4], 'y': el.transform[5], 't': el.str })), ['y','x'], ['desc', 'asc'] );
          const pageText = pageItems.map((item) => item.t.toLowerCase()).join(' ');
          totalText += '\\n\\n' + pageText;
        }
        setPdfText(totalText);
        textToData(totalText);
      })
      .catch(err => {
        console.log("err", err);
      })
  }
  const textToData = (text) => {
    let data = {};
    if (text.includes('ministry of finance')) {
      let index = text.indexOf('other   balance');
      let sub = text.substring(index + 15)?.trim()?.split("   "); 
      // levy
      data.levy = sub[1];
      // grant claimed
      data.hog = sub[4]; 
      // date
      let now = new Date();
      now.setYear(sub[0])
      data.date = now.toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"} );
      // total owing
      data.total = sub[6].split(" ")[0];
      // installments
      data.installments = sub[5]
    } else {
      // levy
      let index = text.indexOf('total levy');
      data.levy = text.substring(index + 10, index + 30)?.trim()?.split(" ")?.[0]; 
      // grant claimed
      index = text.indexOf('grant claimed');
      data.hog = text.substring(index + 13, index + 33)?.trim()?.split(" ")?.[0]; 
      // date
      index = text.indexOf('owing as at');
      data.date = text.substring(index + 11, index + 31)?.trim()?.split(" ")?.slice(0,3).join(" ");
      // total owing
      index = text.indexOf('total taxes owing');
      data.total = text.substring(index + 17, index + 27)?.trim()?.split(" ")?.[0];
      // installments
      index = text.indexOf('balance as at');
      let sub = text.substring(index);
      index = sub.indexOf(data.date);
      data.installments = sub.substring(index + 12, index + 22)?.trim()?.split(" ")?.[0];
    }
    data.hog = Math.abs(Number(data.hog?.replace("$", '').replace(',', '') || 0));
    data.levy = Number(data.levy?.replace("$", '').replace(',', '') || 0);
    data.total = Number(data.total?.replace("$", '').replace(',', '') || 0);
    data.installments = Number(data.installments?.replace("$", '').replace(',', '') || 0);
    // property taxes year
    let taxesThisYear = (new Date(completionDate).getFullYear() === new Date(data.date).getFullYear());
    data.propTaxYear = taxesThisYear ? 'this year' : 'last year';

    // property taxes for year paid
    let ptDuedate = firstBusinessDay(new Date(completionDate).getFullYear(), 6);
    ptDuedate.setDate(ptDuedate.getDate() + 1);
    if(taxesThisYear) {
      // completion date year = PT certificate year, 
      if ((ptDuedate <= new Date(completionDate)) && data.total == 0) {
        // If completion date is on or after PT due date, AND Total Taxes Owing is = 0, then answer to question = yes.
        data.propTaxPaidYet = true;
      } else {
        // If completion date is before PT due date, then answer to question = no.
        data.propTaxPaidYet = '';
      }
    } else {
      // If Completion date year = PT certificate year + 1, then answer to question = no.
      data.propTaxPaidYet = '';
    }

    updateAnswers(
      [path, 'propertyTaxes[0].propertyTax.hog', 'propertyTaxes[0].propertyTax.propTaxAmountManual', 'propertyTaxes[0].propertyTax.propTaxPaidYet', 'propertyTaxes[0].propertyTax.propTaxYear',  'propertyTaxes[0].propertyTax.advanceTaxes'], 
      [data, data.hog, data.levy, data.propTaxPaidYet, !data.propTaxPaidYet ? data.propTaxYear : undefined , data.propTaxPaidYet ? data.installments : undefined]
    );
    setParsedData(data);
  }

  return (
      <div className={`flex flex-col justify-between input py-4 gap-1 grow-0 ${hidden ? 'hidden' : ''}`}>
          {!hideLabel && (title || calculatedLabelValue) && (
              description ? 
                  <label className="dark:text-bone">
                      {title || calculatedLabelValue}
                      <Tooltip message={description} />
                  </label>
              :
                  <label className="dark:text-bone">
                      {title || calculatedLabelValue}
                  </label>
          )}
          <div className="flex gap-2 items-center">
            <input name="file" id="ptFile" className="hidden" type="file" onChange={onFileChange} />
            <label className="border-2 border-light-grey font-semibold text-sm rounded bg-white hover:no-underline cursor-pointer px-2 py-1 dark:bg-transparent dark:text-bone" for="ptFile">Choose a file</label>
            {pdfFile && <h3 className="text-green">{pdfFile.name} <FontAwesomeIcon icon={faCheckCircle}/></h3>}
          </div>
          {surveyValue && <div className="flex flex-col gap-2 rounded bg-white p-2">
            <div>
              <h3>PDF Data</h3>
              <hr></hr>
              </div>
              {
                surveyValue && Object.entries(surveyValue).map(([key, value]) => <p><span className="font-medium">{_.startCase(key)}:</span> <i>{value === '' ? 'no' : (value === true ? 'true' : value)}</i></p>)
              }
            </div>
          }
      </div>
  )
};

export default PropertyTaxUpload;