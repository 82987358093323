import React, {useState, useContext, useRef, useCallback, useMemo, useEffect} from 'react';
import Input, { Label, Radios } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBuilding, faTimes, faPlaceOfWorship, faLink } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import { useMutation, gql } from "@apollo/client";
import { typeToLabel } from 'helpers/general';
import locationAutocomplete from 'helpers/locationAutocomplete';
import ClientInput from './ClientInput';
import BusinessInput from './BusinessInput';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import useSurveyPage from 'hooks/useSurveyPage';
import Tooltip from 'components/common/Tooltip';

const ADD_BUSINESS = gql`
  mutation AddBusiness(
    $companyName: String!,
    $email: String,
    $phoneNumber: String,
    $fax: String,
    $addressLineOne: String,
    $addressLineTwo: String,
    $city: String,
    $province: String,
    $postalCode: String,
    $type: String,
    $public: Boolean,
    $gstNumber: String,
    $pstNumber: String,
    $incorporationNumber: String,
    $fundsTransferAuthorizedSubmitter: String,
    $fundsTransferAccountOwner: String,
    $fundsTransferAccountNamePtt: String,
    $firmId: String,
    $corporateDirectors: [JSONObject],
    $directors: [JSONObject],
    $shareholders: [JSONObject],
  ) {
    createBusiness(
      companyName: $companyName,
      email: $email,
      phoneNumber: $phoneNumber,
      fax: $fax,
      addressLineOne: $addressLineOne,
      addressLineTwo: $addressLineTwo,
      city: $city,
      province: $province,
      postalCode: $postalCode,
      type: $type,
      public: $public,
      gstNumber: $gstNumber,
      pstNumber: $pstNumber,
      incorporationNumber: $incorporationNumber,
      fundsTransferAuthorizedSubmitter: $fundsTransferAuthorizedSubmitter,
      fundsTransferAccountOwner: $fundsTransferAccountOwner,
      fundsTransferAccountNamePtt: $fundsTransferAccountNamePtt,
      firmId: $firmId,
      corporateDirectors: $corporateDirectors,
      directors: $directors,
      shareholders: $shareholders,
    ) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      addressLineOne,
      addressLineTwo,
      city,
      province,
      postalCode,
      gstNumber,
      pstNumber,
      incorporationNumber,
      type,
      corporateDirectors,
      directors,
      shareholders,
      fundsTransferAuthorizedSubmitter,
      fundsTransferAccountOwner,
      fundsTransferAccountNamePtt,
    }
  }
`;

const EDIT_BUSINESS = gql`
  mutation UpdateBusiness(
    $id: String,
    $companyName: String,
    $email: String,
    $phoneNumber: String,
    $fax: String,
    $addressLineOne: String,
    $addressLineTwo: String,
    $city: String,
    $province: String,
    $postalCode: String,
    $gstNumber: String,
    $pstNumber: String,
    $incorporationNumber: String,
    $fundsTransferAuthorizedSubmitter: String,
    $fundsTransferAccountOwner: String,
    $fundsTransferAccountNamePtt: String,
    $type: String,
    $corporateDirectors: [JSONObject],
    $directors: [JSONObject],
    $shareholders: [JSONObject],
  ) {
    updateBusiness(
      id: $id,
      companyName: $companyName,
      email: $email,
      phoneNumber: $phoneNumber,
      fax: $fax,
      addressLineOne: $addressLineOne,
      addressLineTwo: $addressLineTwo,
      city: $city,
      province: $province,
      postalCode: $postalCode,
      gstNumber: $gstNumber,
      pstNumber: $pstNumber,
      incorporationNumber: $incorporationNumber,
      fundsTransferAuthorizedSubmitter: $fundsTransferAuthorizedSubmitter,
      fundsTransferAccountOwner: $fundsTransferAccountOwner,
      fundsTransferAccountNamePtt: $fundsTransferAccountNamePtt,
      type: $type,
      corporateDirectors: $corporateDirectors,
      directors: $directors,
      shareholders: $shareholders,
    ) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      addressLineOne,
      addressLineTwo,
      city,
      province,
      postalCode,
      gstNumber,
      pstNumber
      incorporationNumber,
      type,
      corporateDirectors,
      directors,
      shareholders,
      fundsTransferAuthorizedSubmitter,
      fundsTransferAccountOwner,
      fundsTransferAccountNamePtt,
    }
  }
`;

const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($id: String!) {
    deleteBusiness(id: $id) {
      id
    }
  }
`;

const getType = (val, key) => {
  let type = '';
  if (val?.[key]?.length > 0) {
    type = "individual";
  }
  if (val?.['corporate' + key[0].toUpperCase() + key.substring(1)]?.length > 0) {
    type = type ? "both" : 'corporate';
  }
  return type;
}

export default function AddBusiness({close, onDelete, onCreate, type, publicBusiness, edit, firmId, readOnly, specialFields}) {
  const [newBusiness, setNewBusiness] = useState(edit || {type: type && type[0]});
  const [addBusinessMutation, { loading }] = useMutation(ADD_BUSINESS);  
  const [editBusinessMutation, { loading:editLoading }] = useMutation(EDIT_BUSINESS);
  const [deleteBusinessMutation, { loading: deleteLoading, error:deleteError }] = useMutation(DELETE_BUSINESS);
  const [directorsType, setDirectorsType] = useState(getType(edit, 'directors'));
  const [shareholdersType, setShareholdersType] = useState(getType(edit, 'shareholders'));
  const [showDelete, setShowDelete] = useState();
  const page = useMemo(() => {
    return {questions:[
      {question:{
        kind: "List",
        key: 'interestHolder',
        label: 'Corporate interest holder?',
        options: [
          {name: 'yes', value: true},
          {name: 'no', value: ''},
        ]
      }}
    ]}
  }, []);
  const { pageComponents } = useSurveyPage(page);

  const valid = () => {
    return newBusiness && newBusiness.companyName;
  }

  useEffect(() => {
    setNewBusiness(current => {
      return ({
        ...current, 
        directors: current.directors?.map((director, i) => ({...director, ...(edit.directors?.find(editDirector => editDirector.id == director.id) || {})})), 
        corporateDirectors: current.corporateDirectors?.map((director, i) => ({...director, ...(edit.corporateDirectors?.find(editDirector => editDirector.id == director.id) || {})})), 
        shareholders: current.shareholders?.map((shareholder, i) => ({...shareholder, ...(edit.shareholders?.find(editShareholder => editShareholder.id == shareholder.id) || {})}))
      })
    });
  }, [edit]);

  const businessNameRef = useRef();

  const measuredRef = useCallback(node => {
    if (node !== null && !edit) {
      let autocomplete = locationAutocomplete(node, type);
      autocomplete.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        let addressInfo = {};
        for (let item of place.address_components) {
          if (item.types.includes("route")) {
            addressInfo.addressLineOne = (addressInfo.addressLineOne || "") + item.long_name;
          } else if (item.types.includes("street_number")) {
            addressInfo.addressLineOne = item.long_name + " " + (addressInfo.addressLineOne || "");
          } else if (item.types.includes("floor", "room", "subpremise")) {
            addressInfo.addressLineTwo = addressInfo.addressLineTwo ? addressInfo.addressLineTwo + " " + item.long_name : item.long_name;
          } else if (item.types.includes("postal_code")) {
            addressInfo.postalCode = item.long_name;
          } else if (item.types.includes("administrative_area_level_1")) {
              addressInfo.province = item.long_name;
          } else if (item.types.includes("locality")) {
            addressInfo.city = item.long_name;
          }
        }
        setNewBusiness(current => ({...current, ...addressInfo, companyName: place.name, phoneNumber: place.international_phone_number}));
      });
      businessNameRef.current = node;
    }
  }, []);

  const addBusiness = () => {
    if (valid()) {
      addBusinessMutation({variables: {
        companyName: newBusiness.companyName,
        email: newBusiness.email,
        phoneNumber: newBusiness.phoneNumber,
        fax: newBusiness.fax,
        addressLineOne: newBusiness.addressLineOne,
        addressLineTwo: newBusiness.addressLineTwo,
        city: newBusiness.city,
        province: newBusiness.province,
        postalCode: newBusiness.postalCode,
        firmId,
        type: newBusiness.type,
        gstNumber: newBusiness.gstNumber,
        pstNumber: newBusiness.pstNumber,
        incorporationNumber: newBusiness.incorporationNumber,
        public: publicBusiness,
        corporateDirectors: newBusiness.corporateDirectors,
        directors: newBusiness.directors,
        shareholders: newBusiness.shareholders,
        fundsTransferAuthorizedSubmitter:  newBusiness.fundsTransferAuthorizedSubmitter,
        fundsTransferAccountOwner:  newBusiness.fundsTransferAccountOwner,
        fundsTransferAccountNamePtt: newBusiness.fundsTransferAccountNamePtt,
      }})
      .then(res => {
        onCreate(res.data.createBusiness, true);
      })
    }
  }

  const editBusiness = () => {
    if (valid()) {
      editBusinessMutation({variables: {...newBusiness, firmId: newBusiness.firmId || firmId}})
      .then(res => {
        edit.id = res.data.updateBusiness.id;
        onCreate(res.data.updateBusiness, true);
      })
    }
  }

  const deleteBusiness = () => {
    deleteBusinessMutation({variables: {id: newBusiness.id}})
    .then(() => onDelete());
  }
  return (
    <>
      {showDelete && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete this business from the database.`}
        onConfirm={deleteBusiness}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="p-4 border border-transparent-dark-grey rounded flex flex-col bg-transparent-grey gap-4">
          <div className="relative">
          <h3>🏭{edit ? "Edit" : "New"} {typeToLabel(type) || 'Business'}
            {
              edit?.linkedFirm &&
              <Tooltip
                icon={faLink}
                color="green"
                message={"This business is linked to a firm"}
              />
            }
          </h3>
          {close && <FontAwesomeIcon onClick={close} className={`absolute top-1 right-1 cursor-pointer`} icon={edit ? faTimes : faMagnifyingGlass}/>}
          </div>
          {type && <Radios readOnly={readOnly} valueOverride={newBusiness.type} inline onChange={(type) => setNewBusiness({...newBusiness, type})} options={type.map(type => ({name: type || 'none', value: type}))}/> }
          <div className="flex flex-col">
              <Label text="Company Name"/>
              <Input readOnly={readOnly} placeholder="Start typing a company name" forwardRef={measuredRef} valueOverride={newBusiness.companyName} onChange={(companyName) => setNewBusiness({...newBusiness, companyName})} onBlur={(companyName) => setNewBusiness({...newBusiness, companyName: companyName?.trim()})}/>
              {/* <Input readOnly={readOnly} forwardRef={measuredRef} /> */}
          </div>
          <div className="flex gap-2"> 
            <div className="flex flex-col">
                <Label text="Phone Number"/>
                <Input autoPattern="   -   -   " readOnly={readOnly} valueOverride={newBusiness.phoneNumber} onChange={(phoneNumber) => setNewBusiness({...newBusiness, phoneNumber})} />
            </div>
            <div className="flex flex-col grow">
                <Label text="Fax"/>
                <Input autoPattern="   -   -   " readOnly={readOnly} valueOverride={newBusiness.fax} onChange={(fax) => setNewBusiness({...newBusiness, fax})} />
            </div>
          </div>
          <div className="flex flex-col">
              <Label text="Email"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.email} onChange={(email) => setNewBusiness({...newBusiness, email})} />
          </div>
          <div className="flex flex-col gap-2">
          <div className="flex flex-col">
              <Label text="Address"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.addressLineOne} onChange={(addressLineOne) => setNewBusiness({...newBusiness, addressLineOne})} />
          </div>
          <div className="flex flex-col">
              <Label text="Second line of address"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.addressLineTwo} onChange={(addressLineTwo) => setNewBusiness({...newBusiness, addressLineTwo})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
              <Label text="City"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.city} onChange={(city) => setNewBusiness({...newBusiness, city})} />
          </div>
          <div className="flex flex-col">
              <Label text="Province"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.province} onChange={(province) => setNewBusiness({...newBusiness, province})} />
          </div>
          <div className="flex flex-col">
              <Label text="Postal Code"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.postalCode} onChange={(postalCode) => setNewBusiness({...newBusiness, postalCode})} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col">
              <Label text="GST Number"/>
              <Input pattern="^\d{9}$" patternErrorMessage="Invalid GST Number Format try #########" readOnly={readOnly} valueOverride={newBusiness.gstNumber} onChange={(gstNumber) => setNewBusiness({...newBusiness, gstNumber})} />
          </div>
          <div className="flex flex-col">
              <Label text="PST Number"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.pstNumber} onChange={(pstNumber) => setNewBusiness({...newBusiness, pstNumber})} />
          </div>
          <div className="flex flex-col">
              <Label text="Incorporation Number"/>
              <Input readOnly={readOnly} valueOverride={newBusiness.incorporationNumber} onChange={(incorporationNumber) => setNewBusiness({...newBusiness, incorporationNumber})} />
          </div>
        </div>
        {
          specialFields?.length > 0 &&
          <div className="flex-col gap-4">
            <div>
              {specialFields.includes('fundsTransferAccountNamePtt') && 
                <div className="flex flex-col">
                    <Label text="PTT Funds Transfer Account Name"/>
                    <Input readOnly={readOnly} valueOverride={newBusiness.fundsTransferAccountNamePtt} onChange={(fundsTransferAccountNamePtt) => setNewBusiness({...newBusiness, fundsTransferAccountNamePtt})} />
                </div>
              }
            </div>
            <div className="flex gap-2">
              {specialFields.includes('fundsTransferAccountOwner') && 
                <div className="flex flex-col">
                    <Label text="Funds Transfer Account Owner"/>
                    <Input readOnly={readOnly} valueOverride={newBusiness.fundsTransferAccountOwner} onChange={(fundsTransferAccountOwner) => setNewBusiness({...newBusiness, fundsTransferAccountOwner})} />
                </div>
              }
              {specialFields.includes('fundsTransferAuthorizedSubmitter') && 
                <div className="flex flex-col">
                    <Label text="Funds Transfer Authorized Submitter"/>
                    <Input readOnly={readOnly} valueOverride={newBusiness.fundsTransferAuthorizedSubmitter} onChange={(fundsTransferAuthorizedSubmitter) => setNewBusiness({...newBusiness, fundsTransferAuthorizedSubmitter})} />
                </div>
              }
            </div>
          </div>
        }
        <div className="flex gap-2 border p-2 bg-white">
          <div className="flex flex-col">
            <Label text="Directors"/>
            <Radios 
              valueOverride={directorsType} 
              inline 
              onChange={(type) => setDirectorsType(type)} 
              options={[
                {name: 'Individuals', value: 'individual'},
                {name: 'Corporate', value: 'corporate'},
                {name: 'Both', value: 'both'},
                {name: 'None', value: ''},

              ]}/>
            {
              ['both', 'individual'].includes(directorsType) &&
              <ClientInput
                label="Directors"
                valueOverride={newBusiness.directors} 
                multiple={true}
                removable={true}
                mini={true}
                onSelect={(directors) => setNewBusiness({...newBusiness, directors})}
              />
            }
            {
              ['both', 'corporate'].includes(directorsType) &&
              <BusinessInput 
                label="Corporate Directors"
                valueOverride={newBusiness.corporateDirectors} 
                multiple={true}
                removable={true}
                mini={true}
                onSelect={(corporateDirectors) => setNewBusiness({...newBusiness, corporateDirectors})}
              />
            }
          </div>
        </div>
        <div className="flex gap-2 border p-2 bg-white">
          <div className="flex flex-col">
            <Label text="Shareholders"/>
            <Radios 
              valueOverride={shareholdersType} 
              inline 
              onChange={(type) => setShareholdersType(type)} 
              options={[
                {name: 'Individuals', value: 'individual'},
                {name: 'None', value: ''},

              ]}/>
            {
              ['both', 'individual'].includes(shareholdersType) &&
              <ClientInput
                label="Shareholders"
                questionKey="shareholders"
                valueOverride={newBusiness.shareholders} 
                multiple={true}
                removable={true}
                // mini={true}
                questions={pageComponents}
                onSelect={(shareholders) => setNewBusiness({...newBusiness, shareholders})}
              />
            }
            {/* {
              ['both', 'corporate'].includes(shareholdersType) &&
              <BusinessInput 
                label="Corporate Shareholders"
                valueOverride={newBusiness.corporateShareholders} 
                multiple={true}
                removable={true}
                mini={true}
                onSelect={(corporateShareholders) => setNewBusiness({...newBusiness, corporateShareholders})}
              />
            } */}
          </div>
        </div>
        <div className="flex gap-2">
          <ButtonPrimary disabled={editLoading || loading || !valid()} onClick={readOnly ? close : edit ? editBusiness : addBusiness} className="self-start">{readOnly ? "Close" : edit ? (newBusiness.id ? "Update" : "Save") : "Add"}</ButtonPrimary>
          {!readOnly && edit && !edit.linkedFirm && <ButtonPrimary disabled={editLoading || loading || !valid()} onClick={() => setShowDelete(true)} className="self-start border-red text-red">Delete</ButtonPrimary>}
        </div>
      </div>
    </>
  )
}
