import React, {useContext, useState} from 'react'
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFileInvoiceDollar, faPenToSquare, faFileLines, faFolderOpen, faGear, faListCheck, faLock, faPaste, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SurveyContext from 'contexts/SurveyContext';
import { faCalendarCheck, faClock, faEye } from '@fortawesome/free-regular-svg-icons';
import Input, { Checkbox, IconSelector } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import LoadingText from 'components/loading/LoadingText';
import LoadingIcon from 'components/loading/LoadingIcon';
import TooltipBasic from 'components/common/TooltipBasic';

export default function SurveyPageNav({children}) {
  const [editNickname, setEditNickname] = useState(false);
  const { surveyId, fileId, surveyNav, file, updateFile, setShowPaths, showPaths, loading } = useContext(SurveyContext);
  const {currentUser, closeMenu} = useContext(AppContext);
  const [showMenu, setShowMenu] = useState();
  const admin = ["admin", "super-admin"].includes(currentUser?.type);

  const handleNicknameChange = (nickname) => {
    updateFile({nickname}, true);
    setEditNickname(false);
  }
  const handleFileNumberChange = (fileNumber) => {
    updateFile({fileNumber}, true);
    setEditNickname(false);
  }
  const handleIconChange = (icon) => {
    updateFile({icon}, true);
    setEditNickname(false);
  }

  return (
    <>
      <div className={`
          border-b dark:border-slate md:sticky bg-light-cream dark:bg-dark-coffee z-30
          md:-top-12
          lg:-top-14
          xl:-top-16
          2xl:-top-4
        `}>
          <PaddedPage noBottom className="flex flex-col gap-2 md:gap-4">
            <div className="flex justify-between">
              {
                (!file) ?
                  <h2 className="animate-pulse text-light-grey">
                    <LoadingIcon/>&nbsp;
                    <span className="text-primary"> <LoadingText/></span>
                    &nbsp;/&nbsp;
                    <LoadingText/>
                  </h2>
                :
                <h2 className="flex items-center dark:text-bone">
                  <div className="inline">{editNickname ? <IconSelector small initialValue={file.icon} onChange={handleIconChange}/> : file.icon}</div>&nbsp;
                  {editNickname ? <span className="text-primary">#<Input className="text-primary font-medium py-0" valueOverride={file.fileNumber} onBlur={handleFileNumberChange}/></span>: <span className="text-primary">#{file.fileNumber}</span>}
                  &nbsp;/&nbsp;
                  {editNickname ? <Input className=" font-medium py-0" valueOverride={file.nickname} onBlur={handleNicknameChange}/> : file.nickname}
                  <sup>
                    <TooltipBasic message="Edit File" className="relative ml-1 tracking-normal">
                      <div 
                        className={`hover:text-white dark:bg-shadow rounded-full w-6 h-6 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center`}
                        onClick={() => setEditNickname(!editNickname)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => setShowPaths(!showPaths)} className="text-xs"/>
                      </div>
                    </TooltipBasic>
                    {/* <FontAwesomeIcon onClick={() => setEditNickname(!editNickname)}className="ml-1 text-xs cursor-pointer"icon={faPenToSquare}/> */}
                  </sup>
                </h2>
              }
              {
                admin &&
                <div className="gap-1 hidden md:flex items-center">
                  <TooltipBasic message="Show Paths" className="relative">
                    <div className={`${showPaths ? 'text-blue' : ''} dark:bg-shadow hover:text-white rounded-full w-6 h-6 hover:bg-med-grey bg-light-grey cursor-pointer flex items-center justify-center`}>
                      <FontAwesomeIcon icon={faEye} onClick={() => setShowPaths(!showPaths)} className="text-base"/>
                    </div>
                  </TooltipBasic>
                </div>
              }
            </div>
            <div className="hidden md:flex flex-col md:flex-row gap-2 md:gap-4 justify-between">
              <div className="hidden md:flex flex-col md:flex-row gap-2 md:gap-4">
                  <Link onClick={closeMenu} className={`2xl:hidden pb-3 text-sm no-underline ${surveyNav === 'survey' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/1`}>
                    <FontAwesomeIcon icon={faPaste} />
                    &nbsp;Survey
                  </Link>
                  <Link className={`hidden 2xl:block pb-3 text-sm no-underline ${surveyNav === 'survey' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/1`}>
                    <FontAwesomeIcon icon={faPaste} />
                    &nbsp;Survey
                  </Link>
                  <Link className={`pb-3 text-sm no-underline ${surveyNav === 'summary' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/summary`}>
                    <FontAwesomeIcon icon={faFileLines} />
                    &nbsp;Summary
                  </Link>
                  <Link className={`pb-3 text-sm no-underline ${surveyNav === 'documents' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/documents`}>
                    <FontAwesomeIcon icon={faFolderOpen} />
                    &nbsp;Documents
                  </Link>
                  <Link className={`pb-3 text-sm no-underline ${surveyNav === 'checklist' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/checklist`}>
                    <FontAwesomeIcon icon={faListCheck} />
                    &nbsp;Checklist
                  </Link>
                  <Link className={`pb-3 text-sm no-underline flex items-center md:justify-center content-center ${surveyNav === 'trust-account' ? 'border-b border-dark-grey dark:border-bone' : 'text-med-grey hover:text-dark-grey dark:hover:text-bone'}`} to={`/files/${surveyId}/${fileId}/trust-account`}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    &nbsp;Trust Account
                  </Link>
              </div>
              <div className="self-end flex gap-2 text-xs font-semibold">
                {file?.answers.completionDate && <TooltipBasic message="Completion Date" className="relative cursor-pointer">
                  <div className="mb-2 rounded bg-light-light-yellow dark:bg-dark-coffee text-med-grey p-1 px-2 flex gap-1 items-center"><FontAwesomeIcon icon={faCalendarCheck} />{file.answers.completionDate}</div>
                </TooltipBasic>}
                {file?.answers.adjustmentDate && <TooltipBasic message="Adjustment Date" className="relative cursor-pointer">
                  <div className="mb-2 rounded bg-light-light-yellow dark:bg-dark-coffee text-med-grey p-1 px-2 flex gap-1 items-center"><FontAwesomeIcon icon={faCalendarDay} />{file.answers.adjustmentDate}</div>
                </TooltipBasic>}
              </div>
            </div>
            <div onClick={() => setShowMenu(!showMenu)} className="flex md:hidden flex-col md:flex-row md:gap-4">
                <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'survey' ? 'order-first border-b border-dark-grey dark:border-bone' : `hover:text-dark-grey dark:hover:text-bone bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/1`}>
                  <div>
                    <FontAwesomeIcon icon={faPaste} />
                    &nbsp;Survey
                  </div>
                  {surveyNav === 'survey'  && <FontAwesomeIcon onClick={(e) => e.stopPropagation() || e.preventDefault() || setShowMenu(!showMenu)} icon={faBars}/>}
                </Link>
                <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'summary' ? 'order-first border-b border-dark-grey dark:border-bone' : `hover:text-dark-grey dark:hover:text-bone bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/summary`}>
                  <div>
                    <FontAwesomeIcon icon={faFileLines} />
                    &nbsp;Summary
                  </div>
                  {surveyNav === 'summary'  && <FontAwesomeIcon icon={faBars}/>}
                </Link>
                <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'documents' ? 'order-first border-b border-dark-grey dark:border-bone' : `hover:text-dark-grey dark:hover:text-bone bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/documents`}>
                  <div>
                    <FontAwesomeIcon icon={faFolderOpen} />
                    &nbsp;Documents
                  </div>
                  {surveyNav === 'documents'  && <FontAwesomeIcon icon={faBars}/>}
                </Link>
                <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'checklist' ? 'order-first border-b border-dark-grey dark:border-bone' : `hover:text-dark-grey dark:hover:text-bone bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/checklist`}>
                  <div>
                    <FontAwesomeIcon icon={faListCheck} />
                    &nbsp;Checklist
                  </div>
                  {surveyNav === 'checklist'  && <FontAwesomeIcon icon={faBars}/>}
                </Link>
                <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'trust-account' ? 'order-first border-b border-dark-grey dark:border-bone' : `hover:text-dark-grey dark:hover:text-bone bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/trust-account`}>
                  <div className="flex items-center md:justify-center content-center">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    &nbsp;Trust Account
                  </div>
                  {surveyNav === 'trust-account' && <FontAwesomeIcon icon={faBars}/>}
                </Link>
            </div>
          </PaddedPage>
      </div>
      {children}
    </>
  )
}
