import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonPrimary from 'components/common/Button'
import React from 'react'
import DocumentErrors from './DocumentErrors'
import DocumentQuestions from './DocumentQuestions'
import DocumentTitle from './DocumentTitle'

export default function PDFDocumentContent({errors, document, ltsaDocument, loading, generateDocument, downloadRef}) {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between md:items-center p-2 gap-2">
        <DocumentTitle pdf errors={errors} document={document}/>
        <ButtonPrimary className="text-sm" disabled={errors || loading} onClick={generateDocument}>{loading ?  <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> : "Download"}</ButtonPrimary>
        <a className="absolute" ref={downloadRef}></a>
      </div>
      {errors && <DocumentErrors errors={errors}/>}
      {
        document.info.questions && document.info.questions.length > 0 &&
        <DocumentQuestions document={document}/>
      }
    </>
  )
}
